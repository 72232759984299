import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // تأكد من تثبيت axios
import style from "./Otp.module.css"; // تأكد من وجود هذا الملف
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";

export default function Otp() {
  let navg = useNavigate();

  const [otp, setOtp] = useState(["", "", "", ""]);
  const [currentIndex, setCurrentIndex] = useState(3); // يبدأ من المدخل الأخير
  const [isCodeSent, setIsCodeSent] = useState(true);
  const [resendTimer, setResendTimer] = useState(30);
  const [ErrorMessage, setErrorMessage] = useState();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const inputRefs = useRef([]);

  useEffect(() => {
    // التركيز على الحقل الأخير عند بدء التحميل
    if (inputRefs.current[currentIndex]) {
      inputRefs.current[currentIndex].focus();
      inputRefs.current[currentIndex].setSelectionRange(
        otp[currentIndex].length,
        otp[currentIndex].length
      );
    }
  }, []);

  useEffect(() => {
    if (!isCodeSent) {
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsCodeSent(true);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isCodeSent]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // الانتقال للحقل التالي (إلى اليسار) بعد إدخال قيمة
      if (value && index > 0) {
        setCurrentIndex(index - 1); // الانتقال إلى اليسار
        inputRefs.current[index - 1].focus(); // التركيز على الحقل السابق
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // الانتقال للحقل التالي عند الضغط على backspace
      if (index < 3) {
        setCurrentIndex(index + 1);
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleFocus = (index) => {
    setCurrentIndex(index);
  };

  // const handleVerify = async () => {
  //   // const otpCode = otp.join('').toString();
  //   const otpCode = otp.reverse().join('').toString();
  //     try {
  //     const response = await axios.post(`${Api_Url}/check/otp`, { otp: otpCode } ,  {
  //       headers: {
  //         Accept: "application/json", // الهيدر الوحيد المطلوب
  //         "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL", // الهيدر الإضافي
  //       },
  //     });
  //     if (response.data.success) {
  //       console.log('التحقق ناجح:', response.data);
  //       navg('/resetPassword'); // التنقل إلى صفحة OTP

  //     } else {
  //       console.log('خطأ في التحقق:', response.data);
  //     }
  //   } catch (error) {
  //     console.error('خطأ في الاتصال بالـ API:', error);
  //   }

  // };

  const handleVerify = async () => {
    const otpCode = otp.reverse().join("").toString();
    try {
      const response = await axios.post(
        `${Api_Url}/check/otp`,
        { otp: otpCode },
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            "X-Language": i18n.language,
          },
        }
      );

      console.log("Response from API:", response.data); // عرض الرد القادم من الـ API في Console

      if (response.data.success) {
        setErrorMessage(response.data.message); // عرض رسالة النجاح
        navg("/resetPassword"); // التنقل إلى صفحة تغيير كلمة المرور
      } else {
        setErrorMessage(response.data.message); // عرض رسالة الخطأ القادمة من الـ API
      }
    } catch (error) {
      console.error("Error from API:", error.response?.data || error); // عرض الخطأ في Console

      const errorMessage =
        error.response?.data?.message || "حدث خطأ أثناء الاتصال بالـ API";
      setErrorMessage(errorMessage);
    }
  };

  const handleResend = () => {
    console.log("إعادة إرسال كود OTP...");
    setIsCodeSent(false);
  };

  return (
    <div className="specContainer">
      <div className="cover">
        <div className="imgcover"></div>
      </div>

      <div className="sign">
        <div className={`${style.OTPFORM} SignUp commonSign shadow mx-auto`}>
          <div className={`${style.otpSec} p-5 backHome`}>
            <Link to={"/"} className="nav-link">
              <h5>
                <i className="fa-solid fa-arrow-right"></i>{" "}
                {t("backToHome")}
              </h5>
            </Link>
            <h4> {t("VerificationCode")} </h4>
            <p> {t("whatsAppCode")} </p>

            {ErrorMessage && (
              <p className="error-message text-danger pt-1 text-center w-100 mx-auto ">
                {ErrorMessage}
              </p>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{ display: "flex", gap: "20px", marginBottom: "20px" }}
              >
                {otp.map((_, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    maxLength={1}
                    value={otp[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={() => handleFocus(index)}
                    style={{
                      width: "45px",
                      height: "45px",
                      textAlign: "center",
                      fontSize: "18px",
                      direction: "ltr",
                    }}
                  />
                ))}
              </div>
              <button
                onClick={handleVerify}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  cursor: "pointer",
                  backgroundColor: "rgba(0, 189, 237, 1)",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  marginBottom: "7px",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                {t("verifyBtn")}
              </button>
              <button
                onClick={handleResend}
                disabled={!isCodeSent}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  cursor: isCodeSent ? "pointer" : "not-allowed",
                  backgroundColor: "rgba(237, 30, 121, 1)",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                {isCodeSent
                  ? `${t("RsendverifyBtn")}`
                  : `
                
                ${t("RsendverifyBtn")}  (${resendTimer}s)`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
