import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Style from "./Home.module.css"; // تأكد من أن CSS مفصول هنا
import icon from "../../assets/Round Arrow Left.png";
import MainSlider from "../MainSlider/MainSlider";
import SuccessSlider from "../SuccessSlider/SuccessSlider";
import fire from "../../assets/fire.png";
import ball from "../../assets/ball.png";
import celebrate from "../../assets/celeberate.png";
import handShake from "../../assets/handshake.png";
import FaQ from "../FaQ/FaQ";
import WhyUseTik from "../WhyUseTik/WhyUseTik";
import BestSeller from "../BestSeller/BestSeller";
import { Link, useNavigate } from "react-router-dom";
import showArrow from "../../assets/showArrow3x.png";
import faqph from "../../assets/faq.png";
import Aos from "aos";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";
import { useHome } from "../../Context/HomeContext";
import { SearchContext } from "../../Context/searchContext";
import SearchLabel from "../SearchLabel/SearchLabel";

export default function Home() {
  
  const { t } = useTranslation();
  
  
  const { homeData  ,loading } = useHome();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);



 

  if (loading) {
    return (
      <div
        className="loading"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <i
          className="fas fa-spinner fa-spin"
          style={{ fontSize: "3rem", color: "#3498db" }}
        ></i>
      </div>
    );
  }


  return (
    <>
      <div className="homeSection">
        <div
          className="homeGround"
          style={{
            backgroundImage: `linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.05) -9.64%,
              rgba(237, 30, 121, 0.33) 69.79%,
              rgba(0, 189, 237, 0.49) 91.27%
            ), url(${homeData.image})`,
          }}
        >
          <div className="homeGroundDecs text-center" data-aos="fade-up">
            {/* <h5>{sliderData.title}</h5>
            <h4>{sliderData.description}</h4> */}
             <h5>{homeData.title}  </h5>
             <h4>{homeData.description}</h4>
            <div className="homeBtn  d-flex align-items-center justify-content-center" data-aos="fade-left">
              <Link
                to={homeData.url}
                className="btn homeShow py-2"
                target="_blank"
              >
                {homeData.buttonText}{" "}
                <img src={showArrow} width={20} height={20} alt="" />
              </Link>
            </div>
          </div>
        </div>

        <div className="bgForPhone">
        <div className="SliderOne   ">
          <MainSlider />
        </div>
        </div>
          {/* Search Input */}
          <SearchLabel/>



   
        
        <div className="container">
          <div className="whyTikCover">
            <WhyUseTik />
          </div>
        </div>

        <div className="bestCover ">
          <div className="Slider2 ">
            <BestSeller />
          </div>
        </div>

        <div className="successCover   ">
          <div className="commonHeadind  py-5 " data-aos="fade-down">
            <h6 className="py-1">
              {t('partnersSuccessHead')} <img src={handShake} alt="" />
            </h6>
            <h5> {t('partnersSuccessTitle')}  </h5>
          </div>
          <div className="Slider5">
            <SuccessSlider />
          </div>
          <div className="coverMob commonHeadind mt-5" data-aos="fade-up">
            <h6 className="">
              {" "}
              {t("FaQs")}  <img src={faqph} alt="" />
            </h6>
            <h5 className=" py-3">  {t("faqTitle")} </h5>

            <FaQ />
          </div>
        </div>
      </div>
    </>
  );
}
