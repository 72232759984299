import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import style from "./ContactUs.module.css";
import pinkCall from "../../assets/pinkCall.png";
import pinkgmail from "../../assets/pinkgmail.png";
import pinkwhats from "../../assets/pinkwhats.png";
import axios from "axios";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useContact } from "../../Context/ContactContext";
import { useTranslation } from "react-i18next";




export default function ContactUs() {
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const { socialContactData } = useContact();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, `${t("nameMinErr")}`)
      .max(20, `${t("nameMaxErr")}`)
      .required(`${t("nameReqErr")}` ),
    phone: Yup.string()
      .required(`${t("phoneReqErr")}`)
      .matches(/^[0-9]{9,12}$/, `${t("phoneMinErr")}`),
    email: Yup.string()
      .email(`${t("emailinValidErr")}`)
      .required(`${t("emailreqErr")}`),
    subject: Yup.string().required(`${t("subjectReq")}`  ),
    body: Yup.string().required(`${t("contentReq")}`), // تغيير اسم الحقل إلى body
  });


  async function submitContact(values) {
    try {
      const response = await axios.post(
        `${Api_Url}/contactus`,
        values,
        {
          headers: {
            Accept: "application/json", // الهيدر الوحيد المطلوب
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL", // الهيدر الإضافي إذا كان مطلوبًا
          },
        }
      );
      setResponseMessage(response.data.data.success); // عرض رسالة النجاح
      setIsSuccess(true);
      formik.resetForm(); // إعادة تعيين النموذج بعد النجاح
    } catch (error) {
      console.error("خطأ في إرسال البيانات:", error);
      setResponseMessage("حدث خطأ أثناء إرسال الرسالة."); // عرض رسالة الخطأ
      setIsSuccess(false);
    }
  }
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      subject: "",
      body: "", // تغيير اسم الحقل إلى body
    },
    validationSchema: validationSchema,
    onSubmit: submitContact,
  });

  return (
    <div className="container">
      <div className="CommonNoise">
        <div className="commonDesc">
          <h5>  {t("ContactUs")} </h5>
          <h6>
            <span> {t("Home")} \ </span> {t("ContactUs")}
          </h6>
        </div>
      </div>

      <div className="row g-2 mt-5">
        <div className="col-md-3">
          <div className={`${style.socialContact} py-3`}>
            <a href={`tel:${socialContactData?.phone}`} className="nav-link">
              <img src={pinkCall} alt="رقم الهاتف" />
              <h6 > {t("Phone")} </h6>
              <p dir="ltr">{socialContactData?.phone}{" "} </p>
            </a>
          </div>
        </div>

        <div className="col-md-3">
          <div className={`${style.socialContact} py-3`}>
            <a href={`mailto:${socialContactData?.email}`} className="nav-link">
              <img src={pinkgmail} alt="البريد الالكتروني" />
              <h6>  {t("email")} </h6>
              <p>  {socialContactData?.email }  </p>
            </a>
          </div>
        </div>

        <div className="col-md-3">
          <div className={`${style.socialContact} py-3`}>
            <a href={`https://wa.me/${socialContactData?.whatsapp}`}  target="_blank" className="nav-link">
              <img src={pinkwhats} alt="رقم الواتساب" />
              <h6> {t("whatsapp")} </h6>
              <p dir="ltr">    {socialContactData?.whatsapp}  </p>
            </a>
          </div>
        </div>

        <div className="col-md-3">
          <div className={`${style.socialContact} py-3`}>
          
            <i class="fa-solid fa-location-dot">   </i>
              <h6>    {t("location")}   </h6>
              <span   > {t("locationTitle")}     </span >
          
          </div>
        </div>
      </div>

      <div className={`${style.FormContact} my-4 mx-auto p-3`}>
        <form onSubmit={formik.handleSubmit}>
          <input
            type="text"
            name="name"
            className="my-1"
            placeholder={t("Name")}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger py-2">{formik.errors.name}</div>
          ) : null}

          <input
            type="tel"
            name="phone"
            className="my-1"
            placeholder={t("Phone")}
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="text-danger py-2">{formik.errors.phone}</div>
          ) : null}

          <input
            type="email"
            name="email"
            className="my-1"
            placeholder={t("email")}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger py-2">{formik.errors.email}</div>
          ) : null}

          <input
            type="text"
            name="subject"
            className="my-1"
            placeholder={t("subject")}
            value={formik.values.subject}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.subject && formik.errors.subject ? (
            <div className="text-danger py-2">{formik.errors.subject}</div>
          ) : null}

          <textarea
            name="body" // تغيير الاسم هنا إلى body
            placeholder={t("content")}
            className="py-3 my-1"
            value={formik.values.body} // تغيير القيمة هنا إلى body
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          ></textarea>
          {formik.touched.body && formik.errors.body ? ( // تغيير التحقق هنا إلى body
            <div className="text-danger py-2">{formik.errors.body}</div>
          ) : null}

          <div className={`${style.contactBtn}`}>
            <button type="submit"> {t("send")} </button>
          </div>
        </form>
        {responseMessage && (
          <div
            className={`mt-3 text-center ${
              isSuccess ? "text-success" : "text-danger"
            }`}
          >
            {responseMessage}
          </div>
        )}
      </div>
    </div>
  );
}
