



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import style from './SellTicket.module.css';
import { Link } from 'react-router-dom';
import calendar from '../../assets/Calendar.png'
import Api_Url from "../../ApiConfig/ApiConfig";
import { truncateText } from '../../Utils/textUtils';
import { useTranslation } from 'react-i18next';


export default function SellTicket() {
  const [categories, setCategories] = useState([]);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const Authorization = localStorage.getItem('userToken'); // استرجاع التوكن من localStorage
        const space = ' '; // المتغير للمسافة
        const response = await axios.get(`${Api_Url}/ticket`,
           {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer${space}${Authorization}`, 
            "X-Language": i18n.language

          },
        }
      );
        setCategories(response.data.data.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);


  return (
    <div className="container">
      <div className="CommonNoise">
        <div className="commonDesc">
          {categories.length > 0 && (
            <>
              <h5>{categories[0].title} </h5>
              <h6><span> {t("Home")} \ </span> {categories[0].title}</h6>
            </>
          )}
        </div>
      </div>
      <div className={`${style.sellTicketSec}`}>
        {categories.length > 0 && categories.map((category) => (
          <div key={category.id} className={`${style.sellContent} my-5`}>
            <div className='pb-3'>
              <h6>{category.title}</h6>
              <h5>{category.description}</h5>
            </div>
            <div className="row g-2">
              {category.events.map((event) => (
                <div key={event.id} className="col-md-3 col-6">
                  <Link to={`/sellTicketShown/${event.id}`} className="nav-link">
                    <div className={`${style.sellItem} p-2`}>
                      <img src={event.image} alt="" className={`${style.sellTikImg}`} height={150} />
                      <div className=''>
                        <h5 className="cutText"> {event.name} </h5> 
                        <h6 className='pt-3'> {event.date} <img src={calendar} alt=""  className='px-2 ' />{event.time}</h6> 
                        </div>
                      <div className={`${style.sellBtn} text-center`}>
                        <button>   {t("sellTheTicket")}  </button>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}







