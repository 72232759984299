


import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import Api_Url from "../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";
const HomeContextData = createContext();

export const useHome = () => {
  return useContext(HomeContextData);
};
export const HomeProvider = ({ children }) => {
  
  const [error, setError] = useState(null);
  const [homeData, sethomeData] = useState(null);
  const [homeEventSlider, sethomeEventInSlider] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1199);
  const [categories, setCategories] = useState([]);
  const [partners, setPartners] = useState([]); // حالة لتخزين بيانات الشركاء


  
  const { i18n } = useTranslation();

useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Api_Url}/home`, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            "X-Language": i18n.language

            
          },
        });
        sethomeData(response.data.data.slider);
        sethomeEventInSlider(response.data.data.eventInSlider || []);
        setCategories(response.data.data.categories || []);
        setPartners(response.data.data.partner); // حفظ بيانات الشركاء


      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 1199);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
  }, []);

 
  return (
    <HomeContextData.Provider value={{ homeData, sethomeData , error ,setLoading ,loading ,homeEventSlider, categories ,isSmallScreen ,partners}}>
      {children}
    </HomeContextData.Provider>
  );
};