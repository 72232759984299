import React from "react";
import chair from "../../assets/Armchair 2.png";
import verify from "../../assets/verify.png";
import { Link } from "react-router-dom";

export default function Test() {
  return (
    <>
      <div className="container">
        <div className="row g-2 my-3 ">
          <div className="content  p-2 ">
            <div className="col-md-12 px-3 d-flex align-items-center justify-content-between">
              <div className="itemImg">
                <img src={chair} alt="" />
                <img src={chair} alt="" />
                <span> التذاكر بجانب بعض </span>
              </div>
              <img src={verify} alt="" />
            </div>

            <div className=" tickItemDetails ">
              <div className="col-md-2 ">
                <div className="itemDesc">
                  <h5> التاريخ </h5>
                  <h6> jus 25 , 1445 </h6>
                </div>
              </div>
              <div className="col-md-2 ">
                <div className="itemDesc">
                  <label for="ticketCount">الكمية</label>
                  <h6>
                    <select
                      id="ticketCount"
                      name="ticketCount"
                      className="tikCustome"
                    >
                      <option value="1">1    </option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </h6>
                </div>
              </div>

              <div className="col-md-2 ">
                <div className="itemDesc">
                  <label for="tickettype">نوع التذكرة</label>
                  <h6>
                    <select
                      id="tickettype"
                      name="tickettype"
                      className="tikCustome"
                    >
                      <option value="vib">vib</option>
                      <option value="vii">vii</option>
                      <option value="vi">vi</option>
                    </select>
                  </h6>
                </div>
              </div>

              <div className="col-md-2 ">
                <div className="itemDesc">
                  <h5> اجمالي السعر </h5>
                  <h6> SAR 478 </h6>
                </div>
              </div>

              <div className="col-md-4 text-center">
                <Link className="nav-link" to={'/cart'}>
                <div className="buyTicket ">
                  <button> شراء </button>
                </div>
                
                </Link>
              </div>
            </div>
          </div>




          <div className="content  p-2 ">
            <div className="col-md-12 px-3 d-flex align-items-center justify-content-between">
              <div className="itemImg">
                <img src={chair} alt="" />
                <img src={chair} alt="" />
                <span> التذاكر بجانب بعض </span>
              </div>
              <img src={verify} alt="" />
            </div>

            <div className=" tickItemDetails ">
              <div className="col-md-2 ">
                <div className="itemDesc">
                  <h5> التاريخ </h5>
                  <h6> jus 25 , 1445 </h6>
                </div>
              </div>
              <div className="col-md-2 ">
                <div className="itemDesc">
                  <label for="ticketCount">الكمية</label>
                  <h6>
                    <select
                      id="ticketCount"
                      name="ticketCount"
                      className="tikCustome"
                    >
                      <option value="1">1    </option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </h6>
                </div>
              </div>

              <div className="col-md-2 ">
                <div className="itemDesc">
                  <label for="tickettype">نوع التذكرة</label>
                  <h6>
                    <select
                      id="tickettype"
                      name="tickettype"
                      className="tikCustome"
                    >
                      <option value="vib">vib</option>
                      <option value="vii">vii</option>
                      <option value="vi">vi</option>
                    </select>
                  </h6>
                </div>
              </div>

              <div className="col-md-2 ">
                <div className="itemDesc">
                  <h5> اجمالي السعر </h5>
                  <h6> SAR 478 </h6>
                </div>
              </div>

              <div className="col-md-4 text-center">
                <div className="TicketOff ">
                  <button> نفذت الكمية </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
