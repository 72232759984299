/* global gtag */

import React, { useState, useEffect } from "react";
import style from "./Activities.module.css";
import { Link, useLocation } from "react-router-dom";
import file from "../../assets/File Text.png";
import verifiedBlue from "../../assets/Verified Checkblue.png";
import waiting from "../../assets/Group 2.png";
import axios from "axios";
import rejected from "../../assets/Group1.png";
import Swal from "sweetalert2";
import celebrate from "../../assets/celebrate2.png";
import Api_Url from "../../ApiConfig/ApiConfig";
import hosny from "../../assets/hosny.png";
import { useTranslation } from "react-i18next";

export default function Activities() {
  // const location = useLocation();
  // const [activeLink, setActiveLink] = useState(location.pathname);
  const [comming, setComming] = useState([]);
  const [error, setError] = useState("");
  const [LOADING, setLOADING] = useState(true);
  const { t } = useTranslation();
  const { i18n } = useTranslation();


//   const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
//   const paymentId = urlParams.get("paymentId");
//   const orderId = urlParams.get("orderId");



// const text = window.location.hash;
//   const queryString = text.split('?').slice(1).join('&'); // دمج الأجزاء بعد أول `?` مع `&`

//   // تحويل المعاملات إلى كائن
//   const paramss = queryString.split('&').reduce((acc, param) => {
//     const [key, value] = param.split('=');
//     acc[key] = value;
//     return acc;
//   }, {});

//   // استخراج قيمة orderId
//   console.log(paramss.orderId);
//   const orderNumber = paramss.orderNumber;



  
  const [activeLink, setActiveLink] = useState("/Myactivity");

  // دالة لتحديث الرابط النشط عند الضغط
  const handleClick = (link) => {
    setActiveLink(link);
  };
  // const handleClick = (link) => {
  //   setActiveLink(link);
  // };

  const fetchActivities = async () => {
    try {
      const Authorization = localStorage.getItem("userToken");
      const response = await axios.get(`${Api_Url}/activities/comming`, {
        headers: {
          Accept: "application/json",
          "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          Authorization: `Bearer ${Authorization}`, // تأكد من وجود مسافة بعد "Bearer"
          "X-Language": i18n.language
        },
      });
      setComming(response.data.data.comming);
    
      // console.log(response.data.data.comming);
    } catch (err) {
      setError("فشل في تحميل البيانات.");
    }
    finally
    {
      setLOADING(false)
    }
  };

  // // for Payment visa

  // const sendPaymentData = async () => {
  //   try {
  //     const Authorization = localStorage.getItem("userToken");
  //     const response = await axios.post(
  //       `${Api_Url}/visa/paid`,
  //       {
  //         payment_id: paymentId,
  //         order_number: orderId,
  //         payment_method: "visa",
  //       },
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
  //           Authorization: `Bearer ${Authorization}`, // تأكد من وجود مسافة بعد "Bearer"
  //           "X-Language": i18n.language
  //         },
  //       }
  //     );

  //     console.log("Response from backend:", response.data);
  //     if (response.data.success) {
  //       const successMessage = response.data.data.success;
  //       console.log("Success Alert Message:", successMessage);
  //         // هنا يتم إرسال حدث Conversion إلى Google
  //         if (window.gtag) {
  //           gtag('event', 'conversion', {
  //             'send_to': 'AW-16776606865/ou8HCJb0w-oZEJHp2r8-',
  //             'value': 1.0,
  //             'currency': 'SAR',
  //             'transaction_id': response.data.data.order_number, // يمكن استخدام رقم الطلب هنا
  //           });
  //         } else {
  //           console.error("gtag is not defined");
  //         }
    

  //       await Swal.fire({
  //         title: "تم الدفع",
  //         text: successMessage,
  //         imageUrl: `${celebrate}`,
  //         imageWidth: 115,
  //         imageHeight: 115,
  //         confirmButtonText: "موافق",
  //         width: "392px",
  //         customClass: {
  //           text: "swalTextFatoraa",
  //           confirmButton: "swalButtonFatoraa",
  //           title: "swalTitleFatoraa",
  //         },
  //       });

  //       if (window.gtag) {
  //         gtag('event', 'conversion', {
  //           'send_to': 'AW-16776606865/ou8HCJb0w-oZEJHp2r8-',
  //           'value': 1.0,
  //           'currency': 'SAR',
  //           'transaction_id': response.data.data.order_number, // يمكن استخدام رقم الطلب هنا
  //         });
  //       } else {
  //         console.error("gtag is not defined");
  //       }
  //       // استدعاء دالة fetchActivities لجلب الأنشطة المحدثة بعد الدفع
  //       await fetchActivities();
  //     }
  //   } catch (error) {
  //     console.error("Error sending payment data:", error);
  //   }
  // };


  // // for Payment Tamara


  // const sendPaymentDataTamara = async () => {
  //   try {
  //     const Authorization = localStorage.getItem("userToken");
  //     const response = await axios.post(
  //       `${Api_Url}/tamara/paid`,
  //       {
  //         ref_id: paramss.orderId,
  //         order_number: orderNumber,
  //         payment_method: "tamara",
  //       },
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
  //           Authorization: `Bearer ${Authorization}`,
  //           "X-Language": i18n.language
  //         },
  //       }
  //     );
  
  //     console.log("Response from backend:", response);
  
  //     // تحقق من وجود رسالة النجاح
  //     if (response.data.success) {
  //       const successMessage = response.data.data.success;
  //       console.log("Success Alert Message:", successMessage);
  
       
  //       // عرض رسالة النجاح
  //       await Swal.fire({
  //         title: "تم الدفع",
  //         text: successMessage,
  //         imageUrl: `${celebrate}`, // تأكد أن المتغير celebrate يحتوي على رابط صحيح للصورة
  //         imageWidth: 115,
  //         imageHeight: 115,
  //         confirmButtonText: "موافق",
  //         width: "392px",
  //         customClass: {
  //           text: "swalTextFatoraa",
  //           confirmButton: "swalButtonFatoraa",
  //           title: "swalTitleFatoraa",
  //         },
  //       });
  
  //       console.log("Swal message displayed");
  
  //       // استدعاء دالة fetchActivities لجلب الأنشطة المحدثة بعد الدفع
  //       await fetchActivities();
  //     } else {
  //       console.error("Success message is not available in the response");
  //     }
  //   } catch (error) {
  //     console.error("Error sending payment data:", error);
  //   }
  // };
  
  // useEffect(() => {
  //   if (orderNumber) {
  //     sendPaymentDataTamara();
  //   }
  
  //   fetchActivities();
  // }, []);
  

  useEffect(() => {
    // if (orderId) {
    //   sendPaymentData();
    // }

    fetchActivities();
  }, []);


  if (error) {
    return <div>{error}</div>;
  }

  if(LOADING)
  {
    return (
      <>
        {" "}
        <div
          className="loading"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <i
            className="fas fa-spinner fa-spin"
            style={{ fontSize: "3rem", color: "#3498db" }}
          ></i>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="container">
        <div className="CommonNoise">
          <div className="commonDesc">
            <h5> {t("activities")}  </h5>
            <h6>
              <span> {t("Home")} \ </span> {t("activities")}
            </h6>
          </div>
        </div>
        {/* التنقل بين فعالياتي والبروفايل  */}
        <div className="containActivity d-flex my-4 justify-content-between">
          <Link
            to="/profile"
            className="nav-link profileOpt"
            onClick={() => handleClick("/profile")}
          >
            <h6 className={activeLink === "/profile" ? "active" : ""}>
            {t("Profile")}
            </h6>
          </Link>
          <Link
            to="/Myactivity"
            className="nav-link activityOpt"
            onClick={() => handleClick("/Myactivity")}
          >
            <h6
              className={
                activeLink === "/Myactivity" || activeLink === "/prevTalbat"
                  ? "active"
                  : ""
              }
            >
              {t("activities")}
            </h6>
          </Link>
        </div>

        <div className={`${style.profileSec}`}>
          <div className="row g-3 mb-5">
            <div className="col-md-7 mx-auto">
              <div className={`${style.profileItem} ${style.Activities} p-4`}>
                <div className="row">
                  <div className="col-md-12">
                    <div className={`${style.profileSetting}`}>
                      {/* التنقل بين الطلبات الحاليه والطلبات السابقه */}

                      <div className="containActivity talabatActivity d-flex justify-content-between">
                        <Link
                          to="/Myactivity"
                          className="nav-link profileOpt"
                          onClick={() => handleClick("/Myactivity")}
                        >
                          <h6
                            className={
                              activeLink === "/Myactivity" ? "active" : ""
                            }
                          >
                            {t("current")}
                          </h6>
                        </Link>
                        <Link
                          to="/prevTalbat"
                          className="nav-link activityOpt"
                          onClick={() => handleClick("/prevTalbat")}
                        >
                          <h6
                            className={
                              activeLink === "/prevTalbat" ? "active" : ""
                            }
                          >
                             {t("previous")}
                          </h6>
                        </Link>
                      </div>
                      {/* التنقل بين الطلبات الحاليه والطلبات السابقه */}

                      <div className="row g-2">
                        {comming.map((CommingTalab) => (
                          <div
                            key={CommingTalab.id}
                            className={`${style.effectContent} d-flex flex-wrap`}
                          >
                            <div
                              className={`${style.effectiveItem} d-flex flex-wrap align-items-center w-100`}
                            >
                              <div className="col-12 col-md-2">
                                <img
                                  src={CommingTalab.event_image}
                                  alt={CommingTalab.event_name}
                                  className={`${style.activityImg}`}
                                />
                              </div>

                              <div className="col-12 col-md-3 px-2">
                                <div className={`${style.effectiveDetails}`}>
                                  <h4>{CommingTalab.event_name}</h4>
                                </div>
                              </div>

                              <div className="col-4 col-md-2 px-2">
                                <div className={`${style.effectiveDetails}`}>
                                  <h5>{t("date")}</h5>
                                  <h6>{CommingTalab.event_date}</h6>
                                </div>
                              </div>

                              <div className="col-4 col-md-2">
                                <div className={`${style.effectiveDetails}`}>
                                  <h5>{t("community")}</h5>
                                  <h6>{CommingTalab.comunity}</h6>
                                </div>
                              </div>
                              <div className="col-4 col-md-1">
                                <div className={`${style.effectiveDetails}`}>
                                  <h5> {t("numOfTik")}</h5>
                                  <h6>{CommingTalab.quantity}</h6>
                                </div>
                              </div>

                              <div className="col-8 col-md-2">
                                <div className={`${style.effectiveDetails}`}>
                                  <div className={`${style.varifiedEffect}`}>
                                    <p
                                      className={
                                        CommingTalab.status === "تمت الموافقة"
                                          ? "text-info"
                                          : CommingTalab.status === "تم الرفض"
                                          ? "text-danger"
                                          : "text-secondary"
                                      }
                                    >
                                      {/* <img
                                        src={
                                          CommingTalab.status === "تمت الموافقة" 
                                            ? verifiedBlue
                                            : CommingTalab.status === "تم الرفض"
                                            ? rejected
                                            : waiting
                                        }
                                        className="ps-1"
                                        alt=""
                                      /> */}
                                      {CommingTalab.status}
                                      {CommingTalab.webook && (
                                        <div
                                          className={`${style.effectiveDetails}`}
                                        >
                                          <h6>{CommingTalab.webook}</h6>
                                        </div>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
