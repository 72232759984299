import React, { useEffect } from "react";

const MyFatoraa = () => {
    const initiateSession = async () => {
        try {
            const response = await fetch('/v2/InitiateSession', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "Authorization": "Bearer TjgCcUt3Vsu86TzHrc4tmqvJRRUPz4_GzNLlAE6vUqB-76iNzXOqY073LhTb-EyHIMGlb8R_UabgdTHDmslT1TPPQhLwdpV9YQbLeN5fgJ5TH66DE50OpkYfFzxJjOdb0RSXEhlEFW2Ok_Gupx1UILLMF0j4pJI6CgkiKz-anCrkgKB3upqbaSo0GPI2dXD08d1u5rDKWFNRA1lG120ZE9gy4Y2kTJVQL57OF8mhgXwayLMuDHrC0z20zWRjcX6qXT6gtcA8VdlNivWpS1gC4sk5SyvENfUm90znH_moNFqNgkeglXkbFEaYXgw3ntQNMRrsIvQrALVDycRub3QthHxnWTav0gVpTibtP6P4ZP_uQFGnNwc0n5vFEjAjY9-8fuQBu2nvHE9oXjsGJf1-L5HWWinNAFxvejPsb3IteDt3Gr8iTnybtFqxJf-pTcMrCOv2KHflVMlDIZLzoOmH4jzh02N3KmBr43cahXkwozbE5PcCfOoq3Rwbza9WDl40SD1mws6RBf7JP5G8pTVxQFyRyYUDELvYBwrkXWLruidCV_DTF6ufQdThSbWdq3EdB9HFa_BQFMyCaWKFlPKywFty1t0n1Mg1mP9TdrlXq7IZw3UPXCLJy6gkg5ByR1nhdHT29Mw_wCZfLVHNV1QMy6Ow2kZsJnkKa2ip52UhuDBgC34q"
                },
                body: JSON.stringify({
                    currencyCode: "SAR",
                    amount: 99,
                    // CustomerIdentifier: 50, // Save Cart
                }),
            });
            
            if (response.ok) {
                const data = await response.json();
                console.log("Session ID:", data.Data.SessionId);
                return data.Data.SessionId;
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const completePayment = async (sessionId) => {
        try {
            const url = "/v2/ExecutePayment";
            const paymentData = {
                "PaymentMethodId": 0,
                "SessionId": sessionId,
                "CustomerName": "Test Customer",
                "InvoiceValue": 99,
                "DisplayCurrencyIso": "SAR",
                "CustomerMobile": "1234567890",
                "CustomerEmail": "test@example.com",
                "Language": "en",
                "CallBackUrl": "http://localhost:3000/#/myfatoora",
                "ErrorUrl": "http://localhost:3000/#/myfatoora",
                "MobileCountryCode": 966,
                'Language': 'ar',
                "CustomerReference": 123,
            };

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer TjgCcUt3Vsu86TzHrc4tmqvJRRUPz4_GzNLlAE6vUqB-76iNzXOqY073LhTb-EyHIMGlb8R_UabgdTHDmslT1TPPQhLwdpV9YQbLeN5fgJ5TH66DE50OpkYfFzxJjOdb0RSXEhlEFW2Ok_Gupx1UILLMF0j4pJI6CgkiKz-anCrkgKB3upqbaSo0GPI2dXD08d1u5rDKWFNRA1lG120ZE9gy4Y2kTJVQL57OF8mhgXwayLMuDHrC0z20zWRjcX6qXT6gtcA8VdlNivWpS1gC4sk5SyvENfUm90znH_moNFqNgkeglXkbFEaYXgw3ntQNMRrsIvQrALVDycRub3QthHxnWTav0gVpTibtP6P4ZP_uQFGnNwc0n5vFEjAjY9-8fuQBu2nvHE9oXjsGJf1-L5HWWinNAFxvejPsb3IteDt3Gr8iTnybtFqxJf-pTcMrCOv2KHflVMlDIZLzoOmH4jzh02N3KmBr43cahXkwozbE5PcCfOoq3Rwbza9WDl40SD1mws6RBf7JP5G8pTVxQFyRyYUDELvYBwrkXWLruidCV_DTF6ufQdThSbWdq3EdB9HFa_BQFMyCaWKFlPKywFty1t0n1Mg1mP9TdrlXq7IZw3UPXCLJy6gkg5ByR1nhdHT29Mw_wCZfLVHNV1QMy6Ow2kZsJnkKa2ip52UhuDBgC34q"
                },
                body: JSON.stringify(paymentData),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.Data && data.Data.PaymentURL) {
                    window.location.href = data.Data.PaymentURL;
                } else {
                    console.error('No PaymentURL found in response:', data);
                }
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error("Error completing payment:", error);
        }
    };

    useEffect(() => {
        const loadMyFatoorahScript = async () => {
            const script = document.createElement("script");
            script.src = "https://demo.myfatoorah.com/payment/v1/session.js";
            script.async = true;

            script.onload = async () => {
                const sessionId = await initiateSession();
                if (sessionId) {
                    const config = {
                        sessionId,
                        countryCode: "KWT",
                        currencyCode: "SAR",
                        amount: "99",
                        containerId: "unified-session",
                        paymentOptions: ["Card"],
                        supportedNetworks: ["visa", "masterCard", "mada", "amex"],
                        language: "ar",
                        callback: (response) => {
                            if (response.isSuccess) {
                                console.log("Payment successful:", response);
                                completePayment(sessionId); // تأكد من استدعاء هذه الوظيفة هنا
                            } else {
                                console.error("Payment failed:", response);
                            }
                        },
                        
                    };

                    if (window.myfatoorah) {
                        window.myfatoorah.init(config);
                    } else {
                        console.error("myfatoorah not found");
                    }
                }
            };

            document.body.appendChild(script);
        };

        loadMyFatoorahScript();

        return () => {
            const script = document.querySelector('script[src="https://demo.myfatoorah.com/payment/v1/session.js"]');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    // Start Get Payment ID from URL And Send In My BackEnd
    const checkPaymentID = async () => {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        const paymentId = urlParams.get('paymentId');
        if (paymentId && paymentId !== 'undefined' && paymentId != null) {
            try {
                const response = await fetch('http://localhost:3000/#/myfatoora', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify({
                        paymentId: paymentId,
                    }),
                });

                const data = await response.json();
                // console.log('PaymentID sent to backend:', data);

            } catch (error) {
                console.error('Error sending PaymentID to backend:', error);
            }
        }
    };

    useEffect(() => {
        checkPaymentID();
    }, []);
    // End Get Payment ID from URL And Send In My BackEnd
    return (
        <div className="w-75 mx-auto">
            <h1>Payment</h1>
            <div id="unified-session"></div>
        </div>
    );
};

export default MyFatoraa;
