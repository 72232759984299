import React, { useContext, useState } from "react";
import Style from "./Login.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { UserContext } from "../../Context/userContext";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";

export default function Login() {
  let { setUserToken } = useContext(UserContext);
  let navg = useNavigate();

  const location = useLocation();






  const [passwordVisible, setPasswordVisible] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [colors, setColors] = useState({
    opt1: {
      backgroundColor: "var(--color-sky-subtle)",
      textColor: "var(--color-black)",
    },
    opt2: {
      backgroundColor: "var(--color-sky)",
      textColor: "var(--color-white)",
    },
  });

  const handleClick = (option) => {
    if (option === "opt1") {
      setColors({
        opt1: {
          backgroundColor: "var(--color-sky)",
          textColor: "var(--color-white)",
        },
        opt2: {
          backgroundColor: "var(--color-sky-subtle)",
          textColor: "var(--color-black)",
        },
      });
    } else {
      setColors({
        opt1: {
          backgroundColor: "var(--color-sky-subtle)",
          textColor: "var(--color-black)",
        },
        opt2: {
          backgroundColor: "var(--color-sky)",
          textColor: "var(--color-white)",
        },
      });
    }
  };

  let validationSchema = Yup.object({
    email: Yup.string()
      .required(`${t("emailreqErr")}`)
      .email(`${t("emailinValidErr")}`),
    password: Yup.string()
      .matches(/^.{8,}$/, `${t("passwordErrHint")}`)
      .required(`${t("passwordReq")}`),
  });
  async function submitLogin(values) {
    console.log(values);
    setLoading(false); // بدء التحميل

    // try {
    //   let req = await axios.post(`${Api_Url}/login`, values, {
    //     headers: {
    //       Accept: "application/json", // الهيدر المطلوب
    //       "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL", // الهيدر الإضافي
    //       "X-Language": i18n.language,
    //     },
    //   });

    //   console.log(req.data);
    //   setErrorMessage("");

    //   if (req.data.success) {
    //     console.log("تم تسجيل الدخول بنجاح!");

    //     setUserToken(req.data.data.token);
    //     localStorage.setItem("userToken", req.data.data.token);

    //     setLoading(true);

    //     setTimeout(() => {
    //       navg("/home");
    //     }, 1000);
    //   } else {
    //     console.log("فشل تسجيل الدخول.");
    //     // يمكنك عرض رسالة خطأ للمستخدم هنا إذا كانت success غير صحيحة
    //   }
    // } catch (error) {
    //   // معالجة الأخطاء
    //   setErrorMessage(
    //     error.response?.data?.message || "حدث خطأ، يرجى المحاولة لاحقًا."
    //   );
    //   console.log(error);
    //   setLoading(true);
    // }



    try {
      let req = await axios.post(`${Api_Url}/login`, values, {
        headers: {
          Accept: "application/json",
          "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          "X-Language": i18n.language,
        },
      });
    
      console.log(req.data);
      setErrorMessage("");
    
      if (req.data.success) {
        console.log("تم تسجيل الدخول بنجاح!");
      
        setUserToken(req.data.data.token);
        localStorage.setItem("userToken", req.data.data.token);
      
        setLoading(true);
        // setTimeout(() => {
        //   console.log("Redirecting to:", from);  // طباعة المسار الذي سيتم التوجيه إليه
        //   navg(from, { replace: true });  // التوجيه إلى المسار السابق
        // }, 1000);


        const params = new URLSearchParams(location.search);
        const redirectPath = params.get('redirect') || '/'; // إذا لم يتم تحديد redirect، العودة إلى الصفحة الرئيسية
    
        navg(redirectPath); // إعادة التوجيه

        
       



        // const redirectPath = localStorage.getItem('redirectAfterLogin') || '/';
        // console.log('Redirecting to:', redirectPath);

        // localStorage.removeItem('redirectAfterLogin'); // تنظيف البيانات
        // navg(redirectPath);

        
      } else {
        console.log("فشل تسجيل الدخول.");
      }





    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "حدث خطأ، يرجى المحاولة لاحقًا."
      );
      console.log(error);
      setLoading(true);
    }
  }

  let formik = useFormik({
    initialValues: {
      email: "",

      password: "",
    },
    validationSchema,
    onSubmit: submitLogin,
  });

  return (
    <>
      <div className=" specContainer ">
        <div className="cover ">
          <div className="imgcover"></div>
        </div>

        <div className="sign   ">
          <div className={`${Style.login}  commonSign shadow mx-auto  `}>
            <div className="  optionReverse ">
              <div className="widthopt1">
                <Link to={"/login"} className="nav-link">
                  <div
                    className="signOpt1 d-flex align-items-center justify-content-center  "
                    accordion
                    style={{
                      backgroundColor: colors.opt2.backgroundColor,
                      color: colors.opt2.textColor,
                    }}
                    onClick={() => handleClick("opt2")}
                  >
                    <h6> {t("login")} </h6>
                  </div>
                </Link>
              </div>

              <div className="widthopt2">
                <Link to={"/signUp"} className="nav-link">
                  <div
                    className="signOpt2 d-flex align-items-center justify-content-center  "
                    style={{
                      backgroundColor: colors.opt1.backgroundColor,
                      color: colors.opt1.textColor,
                    }}
                    onClick={() => handleClick("opt1")}
                  >
                    <h6> {t("signUp")} </h6>
                  </div>
                </Link>
              </div>
            </div>

            <div className="skipToHome  d-flex align-items-center ">
              <h6 className="text-center "> {t("login")} </h6>
              <Link to={"/"} className="nav-link d-flex align-items-center ">
                <span>
                  {" "}
                  {t("skip")} <i className="fa-solid fa-arrow-left"></i>{" "}
                </span>
              </Link>
            </div>
            {errorMessage && (
              <p className="error-message text-danger pt-1 text-end w-75 mx-auto ">
                {errorMessage}
              </p>
            )}
            <form onSubmit={formik.handleSubmit}>
              <div className="signForm text-center  ">
                <input
                  type="text"
                  placeholder={t("email")}
                  className="p-3"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email ? (
                  <p className="text-danger pt-1 text-end w-75 mx-auto">
                    {formik.errors.email}
                  </p>
                ) : (
                  " "
                )}
              </div>

              <div className="signForm text-center">
                <div
                  style={{
                    position: "relative",
                    width: "w-100",
                    margin: "0 auto",
                  }}
                >
                  <input
                    type={passwordVisible ? "text" : "password"}
                    dir="rtl"
                    placeholder={t("password")}
                    className="p-3 input-with-logo"
                    // style={{ paddingRight: "30px" }}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {/* <span
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      left: "90px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      fontSize: "13px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </span> */}
                </div>
                {formik.errors.password && formik.touched.password ? (
                  <p className="text-danger pt-1  w-75 mx-auto">
                    {formik.errors.password}
                  </p>
                ) : (
                  " "
                )}
              </div>

              <div className={`${Style.checkOut}  mx-auto `}>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    id="myCheckbox"
                    name="myCheckbox"
                    value="on"
                  />
                  <label
                    htmlFor="myCheckbox"
                    className={`${Style.checkOutloginlabel} px-1`}
                  >
                    {t("rememberMe")}
                  </label>
                </div>
                <Link
                  to={"/changePass"}
                  className={`${Style.checkOutforget} nav-link  `}
                >
                  {t("forgetPass")}
                </Link>
              </div>

              <div className="signForm text-center  ">
                {Loading ? (
                  <button
                    type="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                    className=""
                  >
                    {t("LoginNow")}
                  </button>
                ) : (
                  <button>
                    {" "}
                    <i className="fa-solid fa-spinner fa-spin text-white">
                      {" "}
                    </i>{" "}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
