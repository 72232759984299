import { createContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
export let UserContext = createContext();

export function UserContextprovider({ children }) {
  let [userToken, setUserToken] = useState(null);
//   let data = null;
//   if (userToken != null) {
//     data = jwtDecode(userToken);
//     console.log(data);
//   }

  return (
    <UserContext.Provider value={{ userToken, setUserToken }}>
      {children}
    </UserContext.Provider>
  );
}
