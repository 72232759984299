// // import React, { useEffect } from 'react';

// // const TamaraWidget = () => {
// //   useEffect(() => {
// //     // تحقق من وجود السكربت أولاً
// //     const script = document.createElement('script');
// //     script.src = "https://cdn-sandbox.tamara.co/widget-v2/tamara-widget.js";
// //     script.defer = true;
// //     script.onload = () => {
// //       // تحقق من تحميل السكربت بنجاح
// //       console.log('Tamara script loaded');
// //       window.tamaraWidgetConfig = {
// //         lang: "ar",
// //         country: "SA",
// //         publicKey: ""
// //     }
// //     };
// //     document.body.appendChild(script);
// //   }, []);

// //   return (
// //     <div>

// //       {/* <div id="tamara-widget-container"></div> */}
// //       <tamara-widget type="tamara-summary" ></tamara-widget>

// //     </div>
// //   );
// // };

// // export default TamaraWidget;

// import React, { useEffect } from "react";

// const TamaraWidget = () => {
//   useEffect(() => {
//     // تحقق من وجود السكربت أولاً
//     const script = document.createElement("script");
//     script.src = "https://cdn.tamara.co/widget-v2/tamara-widget.js";

//     script.defer = true;
//     script.onload = () => {
//       // تحقق من تحميل السكربت بنجاح
//       console.log("Tamara script loaded");
//       window.tamaraWidgetConfig = {
//         lang: "ar",
//         country: "SA",
//         publicKey: "1a7fe20e-3298-4377-a9da-e94195d4af90",
//       };
//     };
//     document.body.appendChild(script);
//   }, []);

//   return (
//     <div>
//       {/* <div id="tamara-widget-container"></div> */}
//       <tamara-widget type="tamara-summary"></tamara-widget>
//     </div>
//   );
// };

// export default TamaraWidget;

import React, { useEffect } from "react";

const TamaraWidget = ({ totalPrice }) => {
  useEffect(() => {
    // تحميل سكريبت Tamara
    const script = document.createElement("script");
    script.src = "https://cdn.tamara.co/widget/product-widget.min.js";
    script.async = true;

    script.onload = () => {
      if (window.TamaraProductWidget) {
        window.TamaraProductWidget.init({
          lang: "ar", // اللغة
          currency: "SAR", // العملة
          publicKey: "your_public_key_here", // استبدلها بالمفتاح العام الخاص بك
        });

        window.TamaraProductWidget.render();
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <article id="TamaraDiv" style={{ margin: "20px" }}>
      <div
        className="tamara-product-widget"
        data-lang="ar"
        data-price={totalPrice}
        data-currency="SAR"
        data-country-code="SA"
        data-color-type="default"
        data-show-border="true"
        data-payment-type="installment"
        data-number-of-installments="4"
        data-disable-installment="false"
        data-disable-paylater="true"
      ></div>
    </article>
  );
};

export default TamaraWidget;