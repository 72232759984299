import React, { useEffect, useState } from 'react'
import style from './Privacy.module.css'
import priv from '../../assets/faq.png'
import axios from 'axios';
import DOMPurify from 'dompurify'; // استيراد DOMPurify
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from 'react-i18next';

export default function Privacy() {
  const [pages, setPages] = useState([]);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
        try {
            const response = await axios.get(`${Api_Url}/سياسة-الخصوصية` ,  {
              headers: {
                Accept: "application/json",
                "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
                "X-Language": i18n.language

                
              }
            });
            
            // التحقق من وجود البيانات في الاستجابة
            if (response.data && response.data.success && response.data.data && response.data.data.page) {
                setPages([response.data.data.page]); // وضع الصفحة في المصفوفة
            } else {
                console.error('لا توجد بيانات أو البيانات غير صحيحة:', response.data);
            }
        } catch (error) {
            console.error('حدث خطأ أثناء جلب سياسة الخصوصية:', error);
        }
    };

    fetchPrivacyPolicy();
}, []);




  return <>

    <div className="container">
      <div className="CommonNoise  ">
        <div className="commonDesc">
          <h5> {t("privacy")} </h5>
          <h6>   <span> {t("Home")} \   </span>  {t("privacy")} </h6>
        </div>

      </div>

      <div className={`${style.privacy} mb-5 `}>
        <div className='my-5'>
          <h6>  {t("privacy")} <img src={priv} alt="" /> </h6>
          <h5>  {t("privcyTitle")} </h5>
        </div>

       
         <div className="row">
            <div className="col-md-12">
                <div className="row">
                    {pages.map((page) => (
                        <div className="col-md-12" key={page.page_id}>
                            <div className={`${style.privacyDesc}`}>
                                {/* <h4>{page.page_title}</h4> */}
                                {/* <div dangerouslySetInnerHTML={{ __html: page.page_content }} /> */}
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(page.page_content) }} />

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>



      </div>
    </div>





  </>
}
