import React from 'react'

export default function AcceptedAssigne() {
  return <>
      <div className="container Modal-section">
        <div className="modalBackground">
          <div className={`modalContainer w-75 p-4`}>
            {/* زر الإغلاق */}
            <div className="d-flex justify-content-end">
              <button
                className="closeBtn text-center pe-2"
              
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>

            <div className="title text-center">
            
              <h3>  التوقيع بالموافقه  </h3>
            </div>
            <div className="body ">
                <div className="assigneContent">
                    <p>  التوقيع بالموافقه  </p>
                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere, blanditiis sequi? Aliquid atque ipsum repellendus sequi tempora, quo dicta? Consequuntur quis placeat est laborum saepe perferendis veritatis, repudiandae, reprehenderit praesentium, dolor sequi blanditiis facere laudantium eligendi obcaecati dolore libero. Aperiam omnis iusto veniam dicta animi, ad consequatur voluptatibus at facilis? </p>

                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum beatae quaerat laboriosam esse quisquam. Vero quia molestias voluptatem praesentium hic? </p>

                    <div className="assigneCheck">
                        <input type="checkbox"  id="acceptedAss" />
                        <label htmlFor="acceptedAss" className='px-2'> اوافق علي الشروط والاحكام  </label>
                    </div>

                    <div className="assigneBtnAccepted">
                       <button> ارسل الاقرار </button>
                    </div>
                </div>
           
                </div>
         
            

           
          </div>
        </div>
      </div>
  
  
  </>
}

