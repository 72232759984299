import { useTranslation } from "react-i18next";
// import sudi2 from "../../assets/sudiIcon.png";
import sudi2 from "../../assets/ArabicTranslate.svg";
import translate from "../../assets/translate.png";
import arrowDown from "../../assets/Arrowdown.png";
import english from "../../assets/American.png";
import { Link } from "react-router-dom";
const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language); // حفظ اللغة في localStorage
    window.location.reload(); // Refresh the page
  };

  return (
    <ul className={`navbar-nav ms-auto mb-2 mb-lg-0`}>
      <li className="nav-item dropdown" dir="rtl">
        <Link
          className="nav-link dropdown-toggle link-decoration fw-bold"
          to=""
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src={i18n.language === "ar" ? sudi2 : english}
            alt="language-icon"
            className="px-2 rounded-circle"
            width={45}
            height={25}
          />
          {i18n.language === "ar" ? "العربية" : "English"}{" "}
          <img src={arrowDown} alt="" className="" />
        </Link>
        <ul className="dropdown-menu dropdown-menu-end text-end">
          <li className="">
            <button
              className="dropdown-item"
              onClick={() => changeLanguage("en")}
            >
              <img
                src={english}
                width={15}
                height={15}
                alt=""
                className="rounded-pill"
              />
              <span className="fs-6 text-black px-2"> {t("english")} </span>
            </button>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li className="">
            <button
              className="dropdown-item"
              onClick={() => changeLanguage("ar")}
            >
              <img
                src={sudi2}
                width={15}
                height={15}
                alt=""
                className="rounded-pill"
              />
              <span className="fs-6 text-black px-2">{t("arabic")}</span>
            </button>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default LanguageSwitch;
