import './App.css';
import { createBrowserRouter, createHashRouter, RouterProvider } from 'react-router-dom';
import Layout1 from './Component/Layout1/Layout1';
import SignUp from './Component/SignUp/SignUp';
import Login from './Component/Login/Login';
import Footer1 from './Component/Footer1/Footer1';
import ChangePassword from './Component/ChangePassword/ChangePassword';
import Layout2 from './Component/Layout2/Layout2';
import Home from './Component/Home/Home';
import MainSlider from './Component/MainSlider/MainSlider';
import SuccessSlider from './Component/SuccessSlider/SuccessSlider';
import Footer2 from './Component/Footer2/Footer2';
import Navbar from './Component/Navbar/Navbar';
import EventDetails from './Component/EventDetails/EventDetails';
import Cart from './Component/Cart/Cart';
import CheckoutPayment from './Component/CheckoutPayment/CheckoutPayment';
import AddNewCard from './Component/AddNewCard/AddNewCard';
import AboutUs from './Component/AboutUs/AboutUs';
import FaqSec from './Component/FaqSec/FaqSec';
import Privacy from './Component/Privacy/Privacy';
import ContactUs from './Component/ContactUs/ContactUs';
import Profile from './Component/Profile/Profile';
import SellTicket from './Component/SellTicket/SellTicket';
import SellTicketShow from './Component/SellTicketShow/SellTicketShow';
import Dashboard from './Component/Dashboard/Dashboard';
import Mytickets from './Component/Mytickets/Mytickets';
import EditmyTicket from './Component/EditmyTicket/EditmyTicket';
import PrevOrders from './Component/PrevOrders/PrevOrders';
import Wallet from './Component/Wallet/Wallet';
import WalletSetting from './Component/WalletSetting/WalletSetting';
import FlowChart from './Component/FlowChart/FlowChart';
import Otp from './Component/Otp/Otp';
import Navbar2 from './Component/Navbar2/Navbar2';
import Activities from './Component/Activities/Activities';
import Test from './Component/Test/Test';
import SeeMore from './Component/SeeMore/SeeMore';
import BestSeller from './Component/BestSeller/BestSeller';
import Nav3 from './Component/Nav3/Nav3';
import { UserContext } from './Context/userContext';
import { useContext, useEffect } from 'react';
import ProtectedRouting from './Component/ProtectedRouting/ProtectedRouting';
import ResetPassword from './Component/ResetPassword/ResetPassword';
import PauseTicket from './Component/PauseTicket/PauseTicket';
// import NavMob from './Component/NavMob/NavMob';
import OtpVerify from './Component/OtpVerify/OtpVerify';
import { SearchProvider } from './Context/searchContext';
import SearchResult from './Component/SearchResult/SearchResult';
import MyFatoraa from './Component/MyFatoraa/MyFatoraa';
import FaqBuyer from './Component/FaqBuyer/FaqBuyer';
import FaQ from './Component/FaQ/FaQ';
import NotFound from './Component/NotFound/NotFound';
import { UserProvider } from './Context/UserApiContext';
import { ContactProvider } from './Context/ContactContext';
import CartNew from './Component/CartNew/CartNew';
import PrevTalabat from './Component/PrevTalabat/PrevTalabat';
import CurrentTalabat from './Component/CurrentTalabat/CurrentTalabat';
import { useTranslation } from 'react-i18next';
import UploadedTicket from './Component/UploadedTicket/UploadedTicket';
import TamaraWidget from './Component/TamaraWidget/TamaraWidget';
import AcceptedAssigne from './Component/AcceptedAssigne/AcceptedAssigne';
import ThanksPage from './Component/ThanksPage/ThanksPage';
import { HomeProvider } from './Context/HomeContext';
// comment
let routes = createHashRouter([
  {
    path: '', element: <Layout1 />, children: [
      { path: 'navbar2', element: <Navbar2 /> },
      { path: 'signUp', element: <SignUp /> },
      { path: 'login', element: <Login /> },
      { path: 'footer', element: <Footer1 /> },
      { path: 'changePass', element: <ChangePassword /> },
      { path: 'otp', element: <Otp /> },
      { path: 'otpVerify', element: <OtpVerify /> },
      { path: 'resetPassword', element: <ResetPassword /> },

    ]
  },
  {
    path: '', element: <Layout2 />, children:
      [
        { index: true, element: <Home />  },
        { path: 'mainSlider', element: <MainSlider /> },
        { path: 'successSlider', element: <ProtectedRouting> <SuccessSlider /></ProtectedRouting> },
        // { path: 'eventDetails/:id/:slug', element:   <ProtectedRouting><EventDetails /></ProtectedRouting>  },
        { path: 'eventDetails/:id/:slug', element:   <EventDetails />  },
        { path: 'footerBase', element: <Footer2 /> },
        { path: 'navbar', element: <Navbar /> },
        { path: 'faq', element: <FaQ /> },
        { path: 'cartNew/:ticketId/:quantity', element: <ProtectedRouting><CartNew /></ProtectedRouting> },
       
        // { path: 'cart', element: <Cart /> },

        { path: 'checkoutPayment', element: <CheckoutPayment /> },
        { path: 'addNewCard', element: <AddNewCard /> },
        { path: 'aboutUs', element: <AboutUs /> },
        { path: 'faqSec', element: <FaqSec /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'contactUs', element: <ContactUs /> },
        { path: 'profile', element: <ProtectedRouting><Profile /> </ProtectedRouting> },
        { path: 'sellTicet', element:   <SellTicket /> },
        { path: 'sellTicketShown/:id', element: <ProtectedRouting>  <SellTicketShow /> </ProtectedRouting> },
        { path: 'dashboard', element: <ProtectedRouting><Dashboard /></ProtectedRouting> },
        { path: 'mytickets', element: <ProtectedRouting><Mytickets /></ProtectedRouting> },
        { path: 'editTicket', element: <EditmyTicket /> },
        { path: 'prevOrders', element: <ProtectedRouting><PrevOrders /></ProtectedRouting> },
        { path: 'wallet', element: <ProtectedRouting> <Wallet /></ProtectedRouting> },
        { path: 'walletSetting', element: <WalletSetting /> },
        { path: 'flowchart', element: <FlowChart /> },
        { path: 'Myactivity', element: <ProtectedRouting><Activities /></ProtectedRouting> },
        { path: 'test', element: <Test /> },
        { path: 'seeMore/:categoryId', element: <SeeMore /> },
        { path: 'bestSeller', element: <BestSeller /> },
        { path: 'nav', element: <Nav3 /> },
        { path: 'pauseTicket', element: <PauseTicket /> },
        // { path: 'nav4', element: <NavMob /> },
        { path: 'searchResult', element: <SearchResult /> },
        { path: 'myfatoora', element: <MyFatoraa /> },
        { path: 'faqBuyer', element: <FaqBuyer /> },
        { path: '*', element: <NotFound /> },
        { path: 'prevTalbat', element: <PrevTalabat /> },
        { path: 'currentTalabat', element: <CurrentTalabat /> },
        { path: 'uploadticket', element: <UploadedTicket /> },
        { path: 'tamara', element: <TamaraWidget /> },
        { path: 'acceptAssigne', element: <AcceptedAssigne />},
        { path: 'thnxPage', element: <ThanksPage />},
      ]
  }
])

function App() {
  
  

  const { i18n } = useTranslation();

  useEffect(() => {
    // تغيير اتجاه الصفحة حسب اللغة
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
  }, [i18n.language]); // إعادة التوجيه عند تغيير اللغة


  let { setUserToken } = useContext(UserContext)
  useEffect(() => {
    if (localStorage.getItem('userToken') != null) {
      setUserToken(localStorage.getItem('userToken'))
    }
  }, [])
  return <>


<HomeProvider>
<UserProvider>
      <ContactProvider>
        <SearchProvider>
          <RouterProvider router={routes} >


          </RouterProvider>

        </SearchProvider>
      </ContactProvider>
    </UserProvider>


</HomeProvider>
   








  </>
}

export default App;
