import React, { useState, useEffect } from "react";
import style from "./CurrentTalabat.module.css";
import { Link, useLocation } from "react-router-dom";
import file from "../../assets/File Text.png";
import verifiedBlue from "../../assets/Verified Checkblue.png";
import waiting from "../../assets/Group 2.png";
import axios from "axios";
import rejected from "../../assets/Group1.png";
import Swal from "sweetalert2";
import celebrate from "../../assets/celebrate2.png";
import Api_Url from "../../ApiConfig/ApiConfig";

export default function Activities() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState("");
  const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
  const paymentId = urlParams.get("paymentId");
  const orderId = urlParams.get("orderId");

  const handleClick = (link) => {
    setActiveLink(link);
  };

  const fetchActivities = async () => {
    try {
      const Authorization = localStorage.getItem("userToken");
      const response = await axios.get(`${Api_Url}/activities`, {
        headers: {
          Accept: "application/json",
          "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          Authorization: `Bearer ${Authorization}`, // تأكد من وجود مسافة بعد "Bearer"
        },
      });
      setActivities(response.data.data.activities);
    } catch (err) {
      setError("فشل في تحميل البيانات.");
    }
  };

  const sendPaymentData = async () => {
    try {
      const Authorization = localStorage.getItem("userToken");
      const response = await axios.post(
        `${Api_Url}/visa/paid`,
        {
          payment_id: paymentId,
          order_number: orderId,
          payment_method: "visa",
        },
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer ${Authorization}`, // تأكد من وجود مسافة بعد "Bearer"
          },
        }
      );

      console.log("Response from backend:", response.data);
      if (response.data.success) {
        const successMessage = response.data.data.success;
        console.log("Success Alert Message:", successMessage);

        await Swal.fire({
          title: "تم الدفع",
          text: successMessage,
          imageUrl: `${celebrate}`,
          imageWidth: 115,
          imageHeight: 115,
          confirmButtonText: "موافق",
          width: "392px",
          customClass: {
            text: "swalTextFatoraa",
            confirmButton: "swalButtonFatoraa",
            title: "swalTitleFatoraa",
          },
        });

        // استدعاء دالة fetchActivities لجلب الأنشطة المحدثة بعد الدفع
        await fetchActivities();
      }
    } catch (error) {
      console.error("Error sending payment data:", error);
    }
  };

  useEffect(() => {
    if (orderId) {
      sendPaymentData();
    }

    fetchActivities();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="container">
        <div className="CommonNoise">
          <div className="commonDesc">
            <h5> فعالياتي</h5>
            <h6>
              <span> الرئيسية \ </span> فعالياتي
            </h6>
          </div>
        </div>
        {/* التنقل بين فعالياتي والبروفايل  */}
        <div className="containActivity d-flex my-4 justify-content-between">
          <Link
            to="/profile"
            className="nav-link profileOpt"
            onClick={() => handleClick("/profile")}
          >
            <h6 className={activeLink === "/profile" ? "active" : ""}>
              {" "}
              الملف الشخصي{" "}
            </h6>
          </Link>
          <Link
            to="/Myactivity"
            className="nav-link activityOpt"
            onClick={() => handleClick("/Myactivity")}
          >
            <h6 className={activeLink === "/Myactivity" ? "active" : ""}>
              {" "}
              فعالياتي{" "}
            </h6>
          </Link>
        </div>

        <div className={`${style.profileSec}`}>
          <div className="row g-3 mb-5">
            <div className="col-md-7 mx-auto">
              <div className={`${style.profileItem} ${style.Activities} p-4`}>
                <div className="row">
                  <div className="col-md-12">
                    <div className={`${style.profileSetting}`}>
                      {/* <div className={`${style.head}`}>
                        <h6> فعالياتي </h6>
                        <div className="d-flex align-items-center justify-content-between">
                          <h5> ادارة الفعاليات </h5>
                        </div>

                      
                      </div> */}
                        {/* التنقل بين الطلبات الحاليه والطلبات السابقه */}

                        <div className="containActivity talabatActivity d-flex justify-content-between">
                          <Link
                            to="/Myactivity"
                            className="nav-link profileOpt"
                            onClick={() => handleClick("/Myactivity")}
                          >
                            <h6
                              className={
                                activeLink === "/Myactivity" ? "active" : ""
                              }
                            >
                              الطلبات الحاليه
                            </h6>
                          </Link>
                          <Link
                            to="/prevTalbat"
                            className="nav-link activityOpt"
                            onClick={() => handleClick("/prevTalbat")}
                          >
                            <h6
                              className={
                                activeLink === "/prevTalbat" ? "active" : ""
                              }
                            >
                              الطلبات السابقه
                            </h6>
                          </Link>
                        </div>
                        {/* التنقل بين الطلبات الحاليه والطلبات السابقه */}

                      <div className="row g-2">
                        {activities.map((activity) => (
                          <div
                            key={activity.id}
                            className={`${style.effectContent}`}
                          >
                            <div className={`${style.effectiveItem}`}>
                              <div className="col-md-2">
                                <img
                                  src={activity.event_image}
                                  alt={activity.event_name}
                                  className="w-100"
                                />
                              </div>
                              <div className="col-md-4 px-2">
                                <div className={`${style.effectiveDetails}`}>
                                  <h4>{activity.event_name}</h4>
                                  <h5> التاريخ </h5>
                                  <h6>{activity.event_date}</h6>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className={`${style.effectiveDetails}`}>
                                  <div className={`${style.sarEffect}`}>
                                    <p> {activity.price} SAR </p>
                                  </div>
                                  <h5> عدد التذاكر </h5>
                                  <h6>{activity.quantity}</h6>
                                </div>
                              </div>
                              {/* <div className="col-md-3">
                                <div className={`${style.effectiveDetails}`}>
                                  <div className={`${style.varifiedEffect}`}>
                                    <p className={activity.status === "تمت الموافقة" ? "text-info" : "text-secondary"}>
                                      <img src={activity.status === "تمت الموافقة" ? verifiedBlue : waiting} className="px-1" alt="" />
                                      {activity.status}
                                    </p>
                                  </div>
                                  <h5> الفئة </h5>
                                  <h6>{activity.comunity}</h6>
                                </div>
                              </div> */}
                              <div className="col-md-3">
                                <div className={`${style.effectiveDetails}`}>
                                  <div className={`${style.varifiedEffect}`}>
                                    <p
                                      className={
                                        activity.status === "تمت الموافقة"
                                          ? "text-info"
                                          : activity.status === "تم الرفض"
                                          ? "text-danger"
                                          : "text-secondary"
                                      }
                                    >
                                      <img
                                        src={
                                          activity.status === "تمت الموافقة"
                                            ? verifiedBlue
                                            : activity.status === "تم الرفض"
                                            ? rejected
                                            : waiting
                                        }
                                        className="ps-1"
                                        alt=""
                                      />
                                      {activity.status}
                                    </p>
                                  </div>
                                  <h5> الفئة </h5>
                                  <h6>{activity.comunity}</h6>
                                </div>
                              </div>
                            </div>

                            {/* hr  */}
                            {/* <div
                              className={`${style.effectiveBrdr} my-2`}
                            ></div> */}

                            {/* <div
                              className={`${style.showAndUploadBtn} text-center pb-3`}
                            >
                              <button>
                                <img src={file} alt="" className="px-1" /> عرض /
                                تحميل
                              </button>
                            </div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
