import React from "react";
import style from "./ShareMyTicket.module.css";
import linkShare from "../../assets/Link Minimalistic 2.png";
import { useContact } from "../../Context/ContactContext";
import { useTranslation } from "react-i18next";

export default function ShareMyTicket({ closeShare }) {
    const { socialContactData } = useContact();
    const { t } = useTranslation();

  return (
    <>
      <div className="container Modal-section">
        <div className="modalBackground ">
          <div className={`${style.ShareMyTicketSec}   modalContainer p-4 `}>
            <div className=" d-flex justify-content-end ">
              <button
                className="closeBtn text-center pe-2 "
                onClick={() => {
                  closeShare(false);
                }}
              >
                <i className="fa-solid fa-xmark "></i>{" "}
              </button>
            </div>
            <div className="title ">
              <div className={`${style.ShareMyTicketTitle}`}>
                <h6> {t("share")} </h6>
                <h5>{t("shareTicket")} </h5>
              </div>
            </div>
            <div className="body">
              <div className={`${style.ShareMyTicket} pt-4`}>
                <div className={`${style.shareIcons}`}>
                  <ul className="d-flex align-items-center justify-content-center ">
                    <li className="   ">
                      <a href={socialContactData?.twitter} target="_blank">
                        {" "}
                        <i className="fa-brands fa-x-twitter"></i>{" "}
                      </a>
                    </li>
                    <li className="">
                      {" "}
                      <a href={socialContactData?.linkedin} target="_blank">
                        {" "}
                        <i className="fa-brands fa-linkedin-in"></i>{" "}
                      </a>{" "}
                    </li>
                    <li className="">
                      {" "}
                      <a href={socialContactData?.facebook} target="_blank">
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>{" "}
                    </li>
                    <li className="">
                      {" "}
                      <a href={socialContactData?.instagram} target="_blank">
                        <i className="fa-brands fa-instagram"></i>
                      </a>{" "}
                    </li>
                  </ul>
                </div>

                <div className={`${style.shareBrdr} mx-auto my-4`}></div>
              </div>
            </div>
            <div className="footer text-center">
              <a href="">
                {" "}
                <img src={linkShare} alt="" />{" "}
                <span className="px-1"> {t("copyLink")} </span>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
