import React from 'react'
import style from './WalletSetting.module.css'
export default function WalletSetting({closeSettingWallet}) {
  return <>
  <div className="container Modal-section">
      <div className="modalBackground ">
        <div className={`  ${style.walletSettingtSec}   modalContainer p-4 `}>
          <div className=' d-flex justify-content-end '>
            <button className='closeBtn text-center  ' onClick={() => { closeSettingWallet(false) }}  ><i className="fa-solid fa-xmark "></i> </button>

          </div>
          <div className="title ">
            <div className={`${style.WalletSettingTitle}`}>
              <h6> الاعدادات</h6>
              <h5> اعدادات المحفظة</h5>


            </div>
          </div>
          <div className="body">

            <div className={`${style.WalletSettingForm} pt-4`}>

              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="ticketName"> الاسم بالكامل </label>
                  <input type="text" id='ticketName' placeholder='باور فيلا' />


                </div>

                <div className="col-md-12">
                  <label htmlFor="ticketNum"> رقم ال INBAN</label>
                  <input type="tel" id='ticketNum' placeholder=' KW81CBKU0000000000001234560101' dir='rtl' className='' />


                </div>

              
                <div className="col-md-12 mt-5 pt-5  ">
                  <div className={`${style.saveWalletSetting} d-flex justify-content-center `}>
                    <button> حفظ  </button>
                  </div>

                </div>


              </div>

            </div>
          </div>
          <div className="footer">


          </div>
        </div>
      </div>
    </div>
  

  
  </>
}
