



import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import Aos from 'aos';
import Api_Url from '../../ApiConfig/ApiConfig';
import { useHome } from '../../Context/HomeContext';

const ResponsiveSlider = () => {
  // const [partners, setPartners] = useState([]); // حالة لتخزين بيانات الشركاء
  const { partners  } = useHome();


  const settings = {
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 4, // Default to show 4 slides on large screens
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  // جلب البيانات من الـ API
  // useEffect(() => {
  //   Aos.init({ duration: 2000 }); // Initialize AOS for animations

  //   const fetchPartners = async () => {
  //     try {
  //       const response = await axios.get(`${Api_Url}/home` , {
  //         headers: {
  //           Accept: "application/json",
  //           "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            
  //         },
  //       });
  //       setPartners(response.data.data.partner); // حفظ بيانات الشركاء
  //     } catch (error) {
  //       console.error("خطأ أثناء جلب بيانات الشركاء:", error);
  //     }
  //   };

  //   fetchPartners();
  // }, []);

  return (
    <div className="container" data-aos="fade-up">
      <Slider {...settings} className="sucessSlider">
        {partners.map((partner) => (
          <div
            key={partner.id}
            className="sucessPartner d-flex justify-content-center align-items-center"
          >
            <img src={partner.image} alt={partner.name} className="text-center" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ResponsiveSlider;
