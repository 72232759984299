import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import calendar from "../../assets/Calendar.png"; // تأكد من مسار الصورة
import { Link } from "react-router-dom";
import Aos from "aos";
import { useTranslation } from "react-i18next";
import { useHome } from "../../Context/HomeContext";
// import { truncateText } from "../../Utils/textUtils";

const CenterMode = () => {
  const { t } = useTranslation();
  const { homeEventSlider, isSmallScreen } = useHome();

  const [isDragging, setIsDragging] = useState(false);
  const handleBeforeChange = () => setIsDragging(true);
  const handleAfterChange = () => setIsDragging(false);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "50px", // يمكنك تعديل هذه القيمة حسب الحاجة
    slidesToShow: 5,
    speed: 500,
    // rtl: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1, // تعديل ليكون سلايد واحد في كل مرة
    swipeToSlide: true,
    // draggabl:true,

    draggable: true,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
    // afterChange: (current) => {
    //   setActiveIndex(current);
    // },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="mainSlider  " data-aos="fade-up">
      {homeEventSlider.length > 5 ||
      (isSmallScreen && homeEventSlider.length !== 1) ? (
        <Slider {...settings} className="slider  ">
          {homeEventSlider.length > 0 ? (
            homeEventSlider.map((event, index) => (
              <div key={event.id}>
                <Link
                  to={`/eventDetails/${event.id}/${event.slug}`}
                  className="nav-link"
                  onClick={(e) => isDragging && e.preventDefault()}
                >
                  <div className={` itemSlider text-center  `}>
                    <img
                      src={event.image}
                      alt={event.name || "صورة الحدث"}
                      className="itemImg w-100"
                      height={150}
                    />
                    <div className="itemInfoSlider">
                      <h5 className="cutText"> {event.name} </h5>
                      {/* <h5> {truncateText(event.name)}   </h5> */}
                      <h6 className="d-flex justify-content-center align-items-center ">
                        {event.date}{" "}
                        <img src={calendar} alt="Calendar" className="px-2" />{" "}
                        {event.time}
                      </h6>
                    </div>
                    <div className="brdr mx-auto"></div>
                    <div className="itemFooter d-flex justify-content-between  mx-auto w-75">
                      <p className="fw-bold"> {t("mainSliderPrice")}</p>
                      <p className="text-info">SAR {event.min_price}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="loading">
              <i
                className="fas fa-spinner fa-spin"
                style={{ fontSize: "3rem", color: "#3498db" }}
              ></i>
            </div>
          )}
        </Slider>
      ) : (
        <>
          <div className="gridCardsForSlider">
            {homeEventSlider.map((event) => (
              <div key={event.id}>
                <Link
                  to={`/eventDetails/${event.id}/${event.slug}`}
                  className="nav-link"
                  onClick={(e) => isDragging && e.preventDefault()}
                >
                  <div className={`itemSlider text-center`}>
                    <img
                      src={event.image}
                      alt={event.name || "صورة الحدث"}
                      className="itemImg w-100"
                      height={150}
                    />
                    <div className="itemInfoSlider">
                      <h5 className="cutText"> {event.name} </h5>
                      {/* <h5> {truncateText(event.name)} </h5> */}
                      <h6 className="d-flex justify-content-center align-items-center">
                        {event.date}{" "}
                        <img src={calendar} alt="Calendar" className="px-2" />{" "}
                        {event.time}
                      </h6>
                    </div>
                    <div className="brdr mx-auto"></div>
                    <div className="itemFooter d-flex justify-content-between  mx-auto w-75">
                      <p className="fw-bold"> {t("mainSliderPrice")}</p>
                      <p className="text-info">SAR {event.min_price}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CenterMode;
