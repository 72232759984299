import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import setting from "../../assets/Settings.png";
import axios from "axios";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useUser } from "../../Context/UserApiContext";
import { useTranslation } from "react-i18next";

export default function Wallet() {
  const [walletData, setWalletData] = useState(null);
  const [error, setError] = useState("");
  const { t } = useTranslation();


  const { countersData } = useUser();

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const Authorization = localStorage.getItem("userToken"); // استرجاع التوكن من localStorage
        const space = " "; // المتغير للمسافة
        const response = await axios.get(`${Api_Url}/wallet`, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer${space}${Authorization}`, // إضافة الكلمة والمسافة بشكل صحيح
          },
        });
        setWalletData(response.data.data); // الوصول إلى البيانات من الهيكل الصحيح
      } catch (err) {
        setError("فشل في تحميل البيانات.");
      }
    };

    fetchWalletData();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!walletData) {
    return (
      <>
        {" "}
        <div
          className="loading"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <i
            className="fas fa-spinner fa-spin"
            style={{ fontSize: "3rem", color: "#3498db" }}
          ></i>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="container">
        <div className="CommonNoise  ">
          <div className="commonDesc">
          <h5> {t("dashboardTitle")} </h5>            <h6>
              {" "}
              <span> {t("Home")} \ </span> {t("wallet")}
            </h6>
          </div>
        </div>

        <div className="row my-5 g-2 ">
        <div className="col-6 col-md-4">

<Link to={'/dashboard'} className="nav-link"><div className="dashItems">
  <div className="dashItemImg">
    <i className="fa-solid fa-bag-shopping"></i>
  </div>
  <h5>  {t("mySales")} </h5>
  {/* <h4> {countersData?.new_order} </h4> */}
</div></Link>
</div>

          <div className="col-6 col-md-4">
            <Link to={"/mytickets"} className="nav-link">
              <div className="dashItems">
                <div className="dashItemImg">
                  <i className="fa-solid fa-bag-shopping"></i>
                </div>
                <h5> {t("myTickets")} </h5>
                <h4> {countersData?.my_ticket} </h4>
              </div>
            </Link>
          </div>

          <div className="col-12 col-md-4">
            <Link className="nav-link" to={"/wallet"}>
              <div className="dashItems">
                <div className="dashItemImg">
                  <i className="fa-solid fa-bag-shopping"></i>
                </div>
                <h5> {t("wallet")} </h5>
                <h4 className="  ">
                  {" "}
                  <h6 className="px-2 "> SAR</h6>
                  {countersData?.wallet_balance}
                </h4>
              </div>
            </Link>
          </div>
        </div>

        <h5 className="py-3"> {t("walletData")}: </h5>
        <div className="row ">
          <div className="walletDetails mb-5 p-3  ">
            <div className="col-md-2">
              <div className="walletItem">
                <h6>{t("Name")}</h6>
                <h5>{walletData.name}</h5> {/* ربط الاسم هنا */}
              </div>
            </div>
            <div className="col-md-3">
              <div className="walletItem">
                <h6>  {t("numOfIban")} </h6>
                <h5>{walletData.iban}</h5> {/* ربط رقم IBAN هنا */}
              </div>
            </div>
            <div className="col-md-2">
              <div className="walletItem">
                <h6> {t("toalIncome")} </h6>
                <h5>{walletData.totalIncome} SAR</h5>{" "}
                {/* ربط إجمالي الدخل هنا */}
              </div>
            </div>
            <div className="col-md-2">
              <div className="walletItem">
                <h6>  {t("walletBalace")}   </h6>
                <h5>{walletData.wallet} SAR</h5> {/* ربط رصيد المحفظة هنا */}
              </div>
            </div>

            <div className="col-md-2">
              <Link className="nav-link" to={"/profile"}>
                <div className="walletItem   ">
                  <h6>
                    <img src={setting} alt="" className="px-1" /> {t("setting")}
                  </h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
