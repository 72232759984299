
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";

export default function SignUp() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [colors, setColors] = useState({
    opt1: {
      backgroundColor: "var(--color-sky-subtle)",
      textColor: "var(--color-black)"
    },
    opt2: {
      backgroundColor: "var(--color-sky)",
      textColor: "var(--color-white)"
    }
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };

  const handleClick = (option) => {
    if (option === "opt1") {
      setColors({
        opt1: {
          backgroundColor: "var(--color-sky)",
          textColor: "var(--color-white)"
        },
        opt2: {
          backgroundColor: "var(--color-sky-subtle)",
          textColor: "var(--color-black)"
        }
      });
    } else {
      setColors({
        opt1: {
          backgroundColor: "var(--color-sky-subtle)",
          textColor: "var(--color-black)"
        },
        opt2: {
          backgroundColor: "var(--color-sky)",
          textColor: "var(--color-white)"
        }
      });
    }
  };

  let navg = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [countryCodes, setCountryCodes] = useState([]);

  let validationSchema = Yup.object({
    name: Yup.string()
      .min(3, `${t("nameMinErr")}`)
      .max(20,  `${t("nameMaxErr")}`)
      .required(`${t("nameReqErr")}`),
    email: Yup.string()
      .email(`${t("emailinValidErr")}`)
      .required(`${t("emailreqErr")}`),
    phone: Yup.string()
      .required(`${t("phoneReqErr")}`),
    password: Yup.string()
      .matches(
        /^.{8,}$/,
        `${t("passwordErrHint")}`
      )
      .required( `${t("passwordReq")}`),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password")], `${t("rePasswordErr")}`)
      .required(`${t("repasswordReq")}`),
    country_code: Yup.string().required(`${t("countryCodeReq")}`)
  });

  // Fetch country codes
  useEffect(() => {
    setLoading(true);

    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get(`${Api_Url}/countries`, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            "X-Language": i18n.language

          }
        });
        const data = response.data.data.country.map((country) => ({
          code: country.code,
          flag: country.flag
        }));
        setCountryCodes(data);
      } catch (error) {
        console.error("error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountryCodes();
  }, []);

  // API for signUp
  

  async function submitRegister(values) {
    setLoading(true);
    try {
      const req = await axios.post(
        `${Api_Url}/register`,
        values,
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          },
        }
      );
  
      console.log(req.data);
      setErrorMessage("");
  
      if (
        req.data.success 
       
      ) {
        console.log("تم التسجيل بنجاح!");
  
        localStorage.setItem("phoneNumber", values.phone);
  
        setTimeout(() => {
          navg("/otpVerify"); 
        }, 1000);
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "حدث خطأ، يرجى المحاولة لاحقًا."
      );
    } finally {
      setLoading(false);
    }
  }
  
  let formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      country_code: countryCodes.length > 0 ? countryCodes[0].code : "+966"
    },
    validationSchema,
    onSubmit: submitRegister
  });

  // GET COUNTRY CODE "+"
  const countryOptions = countryCodes.map((country) => ({
    value: country.code,
    label: (
      <div className="country-select-code d-flex align-items-center flex-row-reverse justify-content-end gap-3 fs-6 fw-bold">
        <img
          src={country.flag}
          alt="countryImage"
          width={"35px"}
          height={"25px"}
        />
        {country.code}
      </div>
    )
  }));

  const [dropdownOpen, setDropdownOpen] = useState(false); 
  const dropdownRef = useRef(null); // on cliked out menu drop
  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleOptionSelect = (option) => {
    formik.setFieldValue("country_code", option.value);
    setDropdownOpen(false); // Close dropdown on selection
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="specContainer">
        <div className="cover"></div>

        <div className="sign">
          <div className="SignUp commonSign shadow mx-auto">
            <div className="optionReverse">
              <div className="widthopt1">
                <Link to={"/login"} className="nav-link">
                  <div
                    className="signOpt1 d-flex align-items-center justify-content-center"
                    style={{
                      backgroundColor: colors.opt1.backgroundColor,
                      color: colors.opt1.textColor
                    }}
                    onClick={() => handleClick("opt1")}
                  >
                    <h6>  {t("login")} </h6>
                  </div>
                </Link>
              </div>

              <div className="widthopt2">
                <Link to={"/signUp"} className="nav-link">
                  <div
                    className="signOpt2 d-flex align-items-center justify-content-center"
                    style={{
                      backgroundColor: colors.opt2.backgroundColor,
                      color: colors.opt2.textColor
                    }}
                    onClick={() => handleClick("opt2")}
                  >
                  <h6>  {t("signUp")} </h6>
                  </div>
                </Link>
              </div>
            </div>

            <div className="skipToHome d-flex align-items-center">
              <h6 className="text-center">  {t("signUp")}  </h6>
              <Link to={"/"} className="nav-link d-flex align-items-center">
                <span>
                  {" "}
                  {t("skip")}  <i className="fa-solid fa-arrow-left"></i>{" "}
                </span>
              </Link>
            </div>

            {errorMessage && (
              <p className="error-message text-danger pt-1 text-center w-100 ">
                {errorMessage}
              </p>
            )}
            <form className="form-sign-up" onSubmit={formik.handleSubmit}>
              {formik.errors.country_code && formik.touched.country_code && (
                <p className="text-danger screen-sm pt-1 text-end w-75 mx-auto">
                  {formik.errors.country_code}
                </p>
              )}

              <div className="signForm text-center">
                <input
                  type="text"
                  placeholder={t("Name")}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="text-danger pt-1 text-end w-75 mx-auto ">
                    {formik.errors.name}
                  </p>
                )}
              </div>

              <div className="signForm text-center">
                <input
                  type="email"
                  placeholder={t("email")}
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="text-danger  pt-1   text-end w-75 mx-auto ">
                    {formik.errors.email}
                  </p>
                )}
              </div>

              {/* Phone Number */}

              <div className="signForm text-center">
                <div className="input-group phoneGruopRevrse  input-group--2 gap-3 ">
                  <div className="phone-number">
                    <input
                      type="tel"
                      dir="rtl"
                      placeholder={t("logPhone")} 
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="input-tel"
                    />
                    {formik.errors.phone && formik.touched.phone && (
                      <p className="text-danger  pt-1  w-100 ">
                        {formik.errors.phone}
                      </p>
                    )}
                  </div>
                  <div
                    className="select-phone pointer position-relative d-flex flex-column"
                    ref={dropdownRef}
                  >
                    <div
                      className="dropdown-toggle--1"
                      onClick={handleDropdownToggle}
                    >
                      {formik.values.country_code
                        ? countryOptions.find(
                            (option) =>
                              option.value === formik.values.country_code
                          )?.label
                        : ``}
                    </div>

                    {dropdownOpen && (
                      <ul className="dropdown-menu--1">
                        {countryOptions.map((option) => (
                          <li
                            key={option.value}
                            onClick={() => handleOptionSelect(option)}
                            className="dropdown-item--1"
                          >
                            {option.label}
                          </li>
                        ))}
                      </ul>
                    )}

                    {formik.errors.country_code &&
                      formik.touched.country_code && (
                        <p className="text-danger screen-lg pt-1 text-end w-75 mx-auto">
                          {formik.errors.country_code}
                        </p>
                      )}
                  </div>
                </div>
              </div>

              {/* End Phone Number */}

              {/* Password Input */}

              <div className="signForm text-center">
                <div
                  style={{
                    position: "relative",
                    width: "w-100",
                    margin: "0 auto"
                  }}
                >
                  <input
                    type={passwordVisible ? "text" : "password"}
                    dir="rtl"
                    placeholder={t("password")}
                    className="p-3 input-with-logo"
                    // style={{ paddingRight: "30px" }}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {/* <span
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      left: "90px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      fontSize: "13px"
                    }}
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEye : faEyeSlash}
                    />
                  </span> */}
                </div>
                {formik.errors.password && formik.touched.password ? (
                  <p className="text-danger  pt-1  text-end w-75 mx-auto ">
                    {formik.errors.password}
                  </p>
                ) : (
                  " "
                )}
              </div>
            

              <div className="signForm text-center">
                <input
                  type={passwordVisible2 ? "text" : "password"}
                  dir="rtl"
                  placeholder={t("Repassword")}
                  name="password_confirmation"
                  value={formik.values.password_confirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.password_confirmation &&
                  formik.touched.password_confirmation && (
                    <p className="text-danger pt-1  text-end w-75 mx-auto ">
                      {formik.errors.password_confirmation}
                    </p>
                  )}
              </div>

              <div className="signForm text-center  ">
                {loading ? (
                  <button>
                    {" "}
                    <i className="fa-solid fa-spinner fa-spin"> </i>{" "}
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                    className="btn-sign"
                  >
                    {t("RegistNow")}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
