// import React from "react";
// import { Navigate } from "react-router-dom";

// export default function ProtectedRouting({ children }) {
//   if (localStorage.getItem("userToken") !== null) {
//     return children;
//   } else {
//     return <Navigate to="/home" />;
//   }
// }



import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRouting = ({ children }) => {
  const isAuthenticated = localStorage.getItem('userToken'); // تحقق من حالة المستخدم
  const location = useLocation(); // المسار الحالي

  if (!isAuthenticated) {
    // توجيه إلى صفحة تسجيل الدخول مع حفظ المسار
    return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`} />;
  }

  return children; // عرض المكون إذا كان المستخدم مسجلاً الدخول
};

export default ProtectedRouting;







// import React, { useContext } from 'react';
// import { Navigate, useLocation, useNavigation } from 'react-router-dom';
// import { UserContext } from '../../Context/userContext';

// const ProtectedRouting = ({ children }) => {
//   const { userToken } = useContext(UserContext);
//   const location = useLocation();
//   const navg = useNavigation()
//   console.log('ProtectedRouting Executed'); // التأكد من تنفيذ المكون

//   if (!userToken) {
//     // // تسجيل المسار الذي يتم تخزينه
//     // localStorage.setItem('redirectAfterLogin', location.pathname);
//     // console.log('Storing redirect path:', location.pathname);
//     // return <Navigate to="/login" />;

//     const params = new URLSearchParams(location.search);
//     const redirectPath = params.get('redirect') || '/'; // إذا لم يتم تحديد redirect، العودة إلى الصفحة الرئيسية

//     navg(redirectPath); // إعادة التوجيه
//   }

//   return children;
// };

// export default ProtectedRouting;






