import { useTranslation } from "react-i18next";


const MobileLanguageSwitch = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "ar" ? "en" : "ar";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("i18nextLng", newLanguage); // Save language in localStorage
    window.location.reload(); // Refresh the page
  };

  return (
    <div className="mob-lang mob-lang-icon " onClick={toggleLanguage}>
      <i className="fa-solid fa-globe"></i>
      <span> {i18n.language === "ar" ? "Ar" : "En"}</span>
    </div>
  );
};

export default MobileLanguageSwitch;