


import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import Api_Url from "../ApiConfig/ApiConfig";
const UserContextData = createContext();

export const useUser = () => {
  return useContext(UserContextData);
};
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [countersData, setcounters] = useState(null);
  const [error, setError] = useState(null);
  const Authorization = localStorage.getItem("userToken");
  const headers = {
    Accept: "application/json",
    "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
    Authorization: `Bearer ${Authorization}`
  };
  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${Api_Url}/user`, { headers });
      setUser(response.data.data.user);
      
      setcounters(response.data.data.counters);
    } catch (error) {
      setError("Error fetching user data.");
    }
  };  
  useEffect(() => {
    fetchUserData();
  }, []);

 
  return (
    <UserContextData.Provider value={{ user, setUser , countersData ,error   }}>
      {children}
    </UserContextData.Provider>
  );
};