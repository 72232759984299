


import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import calendar from "../../assets/Calendar.png";
import fire from "../../assets/fire.png";
import axios from "axios";
import { Link } from "react-router-dom";
import { SearchContext } from "../../Context/searchContext";
import Aos from "aos";
import Api_Url from "../../ApiConfig/ApiConfig";
import { truncateText } from "../../Utils/textUtils";
import { useTranslation } from "react-i18next";
import { useHome } from "../../Context/HomeContext";

const ResponsiveSlider = () => {
  // const [categories, setCategories] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const { searchTerm, searchResults } = useContext(SearchContext);
  const [events, setEvents] = useState([]);
  // const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1199);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { isSmallScreen ,categories } = useHome();



  const [isDragging, setIsDragging] = useState(false);
  const handleBeforeChange = () => setIsDragging(true);
  const handleAfterChange = () => setIsDragging(false); 

// search





  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${Api_Url}/home`,
  //          {
  //         headers: {
  //           Accept: "application/json",
  //           "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
  //           "X-Language": i18n.language

  //         },
  //       });
  //       setCategories(response.data.data.categories || []);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  //   const handleResize = () => {
  //     setIsSmallScreen(window.innerWidth < 1199);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  

  const settings = {
        dots: true,

    className: "center",
    centerMode: true,
    // infinite: true,
    centerPadding: "55px", // يمكنك تعديل هذه القيمة حسب الحاجة
    slidesToShow: 5,
    // rtl:false,

    speed: 500,

    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1, // تعديل ليكون سلايد واحد في كل مرة
    swipeToSlide: true,

    draggable: true,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
   
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);



  return (
    <div className="">

      {categories.map((category) => (
        <div key={category.id} >
          <div className="commonHeadind  my-2 pt-4"  data-aos="fade-down">
            <h6 className="pt-4">{category.title} <img src={fire} alt="" /></h6>
            <h5>{category.description}</h5>
          </div>
          <Link className="nav-link"   to={`/seeMore/${category.id}`} >
            <div className="d-flex container align-items-center justify-content-end my-1 text-info seeMore">
              <h6> {t('seeMore')}  </h6>
              <i className="fa-solid fa-arrow-left px-2 text-info "></i>
            </div>
          </Link>

          {category.events.length > 5 || (isSmallScreen && category.events.length > 1) ? <> 
          
            <Slider {...settings} className="slider pb-4">
            {category.events.length > 0 ? (
              category.events.map((event , index) => (
                <div key={event.id} data-aos="fade-up">
                  <Link to={`/eventDetails/${event.id}/${event.slug}`} className="nav-link"  onClick={(e) => isDragging && e.preventDefault()}>
                    <div className={` itemSlider text-center  `}>
                      <img
                        src={event.image}
                        alt={event.name}
                        className="itemImg w-100"
                        height={150}
                      />
                      <div className="itemInfoSlider">
                        <h5 className="cutText"> {event.name} </h5>
                        {/* <h5>{truncateText(event.name)}</h5>            */}
                        
                           <h6 className="d-flex justify-content-center align-items-center">
                          {event.date} <img src={calendar} alt="Calendar" className="px-2" /> {event.time}
                        </h6>
                      </div>
                      <div className="brdr "></div>
                      <div className="itemFooter d-flex justify-content-between mx-auto w-75">
                        {/* <p className="fw-bold">الأسعار تبدأ من</p>
                        <span>SAR {event.min_price}</span> */}
                          <p className="fw-bold"  > {t("mainSliderPrice")}</p>
                          <p className="text-info">SAR {event.min_price}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="loading">
                <i className="fas fa-spinner fa-spin" style={{ fontSize: '3rem', color: '#3498db' }}></i>
              </div>
            )}
          </Slider>
          </> : <>

          <div className="gridCardsForSlider">

          { category.events.map((event , index) => (
                <div key={event.id} data-aos="fade-up">
                  <Link to={`/eventDetails/${event.id}/${event.slug}`} className="nav-link" onClick={(e) => isDragging && e.preventDefault()}>
                    <div className={` itemSlider text-center  `}>
                      <img
                        src={event.image}
                        alt={event.name}
                        className="itemImg w-100"
                        height={150}
                      />
                      <div className="itemInfoSlider">
                        <h5 className="cutText">{event.name}</h5>
                        <h6 className="d-flex justify-content-center align-items-center">
                          {event.date} <img src={calendar} alt="Calendar" className="px-2" /> {event.time}
                        </h6>
                      </div>
                      <div className="brdr "></div>
                      <div className="itemFooter d-flex justify-content-between mx-auto w-75">
                      <p className="fw-bold"  > {t("mainSliderPrice")}</p>
                      <p className="text-info">SAR {event.min_price}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}

          </div>
        
          
          </>}

          
        </div>
      ))}
    </div>
  );
};

export default ResponsiveSlider;
