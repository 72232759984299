


import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // تأكد من تثبيت axios
import style from './OtpVerify.module.css'; // تأكد من وجود هذا الملف
import { UserContext } from '../../Context/userContext';
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from 'react-i18next';

export default function Otp() {
  let navg = useNavigate()

  const [otp, setOtp] = useState(['', '', '', '']);
  const [currentIndex, setCurrentIndex] = useState(3); // يبدأ من المدخل الأخير
  const [isCodeSent, setIsCodeSent] = useState(true);
  const [resendTimer, setResendTimer] = useState(30);
  const [ErrorMessage, setErrorMessage] = useState();
  let { userToken, setUserToken } = useContext(UserContext);
  const { t } = useTranslation();

  const inputRefs = useRef([]);

  useEffect(() => {
    // التركيز على الحقل الأخير عند بدء التحميل
    if (inputRefs.current[currentIndex]) {
      inputRefs.current[currentIndex].focus();
      inputRefs.current[currentIndex].setSelectionRange(otp[currentIndex].length, otp[currentIndex].length);
    }
  }, []);

  useEffect(() => {
    if (!isCodeSent) {
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsCodeSent(true);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isCodeSent]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // الانتقال للحقل التالي (إلى اليسار) بعد إدخال قيمة
      if (value && index > 0) {
        setCurrentIndex(index - 1); // الانتقال إلى اليسار
        inputRefs.current[index - 1].focus(); // التركيز على الحقل السابق
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      // الانتقال للحقل التالي عند الضغط على backspace
      if (index < 3) {
        setCurrentIndex(index + 1);
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleFocus = (index) => {
    setCurrentIndex(index);
  };




  const handleVerify = async () => {
    const phoneNumber = localStorage.getItem("phoneNumber");
    const otpCode = otp.reverse().join("").toString();
    console.log("Data being sent to /verify_account:", {
      otp: otpCode,
      phone: phoneNumber,
    });
  
    try {
      const response = await axios.post(
        `${Api_Url}/verify_account`,
        { otp: otpCode, phone: phoneNumber }, 
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          },
        }
      );
  
      console.log("Response from API:", response.data);
  
      if (response.data) {
        setUserToken(response.data.data.token);
        localStorage.setItem("userToken", response.data.data.token);
        setErrorMessage(response.data.message);
        console.log("التحقق ناجح:", response.data.data.token);
        navg("/"); 
      } else {
        setErrorMessage(response.data.message);
        console.log("خطأ في التحقق:", response.data.message);
      }
    } catch (error) {
      console.error("Error from API:", error.response?.data || error);
      setErrorMessage(
        error.response?.data?.message || "حدث خطأ أثناء الاتصال بالـ API"
      );
    }
  };
  
  const handleResend = () => {
    console.log('إعادة إرسال كود OTP...');
    setIsCodeSent(false);
  };

  return (
    <div className="specContainer">
      <div className="cover">
        <div className="imgcover"></div>
      </div>

      <div className="sign">
        <div className={`${style.OTPFORM} SignUp commonSign shadow mx-auto`}>
          <div className={`${style.otpSec} p-5 backHome`}>
            <Link to={'/'} className="nav-link">
              <h5>
                <i className="fa-solid fa-arrow-right"></i> {t("backToHome")}
              </h5>
            </Link>
            <h4>  {t("VerificationCode")} </h4>
            <p> {t("whatsAppCode")} </p>

            {ErrorMessage && (
              <p className="error-message text-danger pt-1 text-center w-100 mx-auto ">
                {ErrorMessage}
              </p>
            )}

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                {otp.map((_, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    maxLength={1}
                    value={otp[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={() => handleFocus(index)}
                    style={{
                      width: '45px',
                      height: '45px',
                      textAlign: 'center',
                      fontSize: '18px',
                      direction: 'ltr',
                    }}
                  />
                ))}
              </div>
              <button
                onClick={handleVerify}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  cursor: 'pointer',
                  backgroundColor: 'rgba(0, 189, 237, 1)',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  marginBottom: '7px',
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                {t("verifyBtn")}
              </button>
              <button
                onClick={handleResend}
                disabled={!isCodeSent}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  cursor: isCodeSent ? 'pointer' : 'not-allowed',
                  backgroundColor: 'rgba(237, 30, 121, 1)',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  width: '100%',
                  marginTop: '10px',
                }}
              >
                {isCodeSent ? `${t("RsendverifyBtn")}` :
                 `${t("RsendverifyBtn")}  (${resendTimer}s)`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
