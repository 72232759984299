/* global gtag */

import React, { useEffect, useState } from "react";
import visa from "../../assets/image 28.png";
import mada from "../../assets/madapic.svg";
import payTwo from "../../assets/image 29.png";
// import applePay from "../../assets/image 30 .png";
import applePay from "../../assets/apple-pay-2.svg";
import tamara from "../../assets/tamara-grad-ar.a20a9a81.svg";
import shield from "../../assets/Shield Check.png";
import checkBtn1 from "../../assets/check-button 1.svg";
import orderImg from "../../assets/image 34.png";
import mastercardImage from "../../assets/mastercard.svg";
import "./CartNew.css";
import axios from "axios";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useParams } from "react-router-dom";
import TamaraWidget from "../TamaraWidget/TamaraWidget";
import { useTranslation } from "react-i18next";
import { Accordion, Form } from "react-bootstrap";

export default function CartNew() {
  const [activeKey, setActiveKey] = useState("0");

  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };
  const [timeLeft, setTimeLeft] = useState(300); // 300 seconds = 5 minutes
  const [eventData, setEventData] = useState(null);
  const [isGift, setIsGift] = useState(false);
  const [discountData, setDiscountData] = useState(null); // حالة جديدة لتخزين بيانات الخصم
  const [couponCode, setCouponCode] = useState(""); // حالة جديدة لتخزين كود الخصم
  const [giftName, setGiftName] = useState(""); // اسم الشخص الذي سيهدى له
  const [giftPhone, setGiftPhone] = useState(""); // رقم هاتف الشخص الذي سيهدى له
  const { ticketId, quantity } = useParams();
  //   const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  //   const [error, seterror] = useState();

  // const [couponCode, setCouponCode] = useState(""); // الكود المدخل
  // const [discountData, setDiscountData] = useState(null); // بيانات الخصم
  const [apiResponse, setApiResponse] = useState(""); // رد الـ API
  const [isDiscountApplied, setIsDiscountApplied] = useState(false); // حالة الخصم
  const [Loading, setLoading] = useState(true);
  const [Loadingftamara, setLoadingfTamara] = useState(true);
  const [err, seterr] = useState();
  const [errorMessage, setErrorMessage] = useState(""); // حالة لتخزين رسالة الخطأ
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isApplePayLoaded, setIsApplePayLoaded] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [error, setError] = useState(null);
  // Live:
  // https://sa.myfatoorah.com/applepay/v3/applepay.js
  // https://sa.myfatoorah.com/payment/v1/session.js
  // https://sa.myfatoorah.com/cardview/v2/session.js

  // Demo
  // https://demo.myfatoorah.com/applepay/v3/applepay.js
  // https://demo.myfatoorah.com/payment/v1/session.js

  // Show Data from Api
  useEffect(() => {
    const fetchEventData = async () => {
      if (isNaN(ticketId) || isNaN(quantity)) {
        console.error("Invalid ticketId or quantity");
        return;
      }

      try {
        const Authorization = localStorage.getItem("userToken");
        const space = " ";

        const response = await axios.post(
          `${Api_Url}/order/show`,
          {
            ticket_id: ticketId,
            quantity: quantity,
          },
          {
            headers: {
              Accept: "application/json",
              "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
              Authorization: `Bearer${space}${Authorization}`,
            },
          }
        );

        if (
          !response.data ||
          !response.data.data ||
          !response.data.data.event
        ) {
          console.error("Invalid response structure");
          return;
        }

        setEventData(response.data.data);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventData();
  }, [ticketId, quantity]);

  // Code for Counter
  useEffect(() => {
    if (timeLeft === 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return { minutes, secs };
  };

  const { minutes, secs } = formatTime(timeLeft);

  const handleCheckboxChange = () => {
    setIsGift(!isGift);
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

  // دالة لتطبيق كود الخصم
  const applyDiscount = async () => {
    try {
      const response = await axios.post(
        `${Api_Url}/order/copoun`, // تأكد من تغيير الرابط إذا كان مختلف
        {
          coupon: couponCode, // الكود الذي أدخله المستخدم
          total: eventData.total_price, // استخدام السعر الإجمالي من eventData
        },
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL", // استبدلها بالـ SECRET KEY الخاص بك
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "X-Language": i18n.language,
          },
        }
      );

      // إذا كان الكود صحيحًا وتم تطبيق الخصم
      if (response.data.success) {
        setDiscountData(response.data.data); // تخزين بيانات الخصم
        setIsDiscountApplied(true); // تفعيل حالة الخصم
        setApiResponse(""); // مسح أي رسائل خطأ سابقة
      } else {
        // في حالة فشل الكود
        setApiResponse(response.data.message || "فشل تطبيق كود الخصم");
        setIsDiscountApplied(false); // عدم إظهار تفاصيل الخصم
        setDiscountData(null); // مسح بيانات الخصم
      }
    } catch (error) {
      console.error("Error applying discount:", error);

      // عرض رسالة الخطأ من الـ API إن كانت موجودة
      const errorMessage = error.response
        ? error.response.data.message || "حدث خطأ أثناء التحقق من كود الخصم."
        : "حدث خطأ أثناء التحقق من كود الخصم."; // رسالة افتراضية لو مفيش response من الـ API

      setApiResponse(errorMessage); // تعيين رسالة الخطأ القادمة من الـ API
      setIsDiscountApplied(false); // عدم إظهار تفاصيل الخصم
      setDiscountData(null); // مسح بيانات الخصم
      setCouponCode(""); // مسح قيمة input بعد الخطأ
    }
  };

  // Payment By MyFatoora
  // تحميل مكتبة MyFatoora SDK
  useEffect(() => {
    const loadMyFatoorahScript = async () => {
      if (isScriptLoaded) return;

      const script = document.createElement("script");
      script.src = "https://sa.myfatoorah.com/payment/v1/session.js";
      script.async = true;
      console.log("start");

      script.onload = () => {
        if (eventData) {
          const sessionId = eventData.payment_methods.visa.SessionId;
          const countryCode = eventData.payment_methods.visa.CountryCode;
          const currencyCode = eventData.currency;
          const totalPrice = eventData.total_price;
          // var myFatoorah;
          console.log(eventData.payment_methods.visa);
          console.log(window.myfatoorah);
          console.log(sessionId);
          console.log(currencyCode);

          if (sessionId && window.myfatoorah) {
            const config = {
              sessionId: sessionId,
              countryCode: countryCode,
              currencyCode: currencyCode,
              paymentOptions: ["Card", "ApplePay"],
              amount: totalPrice,
              containerId: "unified-session", // العنصر الذي سيحتوي على فورم الدفع
              callback: async (response) => {
                if (response.isSuccess) {
                  const purchaseResult = await handlePurchase(sessionId);
                  console.log("Purchase result:", purchaseResult);
                } else {
                  console.error("Payment failed:", response);
                }
              },
              language: "en", //ar en

              settings: {
                applePay: {
                  supportedNetworks: ["visa", "masterCard", "mada"],
                  containerId: "apple-pay",

                  style: {
                    frameHeight: "50px",
                    frameWidth: "100%",
                    button: {
                      height: "40px",
                      marginTop: "50px",
                      type: `${"hi"}`, //["plain", "buy", "pay", "checkout", "continue", "book", "donate", "subscribe", "reload", "add", "topup", "order", "rent", "support", "contribute", "setup", "tip"]
                      borderRadius: "10px",
                    },
                  },
                  // useCustomButton: false,
                  // sessionStarted: sessionStarted,
                  // sessionCanceled: sessionCanceled,
                  // requiredShippingContactFields: [
                  //     "postalAddress",
                  //     "name",
                  //     "phone",
                  //     "email",
                  // ],
                  // requiredBillingContactFields: [
                  //     "postalAddress",
                  //     "name",
                  //     "phone",
                  // ],
                },

                card: {
                  style: {
                    hideCardIcons: false,
                    direction: "rtl",
                    cardHeight: "100%",
                    tokenHeight: "280px",
                    input: {
                      color: "slate",
                      fontSize: "14px",
                      fontWeight: "bold",
                      fontFamily: "sans-serif",
                      inputHeight: "50px",
                      inputMargin: "8px",
                      borderColor: "c7c7c7",
                      borderWidth: "1px",
                      borderRadius: "8px",
                      boxShadow: "",
                      placeHolder: {
                        holderName: "الاسم على البطاقة",
                        cardNumber: "0000000000000000",
                        expiryDate: "سنه/ شهر",
                        securityCode: "123",
                      },
                    },
                    text: {
                      saveCard: "Save card info for future payment.",
                      addCard: "Use another Card!",
                      deletealert: {
                        title: "Delete",
                        message: "Test",
                        confirm: "yes",
                        cancel: "no",
                      },
                    },
                    label: {
                      display: true,
                      color: "slate",
                      fontSize: "14px",
                      fontWeight: "bold",
                      fontFamily: "ITCHandelGothicArabic-Bold",
                      text: {
                        holderName: "الاسم على البطاقة",
                        cardNumber: "رقم البطاقة",
                        expiryDate: "تاريخ الانتهاء",
                        securityCode: "CVV",
                      },
                    },
                    error: {
                      borderColor: "red",
                      borderRadius: "8px",
                      boxShadow: "0px",
                    },
                    button: {
                      useCustomButton: false,
                      //onButtonClicked: submit,//You will have to implement this function and call myfatoorah.submitCardPayment()
                      textContent: "شراء",
                      fontSize: "16px",
                      fontFamily: "ITCHandelGothicArabic-Bold",
                      color: "white",
                      backgroundColor: "#4daee0",
                      height: "50px",
                      borderRadius: "8px",
                      width: "100%",
                      //   margin: "0 auto",
                      cursor: "pointer",
                    },
                    separator: {
                      useCustomSeparator: false,
                      textContent: "",
                      fontSize: "20px",
                      color: "#4daee0",
                      fontFamily: "sans-serif",
                      textSpacing: "2px",
                      lineStyle: "none",
                      lineColor: "black",
                      lineThickness: "3px",
                    },
                  },
                },
              },
            };

            window.myfatoorah.init(config);
            setIsScriptLoaded(true);
            console.log("done");
          } else {
            console.error("MyFatoorah script not loaded or sessionId missing.");
          }
        }
      };

      script.onerror = () => {
        console.error("Failed to load MyFatoorah script.");
        setError("Failed to load payment script.");
      };

      document.body.appendChild(script);

      return () => {
        const scriptElement = document.querySelector(
          'script[src="https://sa.myfatoorah.com/payment/v1/session.js"]'
        );
        if (scriptElement) {
          document.body.removeChild(scriptElement);
        }
      };
    };

    loadMyFatoorahScript();
  }, [isScriptLoaded, eventData]);
  // تحميل مكتبة Apple Pay بشكل منفصل

  useEffect(() => {
    if (!isScriptLoaded) return;
    console.log("Resp of ev", eventData.payment_methods.apple);
    if (!eventData.payment_methods.apple) return;

    const loadApplePayScript = async () => {
      if (isApplePayLoaded) return;

      const script = document.createElement("script");
      script.src = "https://sa.myfatoorah.com/applepay/v3/applepay.js";
      script.async = true;
      console.log("Loading Apple Pay SDK...");

      script.onload = () => {
        console.log("Apple Pay SDK loaded successfully!");
        setIsApplePayLoaded(true);
      };

      script.onerror = () => {
        console.error("Failed to load Apple Pay script.");
        setError("Failed to load Apple Pay script.");
      };

      document.body.appendChild(script);
    };

    // تحميل Apple Pay فقط في متصفح Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      loadApplePayScript();
    }
  }, [isScriptLoaded, isApplePayLoaded, isApplePayLoaded]);
  const handlePurchase = async (sessionId = null) => {
    setLoading(false); // بدء التحميل

    try {
      const purchaseData = {
        ticket_id: ticketId,
        quantity: quantity,
        sessionId: sessionId ?? eventData.payment_methods.visa.SessionId,
        payment_method: "visa",
      };

      console.log("بيانات الشراء قبل الإرسال:", purchaseData);

      if (discountData) {
        purchaseData.coupon = discountData.code;
        console.log("تم إضافة كود الخصم:", discountData.code);
      }

      if (isGift) {
        if (giftPhone) {
          purchaseData.phone = giftPhone;
          console.log("تم إضافة رقم الهاتف للهدية:", giftPhone);
        }
        if (giftName) {
          purchaseData.name = giftName;
          console.log("تم إضافة اسم المهدى له:", giftName);
        }
        purchaseData.gift = true;
        console.log("تم تعيين خيار الهدية.");
      }

      const response = await axios.post(`${Api_Url}/order`, purchaseData, {
        headers: {
          Accept: "application/json",
          "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "X-Language": i18n.language,
        },
      });

      console.log("رد API بعد عملية الشراء:", response.data); // سجل رد API
      console.log(response.data.success);
      console.log(response.data.data.invoice_url);

      if (response.data.success) {
        if (response.data.data.invoice_url) {
          window.location.href = response.data.data.invoice_url;
          setLoading(true);
        } else {
          console.error("No PaymentURL found in response:", response);
        }
        console.log("تم إتمام عملية الشراء بنجاح");

        const orderId = response.data.data.order_number; // استخرج رقم الطلب
        const total_price = response.data.data.total_price; // استخرج رقم الطلب
        return { success: true, orderId, total_price };
      } else {
        console.log(response.data.message || "فشل في إتمام عملية الشراء");
        return { success: false };
      }
    } catch (error) {
      console.error("خطأ في إتمام عملية الشراء:", error);

      setLoading(true);
      return { success: false };
    }
  };

  // payment by tamara

  const handlePurchaseTamara = async () => {
    setLoadingfTamara(false); // بدء التحميل
    setErrorMessage(""); // إعادة تعيين رسالة الخطأ عند بدء العملية

    try {
      const handlePurchaseTamara = {
        ticket_id: ticketId,
        quantity: quantity,
        payment_method: "tamara",
      };

      console.log("بيانات الشراء قبل الإرسال:", handlePurchaseTamara);

      if (discountData) {
        handlePurchaseTamara.coupon = discountData.code;
        console.log("تم إضافة كود الخصم:", discountData.code);
      }

      if (isGift) {
        if (giftPhone) {
          handlePurchaseTamara.phone = giftPhone;
          console.log("تم إضافة رقم الهاتف للهدية:", giftPhone);
        }
        if (giftName) {
          handlePurchaseTamara.name = giftName;
          console.log("تم إضافة اسم المهدى له:", giftName);
        }
        handlePurchaseTamara.gift = true;
        console.log("تم تعيين خيار الهدية.");
      }

      const response = await axios.post(
        `${Api_Url}/order`,
        handlePurchaseTamara,
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "X-Language": i18n.language,
          },
        }
      );

      console.log("رد API بعد عملية الشراء:", response.data);
      if (response.data.success) {
        if (response.data.data.invoice_url) {
          setLoadingfTamara(true);
          window.location.href = response.data.data.invoice_url;
        } else {
          console.error("No PaymentURL found in response:", response);
        }
        console.log("تم إتمام عملية الشراء بنجاح");

        const orderId = response.data.data.order_number;
        const total_price = response.data.data.total_price;

        // // إرسال حدث إلى Google Analytics
        // if (window.gtag) {
        //   gtag("event", "conversion", {
        //     send_to: "AW-11525632479/Y0J9CLDT2v0ZEN_j7Pcq",
        //     value: total_price,
        //     currency: "SAR",
        //     transaction_id: response.data.data.order_number,
        //   });
        // } else {
        //   console.error("gtag is not defined");
        // }

        return { success: true, orderId, total_price };
      } else {
        console.log(response.data.message || "فشل في إتمام عملية الشراء");
        setErrorMessage(response.data.message || "فشل في إتمام عملية الشراء"); // تخزين رسالة الخطأ
        return { success: false };
      }
    } catch (error) {
      console.error("خطأ في إتمام عملية الشراء:", error);

      // استخراج رسالة الخطأ من الرد
      const errorMsg =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "حدث خطأ غير متوقع. يُرجى المحاولة لاحقًا.";

      setErrorMessage(errorMsg); // تخزين رسالة الخطأ في الحالة
      setLoadingfTamara(true);
      return { success: false };
    }
  };

  useEffect(() => {
    // التحقق من أن المتصفح هو Safari
    const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );
    setIsSafari(isSafariBrowser);
    console.log("test" + isSafari);

    // if (isSafari == false){
    //   eventData.payment_methods.apple = false;
    // }
  }, []);

  // Loading untill comming Data

  if (!eventData) {
    return (
      <>
        {" "}
        <div
          className="loading"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <i
            className="fas fa-spinner fa-spin"
            style={{ fontSize: "3rem", color: "#3498db" }}
          ></i>
        </div>
      </>
    );
  }
  // console.log(eventData);

  // Show div for Discount if code discount is applied
  const totalPriceAfterDiscount = discountData
    ? discountData.total_price_after_discount
    : eventData.total_price;

  return (
    <>
      <div className="container">
        <div className="cartNewSec ">
          {/* counter */}

          <div className="col-md-12">
            <div className="timerDesc ">
              <div className="countdown-timer">
                <div className="timer">
                  <div className="time-segment seconds">
                    <span className="time">
                      {String(secs).padStart(2, "0")}
                    </span>
                    <span className="label"> {t("second")} </span>
                  </div>
                  <div className="time-segment minutes">
                    <span className="time">
                      {String(minutes).padStart(2, "0")}
                    </span>
                    <span className="label">{t("minute")}</span>
                  </div>
                </div>
              </div>

              <p className="text-center py-2">{t("conterRemainng")} </p>
            </div>
          </div>

          <div className="row CartReverse g-3 ">
            <div className="col-md-6">
              <div className="cartItem  ">
                <div className="row">
                  <div className="cartNewInfo">
                    <div className="col-12">
                      <h5> {t("paymentMethod")} </h5>
                      <h6> {t("choosePayMethod")} </h6>
                    </div>

                    <Accordion
                      className="pay-info mt-4"
                      activeKey={activeKey}
                      onSelect={handleAccordionToggle}
                    >
                      {true && (
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <Form.Check
                              type="checkbox"
                              label=""
                              checked={activeKey === "0"}
                              readOnly
                              style={{ pointerEvents: "none" }}
                            />
                            <div className="header-content">
                              <div className="payType">
                                <img
                                  src={applePay}
                                  alt="apple"
                                  className="applePayImg"
                                />
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {" "}
                            {eventData.payment_methods.apple && (
                              <div className="col-md-12 ">
                                <div className="payContent">
                                  <div className="appleWidth cartCode my-4">
                                    {/*<div className="" id="apple-pay"></div>*/}
                                    <button 
                                    onClick={() => {
                                      window.location.href = `${eventData.invoiceURL}`;
                                    }}>{t("buy")}</button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      )}

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <Form.Check
                            type="checkbox"
                            label=""
                            checked={activeKey === "1"}
                            readOnly
                            style={{ pointerEvents: "none" }}
                          />

                          <div className="header-content">
                            <div className="payType">
                              <img
                                src={visa}
                                alt="apple"
                                className="applePayImg"
                              />
                              <img
                                src={mastercardImage}
                                alt="apple"
                                className="applePayImg"
                              />
                              <img
                                src={mada}
                                alt="mada"
                                className="applePayImg mada-img"
                              />
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="col-12 spaceVisa ">
                            <div id="unified-session"></div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <Form.Check
                            type="checkbox"
                            label=""
                            checked={activeKey === "2"}
                            readOnly
                            style={{ pointerEvents: "none" }}
                          />

                          <div className="header-content d-flex justify-content-between  align-items-center gap-2 w-100">
                            <div className="payType">
                              <img
                                src={tamara}
                                alt="apple"
                                className="applePayImg"
                              />
                            </div>
                            <p
                              className="text w-100  m-0"
                              style={{ fontSize: "12px" }}
                            >
                              قسّمها على 4. بدون أي فوائد، أو رسوم.
                            </p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {eventData.payment_methods.tamara && (
                            <div className="col-md-12">
                              <div className="payContent">
                                {Loadingftamara ? (
                                  <div className="payTypeBtn">
                                    <div className="payType">
                                      <TamaraWidget
                                        totalPrice={eventData.total_price}
                                      />
                                    </div>
                                    <button
                                      id="tamara-button"
                                      onClick={handlePurchaseTamara}
                                    >
                                      {t("buy")}
                                    </button>
                                  </div>
                                ) : (
                                  <div className="payTypeBtn">
                                    <button>
                                      {" "}
                                      <i className="fa-solid fa-spinner fa-spin text-white">
                                        {" "}
                                      </i>{" "}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    {errorMessage && (
                      <p
                        style={{
                          color: "red",
                          marginTop: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {errorMessage}
                      </p>
                    )}

                    {/* خيار الهدية */}
                    <div className="col-md-12">
                      <div className="cartCheckbox">
                        <input
                          type="checkbox"
                          id="cart"
                          checked={isGift}
                          onChange={handleCheckboxChange}
                          className="pointer"
                        />
                        <label htmlFor="cart" className="px-1 pointer ">
                          {t("friendGift")}
                        </label>
                      </div>

                      {isGift && (
                        <>
                          <div className="ticketGift mt-2">
                            <input
                              type="text"
                              placeholder={t("friendGiftName")}
                              value={giftName}
                              onChange={(e) => setGiftName(e.target.value)}
                            />
                          </div>
                          <div className="ticketGift mt-2">
                            <input
                              type="text"
                              placeholder={t("writePhone")}
                              value={giftPhone}
                              onChange={(e) => setGiftPhone(e.target.value)}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    {/* payment Safe */}
                    <div className="col-md-12 my-4">
                      <div className="paymentSafe text-center">
                        <img src={shield} alt="" />
                        <span> {t("paymentSafety")} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Order details */}

            <div className="col-md-6">
              <div className="cartItem  ">
                <div className="row">
                  <div className="cartNewInfo">
                    <div className="col-12">
                      <h5> {t("shoppingCart")} </h5>
                      <h6> {t("shoppingCartDetails")} </h6>
                    </div>
                    {/* order Item */}

                    <div className="col-md-12">
                      <div className="orderItem">
                        <img
                          className="ticketCartimg"
                          src={eventData?.event?.image || orderImg}
                          alt={eventData?.event?.name}
                        />

                        <div className="orderItemDesc">
                          <h5>{eventData?.event?.name}</h5>
                          <h6> {eventData?.event?.address} </h6>

                          {/* <div className="orderDate d-flex align-items-center ">
                            {" "}
                            <h6> {eventData?.event?.date} </h6>
                            <img src={calendar} alt="" className="px-2" />
                            <h6 className="">{eventData?.event?.time} </h6>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <hr className="horizonLine" />
                    {/* order Ps. */}

                    <div className="col-md-12">
                      <div className="orderPs">
                        <h5> {t("orderSummary")} </h5>
                        <div className="orderDetails">
                          <div>
                            <h5> {t("numOfTik")}</h5>
                            <h6> {eventData.quantity} </h6>
                          </div>

                          <div>
                            <h5> {t("typeofTikinCart")} </h5>
                            <h6> {eventData.community} </h6>
                          </div>

                          {/* <div>
                            <h5> عدد التذاكر </h5>
                            <h6> {eventData.quantity} </h6>
                          </div> */}

                          <div>
                            <h5> {t("price")} </h5>
                            <h6> SAR {eventData.total_price} </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Price After discount */}
                    <hr className="horizonLine" />

                    {/* Coupon Code */}

                    <div className="col-md-12">
                      <div className="cartCode my-4">
                        <h5 className="discountCode"> {t("coupon")} </h5>
                        <div className="d-flex">
                          <input
                            type="text"
                            placeholder={t("couponWrite")}
                            value={couponCode}
                            onChange={handleCouponChange}
                          />
                          <button onClick={applyDiscount}>{t("apply")}</button>
                        </div>
                      </div>
                    </div>
                    {apiResponse && !isDiscountApplied && (
                      <div className="col-md-12">
                        <div className="errorResponse">
                          <p className="text-danger fw-bold text-center">
                            {apiResponse}
                          </p>{" "}
                          {/* عرض الرسالة الواردة من الـ API */}
                        </div>
                      </div>
                    )}
                    {isDiscountApplied && discountData && (
                      <div className="col-md-12">
                        <div className="PriceDetails">
                          <div className="priceDetailsPS">
                            <h5> {t("beforeDiscount")} : </h5>
                            <h6>
                              <del> SAR {eventData.total_price} </del>{" "}
                              {/* السعر قبل الخصم */}
                            </h6>
                          </div>

                          <div className="priceDetailsPS">
                            <h5>{t("discount")} :</h5>
                            <h6 className="text-danger">
                              {" "}
                              SAR {discountData.discount}{" "}
                            </h6>
                          </div>

                          <div className="priceDetailsPS">
                            <h5> {t("priceAfterDiscount")} : </h5>
                            <h6> SAR {totalPriceAfterDiscount} </h6>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* customer Support */}

                  <div className="col-md-12">
                    <div className="supportDesc">
                      <ul>
                        <li>
                          {" "}
                          <img
                            src={checkBtn1}
                            alt=""
                            width={12}
                            height={12}
                          />{" "}
                          <span>{t("customerSuppTitle")}</span>
                        </li>

                        <li>
                          {" "}
                          <img
                            src={checkBtn1}
                            alt=""
                            width={12}
                            height={12}
                          />{" "}
                          <span>{t("CustomerSuppTitleTwo")}</span>
                        </li>

                        <li>
                          <img src={checkBtn1} alt="" width={12} height={12} />
                          <span>{t("CustomerSuppTitleThree")}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
