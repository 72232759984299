import React, { useEffect, useRef, useState } from "react";
import style from "./ChangePassword.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Select from "react-select";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";

export default function ChangePassword() {
  let navg = useNavigate();
  let [mesg, setmesg] = useState();
  const [Loading, setLoading] = useState(true);
  const [countryCodes, setCountryCodes] = useState([]);
  const [successMsg, setSuccessMsg] = useState(""); // رسالة النجاح
  const [errorMsg, setErrorMsg] = useState(""); // رسالة الخطأ
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  // Fetch country codes
  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get(`${Api_Url}/countries`, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            "X-Language": i18n.language

          }
        });
        const data = response.data.data.country.map((country) => ({
          code: country.code,
          flag: country.flag
        }));
        setCountryCodes(data);
      } catch (error) {
        console.error("error", error);
      } finally {
        // setLoading(false);
      }
    };

    fetchCountryCodes();
  }, []);

  async function resetPass(values) {
    console.log(values); // طباعة القيم المدخلة

    try {
      let req = await axios.post(
        `${Api_Url}/password/sendwhatsappmsg`,
        values,
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            "X-Language": i18n.language

            
          }
        }
      );

      // طباعة البيانات المستلمة للتأكد من الاستجابة
      console.log(req.data);

      // التحقق من نجاح العملية
      if (req.data.success) {
        setSuccessMsg(req.data.data.success); // عرض رسالة النجاح
        setErrorMsg(""); // إعادة تعيين رسالة الخطأ
        setTimeout(() => {
          setSuccessMsg(""); // إزالة رسالة النجاح بعد 3 ثواني
        }, 3000);
        navg("/otp"); // التنقل إلى صفحة OTP
      }
    } catch (error) {
      console.log("حدث خطأ:", error.response?.data); // طباعة الخطأ للاستكشاف
      setErrorMsg(
        error.response?.data?.message || "حدث خطأ، يرجى المحاولة لاحقًا."
      ); // عرض رسالة الخطأ
      setSuccessMsg(""); // إعادة تعيين رسالة النجاح
    }
  }

  

  let validationSchema = Yup.object({
    phone: Yup.string()
      .required(`${t("phoneReqErr")}`),
    country_code: Yup.string().required(`${t("countryCodeReq")}`)
  });

  let formik = useFormik({
    initialValues: {
      phone: "",
      country_code: countryCodes.length > 0 ? countryCodes[0].code : "+966"
    },
    validationSchema,
    onSubmit: resetPass
  });

  // GET COUNTRY CODE "+"
  const countryOptions = countryCodes.map((country) => ({
    value: country.code,
    label: (
      <div className="country-select-code d-flex align-items-center flex-row-reverse justify-content-end gap-3 fs-6 fw-bold">
        <img
          src={country.flag}
          alt="countryImage"
          width={"35px"}
          height={"25px"}
        />
        {country.code}
      </div>
    )
  }));

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // on cliked out menu drop
  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleOptionSelect = (option) => {
    formik.setFieldValue("country_code", option.value);
    setDropdownOpen(false); // Close dropdown on selection
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="specContainer specContainer-change-password">
        <div className="cover">
          <div className="imgcover"></div>
        </div>

        <div className="sign">
          <div className={`${style.PASSFORM} SignUp commonSign shadow mx-auto`}>
            <div className={`${style.ChangePassword} p-5 backHome`}>
              <Link to={"/"} className="nav-link">
                <h5>
                  <i className="fa-solid fa-arrow-right"></i> {t("backToHome")}
                </h5>
              </Link>
              <h4> {t("ResetPassword")} </h4>
              <p> {t("confirmNumber")} </p>

              {/* عرض رسالة الخطأ إذا كانت موجودة */}
              {successMsg && (
                <div className="alert alert-success text-center" role="alert">
                  {successMsg}
                </div>
              )}
              {errorMsg && (
                <div className="alert alert-danger text-center" role="alert">
                  {errorMsg}
                </div>
              )}

              <form onSubmit={formik.handleSubmit}>
                <div className="signForm text-center">
                  <div className="input-group   input-group--2 gap-3 ">
                    <div className="phone-number">
                      <input
                        type="tel"
                        dir="rtl"
                        placeholder={t("logPhone")}
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="input-tel"
                      />
                      {formik.errors.phone && formik.touched.phone && (
                        <p className="text-danger text-center pt-1 text-end w-100 mx-auto">
                          {formik.errors.phone}
                        </p>
                      )}
                    </div>
                    <div
                      className="select-phone position-relative d-flex flex-column"
                      ref={dropdownRef}
                    >
                      <div
                        className="dropdown-toggle--1"
                        onClick={handleDropdownToggle}
                      >
                        {formik.values.country_code
                          ? countryOptions.find(
                              (option) =>
                                option.value === formik.values.country_code
                            )?.label
                          : ``}
                      </div>

                      {dropdownOpen && (
                        <ul className="dropdown-menu--1">
                          {countryOptions.map((option) => (
                            <li
                              key={option.value}
                              onClick={() => handleOptionSelect(option)}
                              className="dropdown-item--1"
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                      )}

                      {formik.errors.country_code &&
                        formik.touched.country_code && (
                          <p className="text-danger screen-lg pt-1 text-end w-75 mx-auto">
                            {formik.errors.country_code}
                          </p>
                        )}
                    </div>
                  </div>
                </div>

                <span>
              {t("confirmNumberText")}
                </span>

                <div className={`${style.changePassBtn}`}>
                  <div className="signForm text-center">
                    <button
                      type="submit"
                      disabled={!(formik.isValid && formik.dirty)}
                      className="w-100"
                    >
                      {t("send")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
