import React from 'react';
import Style from './Footer1.module.css'
import iconlog from '../../assets/Ticket Sale.png'

export default function Footer1() {
  return <>

  <div className="container">
    <div className={`${Style.footer1}`}>
      <div className={`${Style.footer1Logo}`}>
        <span>  بيع تذكرتك  <img src={iconlog} alt="" />  </span>


      </div>

      <div className={`${Style.footerDisc}`}>
        <h6> جميع الحقوق محفوظه لتيك ايفنت 2024  </h6>

      </div>

      <div className={`${Style.footer1List}`}>
        <div className={`${Style.footer1Icons}`}>
          <ul  className='d-flex ' >
            <li className='pointer' >  <i className="fa-brands fa-x-twitter"></i>  </li>
            <li className='pointer'>  <i className="fa-brands fa-linkedin-in"></i> </li>
            <li className='pointer'>  <i className="fa-brands fa-facebook-f"></i>  </li>
            <li className='pointer'>  <i className="fa-brands fa-instagram"></i>  </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  </>
}
