


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import Api_Url from '../../ApiConfig/ApiConfig';
import {  useTranslation } from 'react-i18next';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const FlowChart = () => {
  let {t} = useTranslation()
  const { i18n } = useTranslation();

  const [chartData, setChartData] = useState({
    labels: [`${t("january")}`, `${t("february")}`, `${t("march")}`, `${t("april")}`, `${t("may")}`, `${t("june")}`,`${t("july")}`, `${t("august")}`, `${t("september")}`,`${t("october")}`, `${t("november")}`, `${t("december")}`],
    datasets: [
      {
        label: `${t("detailsOfMonth")}` ,
        data: [], // Initially empty, will be filled after fetching data
        borderWidth: 2,
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.4,
        pointRadius: 5,
        pointBackgroundColor: 'rgba(255, 99, 132, 1)',
        pointBorderColor: '#fff',
        pointHoverRadius: 7,
        pointHoverBackgroundColor: 'rgba(54, 162, 235, 1)',
        pointHoverBorderColor: 'rgba(54, 162, 235, 1)',
        pointHoverBorderWidth: 2
      }
    ]
  });

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const Authorization = localStorage.getItem("userToken");

        const response = await axios.get(`${Api_Url}/new_order`   , 
          {
            headers: {
              Accept: "application/json",
              "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
              Authorization: `Bearer ${Authorization}`, // تأكد من وجود مسافة بعد "Bearer",
              "X-Language": i18n.language

            },
          }
        );
        
        // Extract chart data from response
        const apiData = response.data.data.chart;
        console.log(apiData)
        
        // Update chart data with API response
        setChartData((prevData) => ({
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              data: Object.values(apiData)
            }
          ]
        }));
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', margin: 'auto', paddingBottom: '40px' }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default FlowChart;
