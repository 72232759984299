import React, { useState } from "react";
import style from "./ResetPassword.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  let navg = useNavigate();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState(""); // رسالة الخطأ

  const [Loading, setLoading] = useState(true);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };

  


  async function ResetPassword(values) {
    setLoading(false);
    console.log(values)
    try {
      const req = await axios.post(
        `${Api_Url}/reset/password`,
        values,
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          },
        }
      );
      console.log(req.data);
      setErrorMessage("");
      if (
        req.data.data.success 
      ) {
        console.log("تم تغير كلمة المرور بنجاح");
        setLoading(true)

        setTimeout(() => {
          navg("/login");
        }, 1000);
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "حدث خطأ، يرجى المحاولة لاحقًا."
      );
      setLoading(true)

    } 
    // finally {
    //   setLoading(false);
    // }
  }









  
  let validationSchema = Yup.object({
    password: Yup.string()
    .matches(
     /^.{8,}$/,
      `{${t("passwordErrHint")}}`
    )
    .required( `{${t("passwordReq")}}`),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password")], `{${t("rePasswordErr")}}`)
      .required(`{${t("repasswordReq")}}`),
  });

  let formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema,
    onSubmit: ResetPassword,
  });
  return (
    <>
      <div className="specContainer">
        <div className="cover">
          
        </div>

        <div className="sign ">
          <div
            className={`${style.resetFORM} SignUp commonSign   shadow mx-auto `}
          >
            <div className={`${style.resetPassword} p-5 backHome `}>
              <Link to={"/"} className="nav-link">
                <h5>
                  <i className="fa-solid fa-arrow-right"></i> 
                 {t("backToHome")}
                </h5>
              </Link>
              <h4> {t("ResetPassword")} </h4>


              {errorMsg && (
        <div className="alert alert-danger text-center" role="alert">
          {errorMsg}
        </div>
      )}


              <form onSubmit={formik.handleSubmit}  >
                <div className="signForm ">
                  <label htmlFor="resetPass" className="py-2">  {t("newPassword")} </label>
                  <div
                    style={{
                      position: "relative",
                      width: "w-100",
                      margin: "0 auto",
                    }}
                  >
                    <input
                      type={passwordVisible ? "text" : "password"}
                      dir="rtl"
                      id="resetPass"
                      placeholder={t("newPassword")}
                      className=" input-with-logo w-100"
                      // style={{ paddingRight: '30px' }}
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {/* <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        left: "25px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        fontSize: "13px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={passwordVisible ? faEye : faEyeSlash}
                      />
                    </span> */}
                  </div>
                  {formik.errors.password && formik.touched.password ? (
                    <p className="text-danger pt-1 text-end w-100 mx-auto">
                      {formik.errors.password}
                    </p>
                  ) : (
                    " "
                  )}
                </div>

                {/* //////////////////////////////////////////////////////////////// */}

                <div className="signForm ">
                  <label htmlFor="resetPassConfirm" className="py-2" > {t("confirmNewPassword")} </label>

                  <div
                    style={{
                      position: "relative",
                      width: "w-100",
                      margin: "0 auto",
                    }}
                  >
                    <input
                      type={passwordVisible2 ? "text" : "password"}
                      dir="rtl"
                      id="resetPassConfirm"
                      placeholder={t("Repassword")}
                      className=" input-with-logo w-100 "
                      // style={{ paddingRight: '30px' }}
                      name="password_confirmation"
                      value={formik.values.password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {/* <span
                      onClick={togglePasswordVisibility2}
                      style={{
                        position: "absolute",
                        left: "25px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        fontSize: "13px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={passwordVisible2 ? faEye : faEyeSlash}
                      />
                    </span> */}
                  </div>
                  {formik.errors.password_confirmation &&
                  formik.touched.password_confirmation ? (
                    <p className="text-danger pt-1 text-end w-100 mx-auto">
                      {formik.errors.password_confirmation}
                    </p>
                  ) : (
                    " "
                  )}
                </div>

                <div className={`${style.resetPassBtn} `}>
                  {/* <div className="signForm text-center  ">
                    <button
                      type="submit"
                      disabled={!(formik.isValid && formik.dirty)}
                      className="w-100"
                    >
                      حفظ
                    </button>
                  </div> */}

<div className="signForm text-center  ">
              

              {Loading ? <button
                    type="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                    className="w-100"
                  >
                   {t("save")}
                  </button> : (
                  <button>
                    {" "}
                    <i className="fa-solid fa-spinner fa-spin text-white "> </i>{" "}
                  </button>
                )}
               
              </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
