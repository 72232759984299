import React, { useContext, useEffect } from "react";
import icon1 from "../../assets/Ticket Sale.svg";
import navLogoa from "../../assets/logoa.png";

import sudi2 from "../../assets/sudiIcon.png";
import translate from "../../assets/translate.png";
import arrowDown from "../../assets/Arrowdown.png";
import english from "../../assets/American.png";

import { Link, useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar2 from "../Navbar2/Navbar2";
import Nav3 from "../Nav3/Nav3";
import { UserContext } from "../../Context/userContext";

import { useUser } from "../../Context/UserApiContext";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";


export default function Navbar() {


let {t} = useTranslation()
  
  let nav = useNavigate();

  const { user } = useUser();
  // console.log("user" , user);

  let { userToken, setUserToken } = useContext(UserContext);


  function LogOut() {
    localStorage.removeItem("userToken");
    setUserToken(null);
    nav("/");
  }

 
  

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      {userToken ? (
        <>
          <nav
            className="navbar  navStatic  navbar-expand-lg "
            data-aos="fade-down"
            id="baseNav"
          >
            <div class="container">
              <div className="navItem  ">
                <Link to={"/sellTicet"} className="nav-link">
                  <span>
                    
                   {t("sellyourTicket")}  <img src={icon1} alt=""  />{" "}
                  </span>
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center ">
                  <Link className="nav-link" to={"/"}>
                    <li className="nav-item  navLogo mx-2">
                      <img src={navLogoa} alt="logo-img" />
                    </li>
                  </Link>
                </ul>

                <ul className="navbar-nav  me-auto mb-2 mb-lg-0 d-flex align-items-center ">
                  {/* DropList for Language */}
                


<LanguageSwitch/>

                  {/* profile dropList */}

                  <ul className={`navbar-nav ms-auto mb-2 mb-lg-0`}>
                    <li className="nav-item dropdown" dir="rtl">
                      <Link
                        className="nav-link dropdown-toggle link-decoration fw-bold"
                        to="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          backgroundColor: "black",
                          color: "white",
                          borderRadius: "50%",
                          padding: "10px",
                          width: "32px",
                          height: "32px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >

                        {user?.avatar === "" ? <> <span style={{ fontSize: "10px", color: "white" }}>
                          {user?.name ? user?.name.slice(0, 1) : ""}
                        </span></> : <img src={user?.avatar} alt="" width={32} height={32} className="rounded-circle" /> }
                       
                      </Link>
                      <ul
                        className="dropdown-menu dropdown-menu-end text-end"
                        style={{ minWidth: "100px" }}
                      >
                        <li>
                          <Link className="dropdown-item" to="/profile">
                          {t("Profile")}
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/dashboard">
                          {t("dashboardTitle")}
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li
                          className="pe-3 pointer"
                          onClick={() => {
                            LogOut();
                          }}
                        >
                        {t("logout")}
                        
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </nav>

          <div className="navBrdr"></div>

          <Navbar2 />
        </>
      ) : (
        <Nav3 />
      )}
    </>
  );
}
