import React from "react";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="main-page-404">
      <div className="info-error">
        <h2 className="number-not-found">
          <span className="num-page">4</span>
          <span className="num-page">0</span>
          <span className="num-page">4</span>
        </h2>
        <h2 className="title-page">الصفحة غير موجودة</h2>
        <Link to="/" className="button-home-page">الصفحة الرئيسية</Link>
      </div>
    </div>
  );
};
export default NotFound;