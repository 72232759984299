import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import img10 from "../../assets/image 10.png";
import img11 from "../../assets/image 11.png";
import img12 from "../../assets/image 12.png";
import perfect from "../../assets/perfect.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import { useTranslation } from "react-i18next";

export default function WhyUseTik() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();

  const cartList = [
    {
      id: 0,
      img: img10,
      title: "whyUseQesItemOneTi",
      disc: "whyUseQesItemOneDes",
    },
    {
      id: 1,
      img: img11,
      title: "whyUseQesItemTwoTi",
      disc: "whyUseQesItemTwoDes",
    },
    {
      id: 2,
      img: img12,
      title: "whyUseQesItemThirdTi",
      disc: "whyUseQesItemThirdDes",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    className: "",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    // rtl: true,
    speed: 500,

    autoplaySpeed: 2000,
    afterChange: (current) => {
      setActiveIndex(current);
    },
  };
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="whyUseTik py-3">
      <div className="commonHeadind  pb-3">
        <h6>
          {t("whyUsetitle")} <img src={perfect} alt="perfect" />
        </h6>
        <h5> {t("whyUseQes")} </h5>
      </div>

      {isMobile ? (
        // سلايدر للموبايل

        <Slider {...settings} className="w-100 mx-auto ">
          {cartList.map((item, index) => {
            return (
              <div
                key={item.id}
                data-aos="fade-up"
                className="whyUseItem w-100"
              >
                <img src={item.img} alt={t(item.title)} className="mx-auto" />
                <div className="whyUseDesc">
                  <h5>{t(item.title)}</h5>
                  <h6>{t(item.disc)}</h6>
                </div>
              </div>
            );
          })}
        </Slider>
      ) : (
        // تصميم ثابت للويب
        <div className="row g-3">
          <div className="col-md-3 " data-aos="fade-left">
            <div className="whyUseItem  whyUseItemCOl1">
              <img src={img10} alt="" />
              <div className="whyUseDesc">
                <h5> {t("whyUseQesItemOneTi")} </h5>
                <h6>{t("whyUseQesItemOneDes")}</h6>
              </div>
            </div>
          </div>

          <div className="col-md-6" data-aos="fade-up">
            <div className="whyUseItem">
              <img src={img11} alt="" />
              <div className="whyUseDesc">
                <h5> {t("whyUseQesItemTwoTi")} </h5>
                <h6>{t("whyUseQesItemTwoDes")}</h6>
              </div>
            </div>
          </div>

          <div className="col-md-3 " data-aos="fade-right">
            <div className="whyUseItem  whyUseItemCOl2">
              <img src={img12} alt="" />
              <div className="whyUseDesc">
                <h5> {t("whyUseQesItemThirdTi")} </h5>
                <h6>{t("whyUseQesItemThirdDes")}</h6>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
