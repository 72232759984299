import axios from 'axios';
import React, { useContext, useState } from 'react'
import Api_Url from '../../ApiConfig/ApiConfig';
import { SearchContext } from '../../Context/searchContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function SearchLabel() {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const [ loading , setLoadingSearch] = useState(false);
    let nav = useNavigate();
  
    
    
    const { setSearchTerm, setSearchResults, setResultDataNotFound, searchTerm } =useContext(SearchContext);
    const [error, setError] = useState("");
    const handleSearch = async (e) => {
        e.preventDefault();
        const termInputSearch = e.target.search.value.trim(); // THIS FOR INPUT CHANGES TO GET DATA SEARCH
        if (!termInputSearch) return;
        setSearchTerm(termInputSearch); // ADD THE CHANGES OF INPUT IN USESTATE SEARCH TERM
        setLoadingSearch(true); // SHOW LOADING WHEN GET DATA
        try {
          const response = await axios.get(
            `${Api_Url}/search?q=${termInputSearch}`,
            {
              headers: {
                Accept: "application/json",
                "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
                "X-Language": i18n.language
              },
            }
          );
          const data = response.data;
          if (Array.isArray(data.data.result)) {
            setSearchResults(data.data.result); // ADD RESULTS DATA FROM API IN STATE TO SHOW IN PAGE SEARCHRESULT USE "searchResults"
            nav("/SearchResult"); // GO TO PAGE SEARCH RESULT AFTER GET DATA
          } else if (typeof data.data.result === "string") {
            setResultDataNotFound(data.data.result);
            setSearchResults([]);
            localStorage.removeItem("searchResults");
          }
    
          nav("/SearchResult"); // GO TO PAGE SEARCH RESULT AFTER GET DATA
        } catch (err) {
          setError("فشل في جلب النتائج");
          setSearchResults([]); //  SET TO EMPTY ARRAY IF HAS ERROR
        } finally {
          setLoadingSearch(false); //ADD LOADING IS FALSE
        }
      };
       
  return (
    <div className="inputSearch w-75 mx-auto mb-5 displayInPhone ">
    <h4 className="text-center text-info py-4 fs-5">    {t("bookBestEvent")} <i className="fa-solid fa-magnifying-glass fs-6"></i> </h4>
    {/* Search Input */}
    <div className="ms-auto  ">
      <form onSubmit={handleSearch}>
        <input
          type="search"
          id="search"
          value={searchTerm}
          placeholder={t("Search")}
          onChange={(term) => {
            setSearchTerm(term.target.value);
            console.log(term.target.value);
          }}
          className="form-control "
        />
      </form>
      {loading && <div>  {t("loading")}   </div>}
      {error && <div>{error}</div>}
    </div>
  </div>  )
}
