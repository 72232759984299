// import React, { useState } from 'react'
// import img28 from '../../assets/image 28.png'
// import img29 from '../../assets/image 29.png'
// import card from '../../assets/Card.png'
// import shield from '../../assets/Shield Check.png'
// import AddNewCard from '../AddNewCard/AddNewCard'
// import celebrate2 from '../../assets/celebrate2.png'
// import Swal from 'sweetalert2';


// export default function CheckoutPayment() {
//   const handleClick = () => {
//     Swal.fire({
//         title: 'تأكيد الدفع',
//         text: "هل أنت متأكد أنك تريد الدفع الآن؟",
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#00BDED',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'نعم, ادفع الآن!',
//         cancelButtonText: 'إلغاء',
//         width: '390px',  
//     }).then((result) => {
//         if (result.isConfirmed) {
//           Swal.fire({
//             title: 'تم الدفع!',
//             text: 'تم دفع المبلغ بنجاح.',
//             imageUrl: `${celebrate2}`, 
//             imageWidth: 100,
//             imageHeight: 100,
//             imageAlt: 'Custom Success Icon',
//             confirmButtonText: 'تم',
//             customClass: {
//               title: 'custom-swal-title',
//               text: 'custom-swal-text',
//               confirmButton: 'custom-swal-button',
//           },
//           width: '390px',  

//         });
//         }
//     });
//   }


//   const [selectedOption, setSelectedOption] = useState(null);
//   const [openNewCard , setopenNewCard  ] = useState(false)

//   const handleRadioChange = (event) => {
//     setSelectedOption(event.target.id);
//   };

//   return <>
//     <div className="container">
      
//       <div className="CommonNoise  ">
//         <div className="commonDesc">
//           <h5> الدفع </h5>
//           <h6>   <span> الرئيسية \   </span> الدفع </h6>
//         </div>

//       </div>


//       <div className="checkPayment p-4 mx-auto my-5">
//         <h5 className='py-4'>بيانات البطاقة الائتمانية</h5>

//         {/* First Radio Button Item */}
//         <div className={`checkPaymentItem p-4 ${selectedOption === 'visa1' ? 'checked' : ''}`}>
//           <div className="checkItemDetails d-flex align-items-center justify-content-between">
//             <input
//               type="radio"
//               id="visa1"
//               name="paymentMethod"
//               onChange={handleRadioChange}
//               checked={selectedOption === 'visa1'}
//             />
//             <label htmlFor="visa1" className="d-flex align-items-start">
//               <p className="px-2">4637 **********</p>
//               <img src={img28} alt="" width={54} height={25} />
//             </label>
//           </div>
//         </div>

//         {/* Second Radio Button Item */}
//         <div className={`checkPaymentItem mt-2 p-4 ${selectedOption === 'visa2' ? 'checked' : ''}`}>
//           <div className="checkItemDetails d-flex align-items-center justify-content-between">
//             <input
//               type="radio"
//               id="visa2"
//               name="paymentMethod"
//               onChange={handleRadioChange}
//               checked={selectedOption === 'visa2'}
//             />
//             <label htmlFor="visa2" className="d-flex align-items-start">
//               <p className="px-2">4637 **********</p>
//               <img src={img29} alt="" width={54} height={31} />
//             </label>
//           </div>
//         </div>
//         <div className="addNewCardBtn d-flex justify-content-center pb-3">
//           <button className='d-flex' onClick={()=> {setopenNewCard(true)}} > <img src={card} alt="" className='px-1' />
//             اضافة بطاقة جديدة
//           </button>
//           {openNewCard && <AddNewCard  closeModal={setopenNewCard} /> }
//         </div>

//         <div className="payNow mt-5 pt-5">
//             <button 
//                 className="d-flex justify-content-between align-items-center px-4"
//                 onClick={handleClick}
//             >  
//                 <span>ادفع الان</span>   
//                 <span>SAR 245</span>
//             </button>
//         </div>

//         <div className={`buySafe text-center pt-4 `}>
//           <img src={shield} alt="" />
//           <span> وسائل دفع امنة ١٠٠٪  </span>
//         </div>


//       </div>
//     </div>



//   </>
// }




import React from 'react'

export default function CheckoutPayment() {
  return <>

  
  
  
  
  </>
}
