import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FlowChart from "../FlowChart/FlowChart";
import style from "./Dashboard.module.css";
import axios from "axios";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useUser } from "../../Context/UserApiContext";
import { useTranslation } from "react-i18next";

export default function Dashboard() {
  const [isChartVisible, setIsChartVisible] = useState(false); // State to manage chart visibility
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const { countersData } = useUser();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const handleClick = (link) => {
    setActiveLink(link);
  };


  // couter For User



  const handleToggleChart = () => {
    setIsChartVisible((prev) => !prev); // Toggle the visibility
  };

  // api
  const [orders, setOrders] = useState([]);

  const [error, setError] = useState(null); // حالة لتخزين الخطأ

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const Authorization = localStorage.getItem("userToken"); // استرجاع التوكن من localStorage
        const space = " "; // المتغير للمسافة
        const response = await axios.get(
          `${Api_Url}/new_order`,

          {
            headers: {
              Accept: "application/json",
              "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
              Authorization: `Bearer${space}${Authorization}`,
              "X-Language": i18n.language

            },
          }
        );

        if (response.data && response.data.data && response.data.data.orders) {
          setOrders(response.data.data.orders);
          console.log(response.data.data)
        } else {
          setError("فشل في تحميل الطلبات.");
          console.log(response)
        }
      } catch (error) {
        setError(
          error.response?.data?.message ||
            "حدث خطأ أثناء تحميل الطلبات. حاول مرة أخرى."
        );
        console.log(error)
      }
      finally
      {
        setLoading(false)
      }
    };

    fetchOrders();
  }, []);

  const [successMessage, setSuccessMessage] = useState(null); // حالة جديدة لتخزين الرسالة الناجحة

  //  in case accept the ticket


  const handleAcceptOrder = async (orderId) => {
    console.log(`Attempting to accept order with ID: ${orderId}`);
  
    const Authorization = localStorage.getItem("userToken");
  
    if (!Authorization) {
      console.error("مطلوب تسجيل الدخول.");
      return;
    }
  
    try {
      const response = await axios.put(
        `${Api_Url}/new_order/${orderId}`,
        {
          status: true,
        },
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer ${Authorization}`,
            "X-Language": i18n.language

          },
        }
      );
  
      console.log("Order accepted successfully:", response.data);
      setSuccessMessage(`${t("acceptedAnswer")}`); // تعيين الرسالة الناجحة
      setError(null); // إعادة تعيين حالة الخطأ
  
      // **إزالة الطلب من القائمة**
      setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
    } catch (error) {
      let errorMessage = "خطأ غير متوقع.";
      if (error.response) {
        errorMessage =
          error.response.data.message || "حدث خطأ أثناء معالجة الطلب.";
      }
      setError(errorMessage);
      console.error("Error accepting order:", errorMessage);
    }
  };
  

  //  in case refuse the ticket



  const handleRejectOrder = async (orderId) => {
    console.log(`Attempting to reject order with ID: ${orderId}`);
  
    const Authorization = localStorage.getItem("userToken");
  
    if (!Authorization) {
      console.error("مطلوب تسجيل الدخول.");
      return;
    }
  
    try {
      const response = await axios.put(
        `${Api_Url}/new_order/${orderId}`,
        {
          status: false,
        },
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer ${Authorization}`,
            "X-Language": i18n.language

          },
        }
      );
  
      console.log("Order rejected successfully:", response.data);
      setSuccessMessage(`${t("rejectedAnswer")}`);
      setError(null);
  
      // **إزالة الطلب من القائمة**
      setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
    } catch (error) {
      let errorMessage = "خطأ غير متوقع.";
      if (error.response) {
        errorMessage =
          error.response.data.message || "حدث خطأ أثناء معالجة الطلب.";
      }
      setError(errorMessage);
      console.error("Error rejecting order:", errorMessage);
    }
  };
  

  if(isLoading)
    {
      return (
        <>
          {" "}
          <div
            className="loading"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <i
              className="fas fa-spinner fa-spin"
              style={{ fontSize: "3rem", color: "#3498db" }}
            ></i>
          </div>
        </>
      );
    }
  
  return (
    <>
      <div className="container">
        <div className="CommonNoise  ">
          <div className="commonDesc">
            <h5> {t("dashboardTitle")} </h5>
            <h6>
              {" "}
              <span> {t("Home")} \ </span>  {t("newOrders")}
            </h6>
          </div>
        </div>

        <div className="dashboarrdSec">
          <div className="row my-3 g-2 ">
         

<div className="col-6 col-md-4">
              <div className="dashItems">
                <div className="dashItemImg">
                  <i className="fa-solid fa-bag-shopping"></i>
                </div>
                <h5>  {t("mySales")} </h5>
                {/* <h4> {countersData?.new_order} </h4> */}
              </div>
            </div>

            <div className="col-6 col-md-4">
              <Link to={"/mytickets"} className="nav-link">
                <div className="dashItems">
                  <div className="dashItemImg">
                    <i className="fa-solid fa-bag-shopping"></i>
                  </div>
                  <h5> {t("myTickets")} </h5>
                  <h4> {countersData?.my_ticket} </h4>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-4">
              <Link className="nav-link" to={"/wallet"}>
                <div className="dashItems">
                  <div className="dashItemImg">
                    <i className="fa-solid fa-bag-shopping"></i>
                  </div>
                  <h5> {t("wallet")} </h5>
                  <h4 className="d-flex justify-content-center ">
                    <h6 className="px-2 "> SAR</h6> {countersData?.wallet_balance}
                  </h4>
                </div>
              </Link>
            </div>
          </div>


  {/* التنقل بين الطلبات الجديده السابقه  */}
  <div className="containActivity  ActivitySalse d-flex pt-5 justify-content-between">
          <Link
            to="/dashboard"
            className="nav-link profileOpt"
            onClick={() => handleClick("/dashboard")}
          >
            <h6 className={activeLink === "/dashboard" ? "active" : ""}>
             {t("newOrders")} ({orders.length}) 
              {/* الطلبات الجديده ({countersData?.new_order})  */}
            </h6>
          </Link>
          <Link
            to="/prevOrders"
            className="nav-link activityOpt"
            onClick={() => handleClick("/prevOrders")}
          >
            <h6 className={activeLink === "/prevOrders" ? "active" : ""}>
             
             {t("prevOrders")}
            </h6>
          </Link>
        </div>













          {/* flow Chart */}
          <div className="row mb-4">
            <div
              className="col-md-12 mt-5 shadow-sm pointer"
              onClick={handleToggleChart}
            >
              <div className="monthStatictics">
                <label className="fs-4 py-3" style={{ cursor: "pointer" }}>
                 {t("salesDetails")}
                  <i class="fa-solid fa-angle-down fs-6 mx-1"></i>
                </label>
              </div>
            </div>

            {/* Flow Chart Section */}
            {isChartVisible && (
              <div className={`${style.flowChart} flowchart-container mt-3`}>
                <FlowChart />
              </div>
            )}
            {/* End Flow Chart */}
          </div>

          {/* <div className="Orders text-center">
            <h6> {countersData?.new_order} طلب حتي الان </h6>
            <h5> الطلبات الجديدة </h5>
          </div> */}

          {/* table */}

          {/* dash table with api */}
          <div className="dashTable">
            {error && (
              <div className="alert alert-danger mt-4">
                <p style={{ color: "red", textAlign: "center" }}>{error} </p>
              </div>
            )}{" "}
            {/* عرض رسالة الخطأ */}
            <table className="table text-center ">
              <thead>
                <tr>
                  <th>  {t("orderNum")} </th>
                  <th>  {t("eventName")} </th>
                  <th>  {t("numOfTik")} </th>
                  <th> {t("price")} </th>
                  <th>  {t("date")}  </th>
                  <th>  {t("status")}  </th>
                  <th>  {t("action")}   </th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr
                    key={order.id}
                    className={
                      order.status === "قيد الانتظار" 
                        ? "waiting"
                        : order.status === "مرفوضة" 
                        ? "rejected"
                        : "accepted"
                    }
                  >
                    <td data-title={t("orderNum")}>{order.id}</td>
                    <td data-title={t("eventName")}>{order.eventName}</td>
                    <td data-title={t("numOfTik")}>{order.quentity}</td>
                    <td data-title={t("price")}>{order.price} SAR</td>
                    <td data-title={t("date")}>{order.date}</td>
                    <td
                      data-title={t("status")}
                      className={
                        order.status === "قيد الانتظار" 
                          ? "waitingOrder"
                          : order.status === "مرفوضة"
                          ? "rejectedOrder"
                          : "acceptedOrder"
                      }
                    >
                      {order.status}
                    </td>

                    <td data-title={t("status")} className="px-2">
                      {order.status && (
                        <div className="actionTicket">
                          <button
                            className="bg-transparent border-0"
                            onClick={() => handleAcceptOrder(order.id)}
                          >
                            <i className="fa-solid fa-check text-success fs-5"></i>
                          </button>
                          <button
                            className="bg-transparent border-0"
                            onClick={() => handleRejectOrder(order.id)}
                          >
                            <i className="fa-solid fa-xmark text-danger fs-5"></i>
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
 

              </tbody>
            </table>
            {error && <p className="text-danger text-center">{error}</p>}
            {successMessage && (
              <p className="text-success  text-center">{successMessage}</p>
            )}{" "}
            {/* عرض الرسالة الناجحة */}
          </div>
        </div>
      </div>
    </>
  );
}
