/* global gtag */

import React, { useEffect, useState } from "react";
import "./ThanksPage.css";
import celebrate from "../../assets/celebrate2.png";
import { Link } from "react-router-dom";
import call from "../../assets/Phone Calling Rounded.png";
import axios from "axios";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";
import { useContact } from "../../Context/ContactContext";

export default function ThanksPage() {
  const { socialContactData } = useContact();

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [responseMessage, setResponseMessage] = useState(null); // الرسالة التي ستُعرض للمستخدم
  const [responseTitle, setResponseTitle] = useState(null); // العنوان الخاص بالرسالة
  const [isSuccess, setIsSuccess] = useState(false); // حالة النجاح
  const [responseData, setResponseData] = useState(null);
  // for Recive Visa Order Number
  const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
  const paymentId = urlParams.get("paymentId");
  const orderId = urlParams.get("orderId");

  // for Recive Tamara Order Number

  const text = window.location.hash;
  const queryString = text.split("?").slice(1).join("&"); // دمج الأجزاء بعد أول `?` مع `&`

  // تحويل المعاملات إلى كائن
  const paramss = queryString.split("&").reduce((acc, param) => {
    const [key, value] = param.split("=");
    acc[key] = value;
    return acc;
  }, {});

  // استخراج قيمة orderId
  console.log(paramss.orderId);
  const orderNumber = paramss.orderNumber;

  // for Payment visa

  const sendPaymentData = async () => {
    try {
      const Authorization = localStorage.getItem("userToken");
      const response = await axios.post(
        `${Api_Url}/visa/paid`,
        {
          payment_id: paymentId,
          order_number: orderId,
          payment_method: "visa",
        },
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer ${Authorization}`, // تأكد من وجود مسافة بعد "Bearer"
            "X-Language": i18n.language,
          },
        }
      );

      console.log("Response from backend:", response.data);
      if (response.data.success) {
        // const successMessage = response.data.data.success;
        // console.log("Success Alert Message:", successMessage);

        console.log("Response from backend:", response);
        setResponseData(response.data);

        if (response.data.success) {
          // تعيين عنوان ورسالة النجاح
          setResponseTitle(response.data.data.confirmed || "تمت العملية بنجاح");
          setResponseMessage(response.data.data.success);
          setIsSuccess(true); // تعيين الحالة كنجاح

          // هنا يتم إرسال حدث Conversion إلى Google
          if (window.gtag) {
            gtag("event", "conversion", {
              send_to: "AW-11525632479/Y0J9CLDT2v0ZEN_j7Pcq",
              value: 1.0,
              currency: "SAR",
              transaction_id: response.data.data.order_number, // يمكن استخدام رقم الطلب هنا
            });
          } else {
            console.error("gtag is not defined");
          }
        } else {
          // تعيين عنوان ورسالة الخطأ في حالة استجابة غير ناجحة
          setResponseTitle("خطأ في العملية");
          setResponseMessage("حدث خطأ غير متوقع. حاول مرة أخرى.");
          setIsSuccess(false); // تعيين الحالة كخطأ
        }

        // استدعاء دالة fetchActivities لجلب الأنشطة المحدثة بعد الدفع
      }
    } catch (error) {
      console.error("Error sending payment data:", error);

      // تعيين عنوان ورسالة الخطأ عند حدوث استثناء
      const errorMessage =
        error.response?.data?.message || "حدث خطأ غير متوقع. حاول مرة أخرى.";
      setResponseTitle("خطأ في العملية");
      setResponseMessage(errorMessage);
      setIsSuccess(false); // تعيين الحالة كخطأ
    }
  };

  // for Payment Tamara

  const sendPaymentDataTamara = async () => {
    try {
      const Authorization = localStorage.getItem("userToken");
      const response = await axios.post(
        `${Api_Url}/tamara/paid`,
        {
          ref_id: paramss.orderId,
          order_number: orderNumber,
          payment_method: "tamara",
        },
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer ${Authorization}`,
            "X-Language": i18n.language,
          },
        }
      );

      //   after edit

      console.log("Response from backend:", response);
      setResponseData(response.data);

      if (response.data.success) {
        // تعيين عنوان ورسالة النجاح
        setResponseTitle(response.data.data.confirmed || "تمت العملية بنجاح");
        setResponseMessage(response.data.data.success);
        setIsSuccess(true); // تعيين الحالة كنجاح

        // هنا يتم إرسال حدث Conversion إلى Google
        if (window.gtag) {
          gtag("event", "conversion", {
            send_to: "AW-11525632479/Y0J9CLDT2v0ZEN_j7Pcq",
            value: 1.0,
            currency: "SAR",
            transaction_id: response.data.data.order_number, // يمكن استخدام رقم الطلب هنا
          });
        } else {
          console.error("gtag is not defined");
        }
      } else {
        // تعيين عنوان ورسالة الخطأ في حالة استجابة غير ناجحة
        setResponseTitle("خطأ في العملية");
        setResponseMessage("حدث خطأ غير متوقع. حاول مرة أخرى.");
        setIsSuccess(false); // تعيين الحالة كخطأ
      }
    } catch (error) {
      console.error("Error sending payment data:", error);

      // تعيين عنوان ورسالة الخطأ عند حدوث استثناء
      const errorMessage =
        error.response?.data?.message || "حدث خطأ غير متوقع. حاول مرة أخرى.";
      setResponseTitle("خطأ في العملية");
      setResponseMessage(errorMessage);
      setIsSuccess(false); // تعيين الحالة كخطأ
    }
  };

  useEffect(() => {
    if (orderNumber) {
      sendPaymentDataTamara();
    }
  }, []);

  useEffect(() => {
    if (orderId) {
      sendPaymentData();
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="thnxContent mt-5">
          <div className="row">
            <div className="col-md-8">
              <div className="thnxMessage">
                {responseMessage && (
                  <div className="">
                    {/* عرض الصورة فقط في حالة النجاح */}
                    {isSuccess && <img src={celebrate} alt="" />}
                    <h4>{responseTitle}</h4>
                    {isSuccess ? (
                      <p className="text-success">{responseMessage}</p>
                    ) : (
                      <p className="text-danger">{responseMessage}</p>
                    )}
                    {/* <p>{responseMessage}</p> */}
                  </div>
                )}

                <div className="thnxBtn">
                  <Link to={"/Myactivity"}>
                    <button> {t("uploadMyTik")} </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="accState">
                {isSuccess && (
                  <>
                    {" "}
                    <h5 className="pt-3"> {t("accState")}</h5>
                    <div className="row accItems text-center">
                      {responseData && responseData.data ? (
                        <>
                          <div className="accontStateContent">
                            <div className="col-md-4 col-4">
                              <div className="accountState">
                                {/* اسم الفعالية */}
                                <span>
                                  {responseData.data.event ||
                                    "اسم الفعالية غير متوفر"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4 col-4">
                              <div className="accountState">
                                {/* العدد */}
                                <span>
                                  {responseData.data.total_ticket || "0"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4 col-4">
                              <div className="accountState">
                                {/* السعر الفردي */}
                                <span>
                                  {responseData.data.price_single_ticket
                                    ? `${responseData.data.price_single_ticket} SAR`
                                    : "السعر غير متوفر"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="accountStateHr"></div>

                          <div className="accontStateContent">
                            <div className="col-md-4 col-4">
                              <div className="accountState">
                                <span>{t("total")}</span>
                              </div>
                            </div>
                            <div className="col-md-4 col-4">
                              <div className="accountState">
                                <span>
                                  {responseData.data.total_ticket || "0"}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-4 col-4">
                              <div className="accountState">
                                {/* السعر الكلي */}
                                <span>
                                  {responseData.data.total_price
                                    ? `${responseData.data.total_price} SAR`
                                    : "المجموع غير متوفر"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>{" "}
                  </>
                )}

                <div className="accStateContact">
                  <h5> {t("needHelp")} </h5>

                  <h6 className="py-2">
                    {responseData &&
                    responseData.data &&
                    responseData.data.phone ? (
                      <a
                        href={`tel:${responseData.data.phone}`}
                        className="d-flex align-items-center nav-link"
                      >
                        <img src={call} alt="Call" className="" />{" "}
                        <span>{responseData.data.phone}</span>{" "}
                      </a>
                    ) : (
                      // in case respone is Error I will get data from footer "Context"
                      <span>
                        {" "}
                        <img src={call} alt="Call" className="" />{" "}
                        {socialContactData?.phone}{" "}
                      </span>
                    )}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
