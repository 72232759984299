import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import footLogo from "../../assets/logoa.png";
import call from "../../assets/Phone Calling Rounded.png";
import whatsapp from "../../assets/whatsapp.png";
import email from "../../assets/mail.png";
import icon1 from "../../assets/Ticket Sale.svg";
import { UserContext } from "../../Context/userContext";
import { useContact } from "../../Context/ContactContext";
import { useTranslation } from "react-i18next";

const Footer = () => {
  let { userToken } = useContext(UserContext);
  const { socialContactData } = useContact();
  const { t } = useTranslation();


  return (
    <div className="FooterFixed mt-auto  ">
      <div className="footer2   mt-5">
        <div className="footer2Content">
          <div className="footLogo text-center pt-5">
            <Link to={"/"} className="nav-link">
              <img src={socialContactData?.logo} alt="" className="my-2" />
              {/* <img src={footLogo} alt="" className="my-2" /> */}
            </Link>
          </div>
          <div className="navFooter">
            <nav className="navbar navbar-expand-lg py-4">
              <div className="container">
                <ul className="navbar-nav  mb-2 mb-lg-0 animateBar">
                  <li className="nav-item">
                    <Link
                      className="nav-link active animate fw-bold text-white"
                      to={"/"}
                    >
                  {t("Home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link animate fw-bold text-white"
                      to={"/aboutUs"}
                    >
                  {t("AboutUs")}
                  </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link animate fw-bold text-white"
                      to={"/faqSec"}
                    >
                  {t("FaQs")}
                  </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link animate fw-bold text-white"
                      to={"/privacy"}
                    >
                  {t("Privacy")}
                  </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link animate fw-bold text-white"
                      to={"/contactUs"}
                    >
                  {t("ContactUs")}
                  </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <div className="contactFoot">
            <div className="footItem">
              <a
                href={`tel:${socialContactData?.phone}`}
                className="d-flex align-items-center nav-link"
              >
                <img src={call} alt="Call" className="mx-2" />
                <div className="item text-white">
                  <span className="span1"> {t("Phone")}  </span>
                  <br />
                  <span className="span2" dir="ltr">
                    {" "}
                    {socialContactData?.phone}{" "}
                  </span>
                </div>
              </a>
            </div>

            <div className="footItem px-4">
              <a
                href={`mailto:${socialContactData?.email}`}
                className="d-flex align-items-center text-white nav-link"
              >
                <img src={email} alt="Email" className="mx-2" />
                <div className="item">
                  <span className="span1"> {t("email")} </span>
                  <br />
                  <span className="span2">
                    {" "}
                    {socialContactData?.email || "غير متوفر"}{" "}
                  </span>
                </div>
              </a>
            </div>

            <div className="footItem">
              <a
                href={`https://wa.me/${socialContactData?.whatsapp}`}
                className="d-flex align-items-center nav-link"
              >
                <img src={whatsapp} alt="WhatsApp" className="mx-2" />
                <div className="item text-white">
                  <span className="span1"> {t("whatsapp")} </span>
                  <br />
                  <span className="span2" dir="ltr">
                    {" "}
                    {socialContactData?.whatsapp}
                  </span>
                </div>
              </a>
            </div>
          </div>

          {/* <p className="text-light text-center my-4">
           {t("footerTitle")}
            1391016
          </p> */}
            <p className="text-light text-center my-4">
           {t("footerTitle")}
          </p>

          <div className="foot2brdr"></div>

          <div className="footFooter pt-4 mt-1">
            {userToken ? (
              <>
                <div className="footicon d-flex align-items-center justify-content-center">
                  <Link to={"/sellTicet"} className="nav-link">
                    <span>
                      {" "}
                      {t("sellyourTicket")}  <img src={icon1} alt="" />{" "}
                    </span>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="footicon d-flex align-items-center justify-content-center">
                  <Link to={"/login"} className="nav-link">
                    <span>
                      
                      {t("sellyourTicket")}  <img src={icon1} alt="" />
                    </span>
                  </Link>
                </div>
              </>
            )}

            <div className="foot2copy">
              <p> {t("footerCopyright")} </p>
            </div>

            <div className="footer2List">
              <div className="footer2Icons">
                <ul className="d-flex">
                  {/* تحقق من وجود رابط تويتر */}
                  {socialContactData?.twitter && (
                    <a
                      href={socialContactData?.twitter}
                      target="_blank"
                      className="nav-link"
                    >
                      <li className="pointer">
                        <i className="fa-brands fa-x-twitter"></i>
                      </li>
                    </a>
                  )}

                  {/* تحقق من وجود رابط تيك توك */}
                  {socialContactData?.tiktok && (
                    <a
                      href={socialContactData?.tiktok}
                      target="_blank"
                      className="nav-link"
                    >
                      <li className="pointer">
                        <i className="fa-brands fa-tiktok"></i>
                      </li>
                    </a>
                  )}

                  {/* تحقق من وجود رابط لينكدإن */}
                  {socialContactData?.linkedin && (
                    <a
                      href={socialContactData?.linkedin}
                      target="_blank"
                      className="nav-link"
                    >
                      <li className="pointer">
                        <i className="fa-brands fa-linkedin-in"></i>
                      </li>
                    </a>
                  )}

                  {/* تحقق من وجود رابط فيسبوك */}
                  {socialContactData?.facebook && (
                    <a
                      href={socialContactData?.facebook}
                      target="_blank"
                      className="nav-link"
                    >
                      <li className="pointer">
                        <i className="fa-brands fa-facebook-f"></i>
                      </li>
                    </a>
                  )}

                  {/* تحقق من وجود رابط إنستجرام */}
                  {socialContactData?.instagram && (
                    <a
                      href={socialContactData?.instagram}
                      target="_blank"
                      className="nav-link"
                    >
                      <li className="pointer">
                        <i className="fa-brands fa-instagram"></i>
                      </li>
                    </a>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
