import React, { useState, useEffect } from "react";
import style from "./PrevTalabat.module.css";
import { Link, useLocation } from "react-router-dom";
import file from "../../assets/File Text.png";
import verifiedBlue from "../../assets/Verified Checkblue.png";
import waiting from "../../assets/Group 2.png";
import axios from "axios";
import rejected from "../../assets/Group1.png";
import Swal from "sweetalert2";
import celebrate from "../../assets/celebrate2.png";
import Api_Url from "../../ApiConfig/ApiConfig";
import hosny from "../../assets/hosny.png";
import { useTranslation } from "react-i18next";
export default function PrevTalabat() {

  const [previous, setprevious] = useState([]);
  const [error, setError] = useState("");
  const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
  const paymentId = urlParams.get("paymentId");
  const orderId = urlParams.get("orderId");
  const [LOADING, setLOADING] = useState(true);
  const { t } = useTranslation();
  const { i18n } = useTranslation();



const [activeLink, setActiveLink] = useState("/prevTalbat");

// دالة لتحديث الرابط النشط عند الضغط
const handleClick = (link) => {
  setActiveLink(link);
};
  const fetchActivities = async () => {
    try {
      const Authorization = localStorage.getItem("userToken");
      const response = await axios.get(`${Api_Url}/activities/previous`, {
        headers: {
          Accept: "application/json",
          "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          Authorization: `Bearer ${Authorization}`, 
          "X-Language": i18n.language

        },
      });
      setprevious(response.data.data.previous);
    } catch (err) {
      setError("فشل في تحميل البيانات.");
    }
    finally
    {
      setLOADING(false)
    }
  };

  //   For Payment

  // const sendPaymentData = async () => {
  //   try {
  //     const Authorization = localStorage.getItem("userToken");
  //     const response = await axios.post(
  //       `${Api_Url}/visa/paid`,
  //       {
  //         payment_id: paymentId,
  //         order_number: orderId,
  //         payment_method: "visa",
  //       },
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
  //           Authorization: `Bearer ${Authorization}`, // تأكد من وجود مسافة بعد "Bearer"
  //         },
  //       }
  //     );

  //     console.log("Response from backend:", response.data);
  //     if (response.data.success) {
  //       const successMessage = response.data.data.success;
  //       console.log("Success Alert Message:", successMessage);

  //       await Swal.fire({
  //         title: "تم الدفع",
  //         text: successMessage,
  //         imageUrl: `${celebrate}`,
  //         imageWidth: 115,
  //         imageHeight: 115,
  //         confirmButtonText: "موافق",
  //         width: "392px",
  //         customClass: {
  //           text: "swalTextFatoraa",
  //           confirmButton: "swalButtonFatoraa",
  //           title: "swalTitleFatoraa",
  //         },
  //       });

  //       // استدعاء دالة fetchActivities لجلب الأنشطة المحدثة بعد الدفع
  //       await fetchActivities();
  //     }
  //   } catch (error) {
  //     console.error("Error sending payment data:", error);
  //   }
  // };

  useEffect(() => {
    // if (orderId) {
    //   sendPaymentData();
    // }

    fetchActivities();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if(LOADING)
    {
      return (
        <>
          {" "}
          <div
            className="loading"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <i
              className="fas fa-spinner fa-spin"
              style={{ fontSize: "3rem", color: "#3498db" }}
            ></i>
          </div>
        </>
      );
    }
  return (
    <>
      <div className="container">
        <div className="CommonNoise">
          <div className="commonDesc">
          <h5> {t("activities")}  </h5>
            <h6>
              <span> {t("Home")} \ </span> {t("activities")}
            </h6>
          </div>
        </div>
        {/* التنقل بين فعالياتي والبروفايل  */}
        <div className="containActivity d-flex my-4 justify-content-between">
        <Link
          to="/profile"
          className="nav-link profileOpt"
          onClick={() => handleClick("/profile")}
        >
          <h6 className={activeLink === "/profile" ? "active" : ""}> {t("profile")}</h6>
        </Link>
        <Link
          to="/Myactivity"
          className="nav-link activityOpt"
          onClick={() => handleClick("/Myactivity")}
        >
          <h6 className={activeLink === "/Myactivity" || activeLink === "/prevTalbat" ? "active" : ""}>
          {t("activities")}
          </h6>
        </Link>
      </div>

        <div className={`${style.profileSec}`}>
          <div className="row g-3 mb-5">
            <div className="col-md-7 mx-auto">
              <div className={`${style.profileItem} ${style.Activities} p-4`}>
                <div className="row">
                  <div className="col-md-12">
                    <div className={`${style.profileSetting}`}>
                      {/* التنقل بين الطلبات الحاليه والطلبات السابقه */}

                      <div className="containActivity talabatActivity d-flex justify-content-between">
        <Link
          to="/Myactivity"
          className="nav-link profileOpt"
          onClick={() => handleClick("/Myactivity")}
        >
          <h6 className={activeLink === "/Myactivity" ? "active" : ""}>
          {t("current")}
          </h6>
        </Link>
        <Link
          to="/prevTalbat"
          className="nav-link activityOpt"
          onClick={() => handleClick("/prevTalbat")}
        >
          <h6 className={activeLink === "/prevTalbat" ? "active" : ""}>
          {t("previous")}
          </h6>
        </Link>
      </div>
                      {/* التنقل بين الطلبات الحاليه والطلبات السابقه */}

            




                      <div className="row g-2">
                        {previous.map((previousTalab) => (
                          <div
                            key={previousTalab.id}
                            className={`${style.effectContent} d-flex flex-wrap`}
                          >
                            <div
                              className={`${style.effectiveItem} d-flex flex-wrap align-items-center w-100`}
                            >
                              <div className="col-12 col-md-2">
                                <img
                                  src={previousTalab.event_image}
                                  alt={previousTalab.event_name}
                                  className={`${style.activityImg}`}
                                />
                              </div>

                              <div className="col-12 col-md-3 px-2">
                                <div className={`${style.effectiveDetails}`}>
                                  <h4>{previousTalab.event_name}</h4>
                                  
                                </div>
                              </div>

                              <div className="col-4 col-md-2 px-2">
                                <div className={`${style.effectiveDetails}`}>
                                
                                  <h5>{t("date")}</h5>
                                  <h6>{previousTalab.event_date}</h6>
                                </div>
                              </div>

                              <div className="col-4 col-md-2">
                                <div className={`${style.effectiveDetails}`}>
                                  
                               
                                  <h5>{t("community")}</h5>
                                    <h6>{previousTalab.comunity}</h6>

                                 
                                </div>
                              </div>
                              <div className="col-4 col-md-1">
                                <div className={`${style.effectiveDetails}`}>
                                  
                                  <h5> {t("numOfTik")}</h5>
                                  <h6>{previousTalab.quantity}</h6>
                                  </div></div>

                              <div className="col-8 col-md-2">
                                <div className={`${style.effectiveDetails}`}>
                                  <div className={`${style.varifiedEffect}`}>
                                    <p
                                      className={
                                        previousTalab.status === "تمت الموافقة" 
                                          ? "text-info"
                                          : previousTalab.status === "تم الرفض"
                                          ? "text-danger"
                                          : "text-secondary"
                                      }
                                    >
                                      {/* <img
                                        src={
                                          previousTalab.status === "تمت الموافقة"
                                            ? verifiedBlue
                                            : previousTalab.status === "تم الرفض"
                                            ? rejected
                                            : waiting
                                        }
                                        className="ps-1"
                                        alt=""
                                      /> */}
                                      {previousTalab.status}
                                      {previousTalab.webook && (
                                        <div
                                          className={`${style.effectiveDetails}`}
                                        >
                                          <h6>{previousTalab.webook}</h6>
                                        </div>
                                      )}
                                    </p>
                                  </div>
                                  

                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
