import React, { useEffect, useState } from "react";
import style from "./AboutUs.module.css";
import handshake from "../../assets/handshake.png";
import prefect from "../../assets/perfect.png";
import WhyUseTik from "../WhyUseTik/WhyUseTik";
import SuccessSlider from "../SuccessSlider/SuccessSlider";
import img10 from "../../assets/image 10.png";
import img11 from "../../assets/image 11.png";
import img12 from "../../assets/image 12.png";
import perfect from "../../assets/perfect.png";
import ticket from "../../assets/Ticket Sale.png";
import podcast from "../../assets/Podcast.png";
import pdf from "../../assets/pdf.png";
import roundCircle from "../../assets/Check Circle.png";
import roundInfo from "../../assets/Info Circle.png";
import whats from "../../assets/whatsappIcon 1.png";
import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { t } = useTranslation();

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="container">
        <div className="CommonNoise  ">
          <div className="commonDesc">
            <h5> {t("AboutUS")} </h5>
            <h6>
              {" "}
              <span> {t("Home")} \ </span> {t("AboutUS")}
            </h6>
          </div>
        </div>
        <div className={`${style.aboutUs}`}>
          <div className="tikKnow text-center mt-5">
            <h6>
              {" "}
              {t("whyUsetitle")} <img src={prefect} alt="" />{" "}
            </h6>
            <h5> {t('AboutTikevent')}</h5>
          </div>

          <div className="row g-2 mt-3">
            <div className="col-md-6">
              <div className={`${style.aboutItem}  p-5 `}>
                <h4> {t("AboutUS")} </h4>
                <p>
                  {/* {t("AboutUSDefine")} <span> 1391016 </span> */}
                  {t("AboutUSDefine")}
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className={`${style.aboutItem}  p-5 `}>
                <h4> {t("ourGoals")} </h4>
                <p>
                {t("ourGoalsDefine")}
                </p>
              </div>
            </div>
          </div>

          <div className="whyuseTik pt-4">
            <WhyUseTik />
          </div>

          <div className={`${style.sellTechnique}   text-center mt-5 `}>
            <div className="py-4">
              <h6>
                {" "}
              {t("SellTech")} <img src={prefect} alt="" />{" "}
              </h6>
              <h5> {t("SellTechTitle")}</h5>
            </div>

            
            <div className="row mt-5 g-2">
              {isMobile ? (
                <Slider {...sliderSettings}>
                  <div className="col-md-4">
                    <div className={`${style.sellItem} me-3 `}>
                      <div className={`${style.sellImg}  ms-auto`}>
                        <img src={ticket} alt="" />
                      </div>
                      {/* <p className="px-2"> ١- اضغط على خيار بيع تذكرتك  </p> */}
                      <p className="px-2"> {t("SellTechopt1")} </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className={`${style.sellItem} me-3`}>
                      <div className={`${style.sellImg} ms-auto `}>
                        <img src={podcast} alt="" />
                      </div>
                      {/* <p className="px-2">
                        ٢- اختار الفعالية والفئة اللتي تملكها والسعر المراد بيع
                        التيكت به
                      </p> */}
                        <p className="px-2"> {t("SellTechopt2")} </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className={`${style.sellItem} me-3`}>
                      <div className={`${style.sellImg} ms-auto`}>
                        <img src={pdf} alt="" />
                      </div>
                      <p className="px-2"> {t("SellTechopt3")} </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className={`${style.sellItem}   me-3`}>
                      <div className={`${style.sellImg} ms-auto`}>
                        <img src={roundCircle} alt="" />
                      </div>
                       <p className="px-2"> {t("SellTechopt4")} </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className={`${style.sellItem} me-3`}>
                      <div className={`${style.sellImg} ms-auto `}>
                        <img src={whats} alt="" />
                      </div>
                      <p className="px-2"> {t("SellTechopt5")} </p>
                    </div>
                  </div>
                </Slider>
              ) : (
                <div className="row mt-5 g-2">
                  {/* المحتوى الثابت هنا بدون سلايدر */}
                  <div className="col-md-4">
                    <div className={`${style.sellItem}`}>
                      <div className={`${style.sellImg}`}>
                        <img src={ticket} alt="" />
                      </div>
                      <p className="px-2"> {t("SellTechopt1")} </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className={`${style.sellItem}`}>
                      <div className={`${style.sellImg}`}>
                        <img src={podcast} alt="" />
                      </div>
                       <p className="px-2"> {t("SellTechopt2")} </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className={`${style.sellItem}`}>
                      <div className={`${style.sellImg}`}>
                        <img src={pdf} alt="" />
                      </div>
                      <p className="px-2"> {t("SellTechopt3")} </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className={`${style.sellItem}`}>
                      <div className={`${style.sellImg}`}>
                        <img src={roundCircle} alt="" />
                      </div>
                      <p className="px-2"> {t("SellTechopt4")} </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className={`${style.sellItem}`}>
                      <div className={`${style.sellImg}`}>
                        <img src={whats} alt="" />
                      </div>
                      <p className="px-2"> {t("SellTechopt5")} </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="susseccPartner mb-5">
            <div className="commonHeadind  my-4 pt-4 ">
              <h6 className="py-1">
                {" "}
                {t("partnersSuccessHead")} <img src={handshake} alt="" className="" />{" "}
              </h6>
              <h5> {t("partnersSuccessTitle")} </h5>
            </div>
            <SuccessSlider />
          </div>
        </div>
      </div>
    </>
  );
}
