import React, { useEffect, useRef, useState } from "react";
import icon1 from "../../assets/Ticket Sale.png";
import navLogoa from "../../assets/logoa.png";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Navbar2 from "../Navbar2/Navbar2";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const [setOpenProfile] = useState(false);
  const dropdownRef = useRef(null);
  let {t} = useTranslation()
  


// Function For Redirect

  const handleLoginClick = () => {
    const currentPath = location.pathname + location.search; // يشمل المسار والـ query parameters
    navigate(`/login?redirect=${encodeURIComponent(currentPath)}`);
  };






  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenProfile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <nav class="navbar navbar-expand-lg " data-aos="fade-down" id="baseNav">
        <div class="container">
          <div className="navItem  ">
            <Link onClick={handleLoginClick} className="nav-link">
              <span>
                {" "}
                {t("sellyourTicket")}     <img src={icon1} alt="" />{" "}
              </span>
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center ">
              <Link className="nav-link" to={"/"}>
                <li className="nav-item  navLogo mx-2">
                  <img src={navLogoa} alt="logo-img" />
                </li>
              </Link>
            </ul>



            <ul className="navbar-nav  me-auto mb-2 mb-lg-0 d-flex align-items-center ">
              <li>
                <Link onClick={handleLoginClick} className="nav-link">
                {t("login")}
                </Link>
              </li>
              <li>
                <Link to={"/signUp"} className="nav-link">
                {t("signUp")}
                  
                </Link>
              </li>
              <li><LanguageSwitch/></li>

            </ul>
          </div>
        </div>
      </nav>

      <div className="navBrdr"></div>

      <Navbar2 />
    </>
  );
}
