import React, { useContext, useEffect } from "react";
import style from "./Profile.module.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { useState } from "react";
import { MdDelete } from "react-icons/md";
import profile from "../../assets/profile.png";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Api_Url from "../../ApiConfig/ApiConfig";
import { UserContext } from "../../Context/userContext";
import { useUser } from "../../Context/UserApiContext";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export default function Profile() {
  const { t } = useTranslation();

  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("No selected Photo");
  const location = useLocation(); // الحصول على موقع الصفحة الحالي
  const [activeLink, setActiveLink] = useState(location.pathname); // تعيين الرابط النشط بناءً على الموقع الحالي
  const handleClick = (link) => {
    setActiveLink(link); // تعيين الرابط النشط
  };
  const [successMessage, setSuccessMessage] = useState("");
  const { id } = useParams(); // استخدم useParams لاستخراج id من URL
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false); // حالة جديدة لتتبع حالة الحذف
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  let { userToken, setUserToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  console.log(id)
  // تغيير الباسورد api
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("كلمة المرور الجديدة وتأكيد كلمة المرور لا يتطابقان.");
      return;
    }

    try {
      const Authorization = localStorage.getItem("userToken"); // استرجاع التوكن من localStorage
      const space = " "; // المتغير للمسافة
      const response = await axios.put(
        `${Api_Url}/user/${id}`,
        {
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: confirmPassword,
        },
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer${space}${Authorization}`, // إضافة الكلمة والمسافة بشكل صحيح
          },
        }
      );

      if (response.status === 200) {
        setSuccess("تم تحديث كلمة المرور بنجاح.");
        setError(""); // مسح أي خطأ سابق
      }
    } catch (err) {
      setError("فشل في تحديث كلمة المرور. تأكد من صحة كلمة المرور الحالية.");
      // setSuccess('')
    }
  };

  // تغيير الباسورد api End

  // تحديث بيانات الملف الشخصي

  // Get User Data Api

  // const fetchUserData = async () => {
  //   const Authorization = localStorage.getItem("userToken");
  //   const space = " ";
  //   try {
  //     const response = await axios.get(`${Api_Url}/user`, {
  //       headers: {
  //         Accept: "application/json",
  //         "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
  //         Authorization: `Bearer${space}${Authorization}`,
  //       },
  //     });
  //     // console.log(response.data.data.user.name);
  //     return response.data.data.user;
  //   } catch (error) {
  //     console.error("Error fetching user data:", error);
  //     setSuccessMessage("فشل في استرجاع البيانات.");
  //   }
  // };




  // FUNCTION TO DELETE IMAGE
  const handleDeleteImage = async () => {
    const confirmed = await Swal.fire({
      title: "هل تريد حذف الصورة",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085D6",
      confirmButtonText: "نعم",
      cancelButtonText: "لا"
    });
    if (confirmed.isConfirmed) {
      try {
        const Authorization = localStorage.getItem("userToken");
        const response = await axios.get(`${Api_Url}/delete/image/user`, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer ${Authorization}`
          }
        });
        console.log(response);
        if (response.status === 200) {
          Swal.fire({
            title: "تم بنجاح",
            text: `${response.data.data.success}`,
            icon: "success",
            confirmButtonText: "نعم"
          });
          setImage(null);
          // setUser((prevUser) => ((

          //   console.log(prevUser)
          // ))); // UPDATE STATE OF USER IF AVATAR IS EMPTY SRC " "
          // return { ...prevUser, avatar: "" }
          setUser(prevUser => {
            console.log(prevUser)
            return { ...prevUser, avatar: "" }
          })
        
        }
      } catch (error) {
        console.log(error);
      }
    }
  };












    const fetchUserData = async () => {
    const Authorization = localStorage.getItem("userToken");
    const space = " ";
    try {
      const response = await axios.get(`${Api_Url}/user`, 
        
        {
        headers: {
          Accept: "application/json",
          "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          Authorization: `Bearer${space}${Authorization}`,
        },
      });
      // استرجاع id المستخدم
      console.log(response.data.data.user.id)
      return response.data.data.user ; // استرجاع الـ id مباشرة
    } catch (error) {
      console.error("Error fetching user data:", error);
      setSuccessMessage("فشل في استرجاع البيانات.");
    }
    finally{
      setLoading(false)
    }
  };
  

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      iban: "",
      avatar: user?.avatar || null ,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(`{${t("nameReqErr")}}`),
      email: Yup.string()
        .email(`{${t("emailinValidErr")}}`)
        .required(`{${t("emailreqErr")}}`),
      phone: Yup.string().required(`{${t("phoneReqErr")}}`),
      iban: Yup.string().required(`{${t("ibanReq")}}`),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("iban", values.iban);
      formData.append("avatar", image);
   

      if(values.avatar)
      {
        formData.append("avatar", values.avatar);

      }
      
      // api for update user Data
      try {
        const Authorization = localStorage.getItem("userToken");
        const response = await axios.post(`${Api_Url}/user`, formData, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer ${Authorization}`,
          },
        });

        console.log(response.data);
        setSuccessMessage(response.data.data.success);
        setUser(response.data.data.user)
        
      } catch (error) {
        if (error.response) {
          setErrorMessage(
            error.response.data.message || "حدث خطأ أثناء الحفظ."
          ); // عرض رسالة الخطأ
        } else {
          setErrorMessage("تعذر الاتصال بالخادم. حاول مرة أخرى لاحقًا."); // إذا لم يكن هناك استجابة
        }
      }
      finally{
        setLoading(false)
      }
    },
  });

  useEffect(() => {
    const getUserData = async () => {
      const userData = await fetchUserData();
      if (userData) {
        formik.setValues({
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          iban: userData.iban,
          avatar: userData.avatar,
        });
        setImage(userData.avatar);
      }
    };

    getUserData();
  }, []);

  //  انتهاء api تعديل البيانات الشخصيه */

  // sweet alert

  //delete ACCount/////////////////////////////////////////////////////////////


  // code after updata
  const handleDelete = async () => {
    if (!isChecked) {
      Swal.fire({
        title: "تحذير!",
        text: `{${t("swalDeleteConf")}}`,
        icon: "warning",
        confirmButtonText: `{${t("ok")}}`,
        customClass: {
          confirmButton: "btn-confirm-warning",
        },
      });
      return;
    }
  
    // جلب id المستخدم من دالة fetchUserData
    const userId = await fetchUserData();
  
    Swal.fire({
      title: `${t("swalSure")}`,
      text: `${t("swalSireTitle")}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "rgba(0, 189, 237, 1)",
      confirmButtonText: `${t("swalSureAnswer")}`,
      cancelButtonText: `{${t("cancel")}}`,
      customClass: {
        popup: "custom-popup",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const Authorization = localStorage.getItem("userToken"); // استرجاع التوكن من localStorage
          const space = " "; // المتغير للمسافة

          // delete depend on remove Token from localStorage not ID , but ApiReq need it  
          const response = await axios.delete(`${Api_Url}/user/1`, {
            headers: {
              Accept: "application/json",
              "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
              Authorization: `Bearer${space}${Authorization}`,
            },
          });

          
  
          if (response.status === 200) {
            setIsDeleted(true); // تحديث الحالة بعد نجاح الحذف
            localStorage.removeItem("userToken");
            setUserToken(null);
            console.log("رد السيرفر:", response.data);
            Swal.fire({
              title: `${t("deleteDone")}`,
              text: `{${t("deleteDoneConfirm")}}`,
              icon: "success",
              confirmButtonText: `${t("ok")}`,
              customClass: {
                confirmButton: "btn-confirm-success",
                popup: "custom-popup",
              },
            });
                      
            
  
            setTimeout(() => {
              navigate('/')
            }, 3000);
          }
        } catch (error) {
          console.error("خطأ أثناء محاولة الحذف:", error);
          Swal.fire({
            title: "خطأ!",
            text: "حدث خطأ أثناء حذف الحساب. يرجى المحاولة لاحقًا.",
            icon: "error",
            confirmButtonText: `{${t("ok")}}`,
            customClass: {
              confirmButton: "btn-confirm-error",
              popup: "custom-popup",
            },
          });
        }
      }
    });
  };
  
  
  //delete ACCount/////////////////////////////////////////////////////////////


  
  if (loading) {
    return (
      <div>
        <div
          className="loading"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <i
            className="fas fa-spinner fa-spin"
            style={{ fontSize: "3rem", color: "#3498db" }}
          ></i>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="CommonNoise  ">
          <div className="commonDesc">
            <h5> {t("Profile")} </h5>
            <h6>
              {" "}
              <span> {t("Home")} \ </span> {t("Profile")}
            </h6>
          </div>
        </div>

        {/* التنقل بين فعالياتي والبروفايل  */}

        <div className="containActivity d-flex my-4 justify-content-between">
          <Link
            to="/profile"
            className="nav-link profileOpt"
            onClick={() => handleClick("/profile")}
          >
            <h6 className={activeLink === "/profile" ? "active" : ""}>
              {" "}
              {t("Profile")}
            </h6>
          </Link>
          <Link
            to="/Myactivity"
            className="nav-link activityOpt"
            onClick={() => handleClick("/Myactivity")}
          >
            <h6 className={activeLink === "/Myactivity" ? "active" : ""}>
              {" "}
              {t("activities")}
            </h6>
          </Link>
        </div>

        {/* نهايه التنقل بين فعالياتي والبروفايل  */}

        <div className={`${style.profileSec}`}>
          <div className="row g-3 mb-5">
            {/* My Profile */}
            <div className="col-md-6">
              <div className={`${style.profileItem} p-4`}>
                <div className="row">
                  <div className="col-md-12">
                    <div className={`${style.profileSetting}`}>
                      <div className={`${style.head}`}>
                        <h6> {t("Profile")} </h6>
                        <h5> {t("profileSetting")} </h5>
                      </div>

                      <form onSubmit={formik.handleSubmit}>
                        <div className="profileForm">
                         

<div className="upload-photo">
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={({ target: { files } }) => {
              if (files[0]) {
                setImage(URL.createObjectURL(files[0]));
                formik.setFieldValue("avatar", files[0]);
              }
            }}
            id="fileInput"
          />
          <label htmlFor="fileInput" className="photoChange pointer">
            {image ? (
              <img src={image} alt="User Avatar" width={80} height={80} />
            ) : (
              <div className="d-flex align-items-center">
                <img src={profile} alt="Default Profile" />
                <p className="px-2"> {t("changrProfilePic")}  </p>
              </div>
            )}
          </label>
          {image && (
            <MdDelete
              onClick={() => {
               
                handleDeleteImage() 
              }}
              className="delete-icon"
            />
          )}
        </div>
                          <div className="signForm">
                            <label htmlFor="name" className="w-100 py-2">
                              {t("userName")}
                            </label>
                            <input
                              type="text"
                              id="name"
                              className="w-100"
                              placeholder=""
                              onChange={formik.handleChange}
                              value={formik.values.name}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <div className="text-danger">
                                {formik.errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="signForm">
                            <label htmlFor="phone" className="w-100 py-2">
                              {t("Phone")}
                            </label>
                            <input
                              id="phone"
                              type="tel"
                              className="w-100"
                              dir="rtl"
                              {...formik.getFieldProps("phone")}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                              <div>{formik.errors.phone}</div>
                            ) : null}
                          </div>
                          <div className="signForm">
                            <label htmlFor="email" className="w-100 py-2">
                            {t("email")}
                            </label>
                            <input
                              type="email"
                              id="email"
                              className="w-100"
                              placeholder=""
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div>{formik.errors.email}</div>
                            ) : null}
                          </div>
                          <div className="signForm">
                            <label htmlFor="iban" className="w-100 py-2">
                              IBAN
                            </label>
                            <input
                              type="text"
                              id="iban"
                              className="w-100"
                              placeholder="SA51100000111"
                              onChange={formik.handleChange}
                              value={formik.values.iban}
                            />
                            {formik.touched.iban && formik.errors.iban ? (
                              <div>{formik.errors.iban}</div>
                            ) : null}
                          </div>
                          <div className="signForm mt-4">
                            <button type="submit" className="w-100">
                              {t("save")}
                            </button>
                          </div>
                          {successMessage && (
                            <div className="success-message text-success text-center mt-2">
                              {successMessage}
                            </div>
                          )}
                          {errorMessage && (
                            <div className="error-message text-danger text-center mt-2">
                              {errorMessage}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* //////////////////////////////////////////////////////////////////// */}
            {/* تغيير كلمه المرور */}

            <div className="col-md-6">
              <div className={`${style.profileItem}  p-4 `}>
                <div className="row">
                  <div className="col-md-12">
                    <div className={`${style.profileSetting}`}>
                      <div className={`${style.profileForm}`}>
                        <div className="signForm">
                          <form onSubmit={handleSubmit}>
                            <div>
                              <label htmlFor="passProfile">
                              {t("currentPassword")}
                              </label>
                              <input
                                type="password"
                                id="passProfile"
                                className="p-3 mb-2"
                                value={currentPassword}
                                onChange={(e) =>
                                  setCurrentPassword(e.target.value)
                                }
                                required
                              />
                            </div>

                            <div>
                              <label htmlFor="NewpassProfile">
                              {t("newPassword")}
                              </label>
                              <input
                                type="password"
                                id="NewpassProfile"
                                className="p-3 mb-2"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                              />
                            </div>

                            <div>
                              <label htmlFor="confirmPassProfile">
                              {t("confirmNewPassword")}
                              </label>
                              <input
                                type="password"
                                id="confirmPassProfile"
                                className="p-3 mb-2"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                required
                              />
                            </div>

                            {error && <p className="text-danger">{error}</p>}
                            {success && (
                              <p className="text-success">{success}</p>
                            )}

                            <div className="mt-4">
                              <button type="submit"> {t("updatePassword")} </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className={`${style.profileBrdr}  my-4  `}></div>

                      {/* حذف الحساب  */}
                      <div className={`${style.deleteAccountHead}  pt-5  `}>
                        <h6> {t("deleteAcc")} </h6>
                        <h5> {t("deleteAccConfirm")} </h5>
                      </div>

                      <div className={`${style.deleteAccountConfirm} `}>


                        {/* delete Account with api */}


                        <div>
      {isDeleted && (
        <p className="alert alert-success" role="alert">
          {t("successDelete")}
        </p>
      )}

      <div>
        <div className={`${style.deleteCheck}`}>
          <input
            type="checkbox"
            id="deleteCheck"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <label htmlFor="deleteCheck" className="px-1 pointer">
            {t("deleteAnswer")}
          </label>
        </div>
        <div
          className={`${style.deleteConfirm} pointer`}
          onClick={isChecked ? handleDelete : null}
          style={{ cursor: isChecked ? "pointer" : "not-allowed", opacity: isChecked ? 1 : 0.5 }}
        >
          <i className="fa-regular fa-trash-can"></i>
          <span> {t("deleteAcc")} </span>
        </div>
      </div>
    </div>

           
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
