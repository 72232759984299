import React, { useContext, useEffect } from "react";
import calendar from "../../assets/Calendar.png";
import style from "./SearchResult.module.css";
import { Link } from "react-router-dom";
import { SearchContext } from "../../Context/searchContext";
import { useTranslation } from "react-i18next";
import SearchLabel from "../SearchLabel/SearchLabel";
export default function SearchResult() {
  const { t } = useTranslation();

  const { searchResults, setSearchTerm, resultDataNotFound } =
    useContext(SearchContext); // GET DATA SEARCH FROM CONTEXT
  // SAVE DATA USER SEARCH IN LOCAL STORGE
  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      localStorage.setItem("searchResults", JSON.stringify(searchResults));
      localStorage.removeItem("resultDataNotFound");
    }
  }, [searchResults]);
  // GET DATA FROM LOCAL STORAGE INF NO SEARCH RESALUTE ARE AVAILABLE
  const storedResults = JSON.parse(localStorage.getItem("searchResults")) || [];
  const storedResultDataNotFound = localStorage.getItem("resultDataNotFound");

  useEffect(() => {
    return () => {
      setSearchTerm("");
      // RESET SEARCH INPUT WHEN USER LEAVE PAGE SEARCH RESULTE
      localStorage.removeItem("searchResults"); // REMOVE DATA FROM LOCAL STORGE WHEN USER LEAVE PAGE SEACH RESLUTE
      localStorage.removeItem("resultDataNotFound");
    };
  }, [setSearchTerm]);
  // SAVE resultDataNotFound TO LOCAL STORAGE
  useEffect(() => {
    if (resultDataNotFound) {
      localStorage.setItem("resultDataNotFound", resultDataNotFound);
    }
  }, [resultDataNotFound]);
  return (
    <>
      <div className="container">
        <div className="headSrch  my-5 pt-4">
          <h4 className="text-center text-info browseEvents  ">
            {" "}
            {t("browseEvents")}{" "}
          </h4>
          <SearchLabel />
        </div>

        <div className="searchResult">
          <div className="row g-2 forMainSli">
            {(searchResults && searchResults.length > 0
              ? searchResults
              : storedResults
            ).length > 0 ? (
              <>
                {(searchResults && searchResults.length > 0
                  ? searchResults
                  : storedResults
                ).map((event) => (
                  <div className="col-md-3 col-6" key={event.id}>
                    <Link
                      to={`/eventDetails/${event.id}/${event.slug}`}
                      className="nav-link"
                    >
                      <div className={`${style.sellItem} p-3`}>
                        <img
                          src={event.image}
                          alt={event.name}
                          className={`w-100 ${style.sellItemImage}`}
                        />
                        <div>
                          <h5>{event.name}</h5>
                          <h6>
                            {event.date} <img src={calendar} alt="" />{" "}
                            {event.time}
                          </h6>
                        </div>
                        <div className={`${style.sellBtn} text-center`}>
                          <button> {t("buy")}</button>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            ) : (
              <p className="fs-4 text-center text-danger">
                {storedResultDataNotFound || resultDataNotFound}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
