import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import Api_Url from "../ApiConfig/ApiConfig";

const ContactContext = createContext();

export const useContact = () => {
    return useContext(ContactContext);
};
export const ContactProvider = ({ children }) => {
    const [socialContactData, setsocialContactData] = useState(null);

    const Authorization = localStorage.getItem("userToken");
    const headers = {
        Accept: "application/json",
        "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
        Authorization: `Bearer ${Authorization}`
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${Api_Url}/options`, {headers});
                if (response.data.success) {
                     setsocialContactData(response.data.data);
                    // console.log(response.data.data)
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    return (
        <ContactContext.Provider value={{socialContactData}}>
            {children}
        </ContactContext.Provider>
    );
};