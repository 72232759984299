import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditmyTicket from "../EditmyTicket/EditmyTicket";
import ShareMyTicket from "../ShareMyTicket/ShareMyTicket";
import Swal from "sweetalert2";
import axios from "axios";
import PauseTicket from "../PauseTicket/PauseTicket";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useUser } from "../../Context/UserApiContext";
import UploadedTicket from "../UploadedTicket/UploadedTicket";
import { useTranslation } from "react-i18next";

export default function MyTickets({ ticket = {} }) {
  const [shareTicket, setShareTicket] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [editStates, setEditStates] = useState({});
  const [error, setError] = useState(null);
  const [isStopped, setIsStopped] = useState(ticket?.status === "متوقفة");
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [updateTik, setupdateTik] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false); // للتحكم في إظهار المودال
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { countersData } = useUser();

  // couter For User

  const openModal = (id) => {
    console.log(`Opening modal for ticket ID: ${id}`);
    setSelectedTicketId(id);
    setShowModal(true);
  };

  const openUploadModal = (id) => {
    console.log(`Opening upload modal for ticket ID: ${id}`);
    setSelectedTicketId(id); // تعيين ID التذكرة
    setShowUploadModal(true); // فتح المودال
  };
  // Api for My Tickets
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const Authorization = localStorage.getItem("userToken");
        const headers = {
          Accept: "application/json",
          "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
          Authorization: `Bearer ${Authorization}`,
          "X-Language": i18n.language,
        };

        const response = await axios.get(`${Api_Url}/my_ticket`, { headers });
        setTickets(response.data.data.tickets || []);
      } catch (error) {
        setError(
          error.response
            ? error.response.data.message
            : "حدث خطأ أثناء تحميل البيانات."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, []);

  const handleUpdateTicket = (ticketId, newPrice) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket) =>
        ticket.id === ticketId
          ? { ...ticket, price: Math.round(newPrice) } // تقريب السعر إلى أقرب عدد صحيح
          : ticket
      )
    );
  };

  const handleUpdateTicketStatus = (ticketId, newStatus) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket) =>
        ticket.id === ticketId
          ? { ...ticket, auto_approved_status: newStatus }
          : ticket
      )
    );
  };

  if (isLoading) {
    return (
      <>
        {" "}
        <div
          className="loading"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <i
            className="fas fa-spinner fa-spin"
            style={{ fontSize: "3rem", color: "#3498db" }}
          ></i>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container">
        <div className="CommonNoise">
          <div className="commonDesc">
            <h5> {t("dashboardTitle")} </h5>{" "}
            <h6>
              <span>{t("Home")} \ </span> {t("myTickets")}
            </h6>
          </div>
        </div>

        <div className="row my-5 g-2 ">
          <div className="col-6 col-md-4">
            <Link to={"/dashboard"} className="nav-link">
              <div className="dashItems">
                <div className="dashItemImg">
                  <i className="fa-solid fa-bag-shopping"></i>
                </div>
                <h5> {t("mySales")} </h5>
                {/* <h4> {countersData?.new_order} </h4> */}
              </div>
            </Link>
          </div>

          <div className="col-6 col-md-4">
            <div className="dashItems">
              <div className="dashItemImg">
                <i className="fa-solid fa-bag-shopping"></i>
              </div>
              <h5> {t("myTickets")} </h5>
              <h4> {countersData?.my_ticket} </h4>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <Link to={"/wallet"} className="nav-link">
              <div className="dashItems">
                <div className="dashItemImg">
                  <i className="fa-solid fa-bag-shopping"></i>
                </div>
                <h5> {t("wallet")} </h5>
                <h4 className="d-flex justify-content-center ">
                  {" "}
                  <h6 className="px-2 "> SAR</h6> {countersData?.wallet_balance}
                </h4>
              </div>
            </Link>
          </div>
        </div>

        <div className="dashboarrdSec">
          <div className="Orders text-center py-4">
            <h6>{t("myTickets")} </h6>
            <h5> {t("onSaleTik")} </h5>
          </div>

          {error && (
            <div className="alert alert-danger">
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            </div>
          )}

          <div className="dashTable">
            <table className="table text-center pt-3">
              <thead>
                <tr>
                  <th>{t("status")} </th>
                  <th> {t("eventName")}</th>
                  <th> {t("numOfTik")} </th>
                  <th> {t("numOfSoldTik")} </th>
                  <th> {t("numOfRemainTik")} </th>
                  <th>{t("community")}</th>
                  <th>{t("price")}</th>
                  <th>{t("action")}</th>
                </tr>
              </thead>
              <tbody>
                {tickets.map((ticket) => {
                  const statusClass =
                    ticket.status === "نشطة"
                      ? "active"
                      : ticket.status === "ملغية"
                      ? "rejected"
                      : ticket.status === "قيد الانتظار"
                      ? "waiting"
                      : "stopped";

                  return (
                    <tr key={ticket.id} className={statusClass}>
                      <td data-title={t("status")}>
                        <div className={`prevOrderDetails ${statusClass}`}>
                          <span>{ticket.status}</span>
                        </div>
                      </td>
                      <td data-title={t("eventName")}>{ticket.event_name}</td>
                      <td data-title={t("numOfTik")}>{ticket.quentity}</td>
                      <td data-title={t("numOfsoldTik")}>
                        {ticket.ticket_paid || 0}
                      </td>
                      <td data-title={t("numofRemainTik")}>
                        {ticket.ticket_remaining || 0}
                      </td>
                      <td data-title={t("community")}>{ticket.comunity}</td>
                      <td data-title={t("price")}>
                        SAR {Math.round(ticket.price)}
                      </td>

                      <td data-title={t("action")}>
                        <div className="interactionIcons">
                          {/* <i className="fa-regular fa-eye"></i> */}
                          <div className="ticketEdit">
                            <button
                              onClick={() =>
                                setEditStates((prev) => ({
                                  ...prev,
                                  [ticket.id]: true,
                                }))
                              }
                            >
                              <i className="fa-solid fa-pen"></i>
                            </button>
                            {editStates[ticket.id] && (
                              <EditmyTicket
                                closeEdit={() =>
                                  setEditStates((prev) => ({
                                    ...prev,
                                    [ticket.id]: false,
                                  }))
                                }
                                ticketId={ticket.id}
                                ticketPrice={Math.round(ticket.price)}
                                ticketAutoApprov={ticket.auto_approved_status}
                                onUpdateTicket={handleUpdateTicket} // تمرير دالة التحديث هنا
                                onUpdateTicketState={handleUpdateTicketStatus} // تمرير دالة التحديث هنا
                              />
                            )}
                          </div>

                          <div className="ticketShare">
                            <button onClick={() => setShareTicket(true)}>
                              <i className="fa-solid fa-reply-all"></i>
                            </button>
                            {shareTicket && (
                              <ShareMyTicket
                                closeShare={() => setShareTicket(false)}
                              />
                            )}
                          </div>

                          <div className="UploadTik">
                            {ticket.can_update_image && (
                              <button
                                onClick={() => openUploadModal(ticket.id)}
                              >
                                <i className="fa-solid fa-arrow-up-from-bracket"></i>
                              </button>
                            )}
                            {showUploadModal &&
                              selectedTicketId === ticket.id && (
                                <UploadedTicket
                                  closeUpdated={setShowUploadModal}
                                  ticketId={selectedTicketId} // تمرير ID التذكرة للكمبوننت
                                />
                              )}
                          </div>

                          <div className="ticketPause">
                            <button onClick={() => openModal(ticket.id)}>
                              <i className="fa-regular fa-eye"></i>
                            </button>

                            {showModal && selectedTicketId === ticket.id && (
                              <PauseTicket
                                closeEdit={setShowModal}
                                ticketId={selectedTicketId}
                                setTickets={setTickets} // إذا كنت تستخدمها لتحديث الحالة
                              />
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
