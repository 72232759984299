import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer2 from "../Footer2/Footer2";
import ScrollApp from "../../ScrollApp/ScrollApp";

export default function Layout2() {
  return (
    <>
      <div className="layout-two">
        <Navbar />
        <Outlet />
        <ScrollApp />
        <Footer2 />
      </div>
    </>
  );
}
