import React, { useContext, useEffect, useState } from "react";
import style from "./EventDetails.module.css";
import img9 from "../../assets/image 9.png";
import calendar from "../../assets/Calendar.png";
import clock from "../../assets/Clock Circle.png";
import chair from "../../assets/Armchair 2.png";
import verify from "../../assets/verify.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import arrowDown from "../../assets/Arrowdown.png";
import { UserContext } from "../../Context/userContext";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";

export default function EventDetails() {
  let { userToken, setUserToken } = useContext(UserContext);
  const [selectedTickets, setSelectedTickets] = useState(0); // الكمية المختارة
  const [ticketQuantities, setTicketQuantities] = useState({}); //  quantity for each ticket
  const [selectedTicketsEv, setSelectedTicketsEv] = useState(""); // نوع التذكرة المختارة
  const [eventData, setEventData] = useState(null); // بيانات الحدث
  const [loading, setLoading] = useState(true); // حالة التحميل
  const [error, setError] = useState(null); // حالة الخطأ
  const [tickets, setTickets] = useState([]); // قائمة التذاكر
  const [maxCountTicket, setMaxCountTicket] = useState(0); // الحد الأقصى للتذاكر
  const [community, setCommunity] = useState([]); // الفئات (community)
  const [isSiblings, setIsSiblings] = useState(false); // حالة التذاكر بجانب بعضها
  const { id, slug } = useParams(); // استلام ID الحدث من المسار
  const [errorMessage, setErrorMessage] = useState({}); // رسالة الخطأ
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Update ticket quantity for a specific ticket
  const handleTicketQuantityChange = (ticketId, quantity) => {
    setTicketQuantities((prev) => ({
      ...prev,
      [ticketId]: quantity,
    }));

    // Clear error if quantity is selected
    setErrorMessage((prev) => ({
      ...prev,
      [ticketId]: "",
    }));
  };

  // Navigate to the Cart with ticketId and selected quantity
  const handleBuyClick = (ticketId) => {
    const selectedQuantity = ticketQuantities[ticketId];
    navigate(`/cartNew/${ticketId}/${selectedQuantity}`);
  };

  // Navigate to login if he hasn't token
  // const handleBuyClick2 = () => {
  //   navigate(`/login`);
  // };

  const handleLoginClick = () => {
    const currentPath = location.pathname + location.search; // يشمل المسار والـ query parameters
    navigate(`/login?redirect=${encodeURIComponent(currentPath)}`);
  };
  // change in the selected community
  const handleCommunityChange = (e) => {
    const value = e.target.value;
    setSelectedTicketsEv(value);
  };

  // Simplified filtering logic for debugging

  const filteredTickets = Array.isArray(tickets)
    ? tickets.filter((ticket) => {
        // Check if the community matches the selected one, or allow all if none is selected
        const isCommunity = selectedTicketsEv
          ? ticket.community === selectedTicketsEv // filter by community
          : true;

        // avaliable ticket in array
        const hasAvailableTickets = ticket.total >= selectedTickets;
        const isSiblingFilter = isSiblings ? ticket.is_siblings : true;

        return isCommunity && hasAvailableTickets && isSiblingFilter;
      })
    : [];

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(`${Api_Url}/event/${id}/${slug}`, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "X-Language": i18n.language,
          },
        });
        setMaxCountTicket(response.data.data.maxCountTicket); // تعيين الحد الأقصى لعدد التذاكر
        setCommunity(response.data.data.community); // تعيين الفئات (community)
        setIsSiblings(response.data.data.event.is_siblings); // تعيين is_siblings من API

        // ADD DATA FROM ARRAY TO
        const fetchedTickets = response.data.data.tickets;
        setEventData(response.data.data.event); // تعيين بيانات الحدث
        setTickets(fetchedTickets); // تعيين قائمة التذاكر

        //ADD DEFAULT VALUE QYANTITY IS 1 FOR ALL TICKETS
        const initialQuantities = {};
        fetchedTickets.forEach((ticket) => {
          initialQuantities[ticket.id] = 1;
          // DEFAULT VALUE QUANTITY IS 1 FOR TICKET
        });
        setTicketQuantities(initialQuantities);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [id, slug, i18n.language]);

  // Update the URL when selectedTickets or selectedTicketsEv changes
  useEffect(() => {
    const queryParams = new URLSearchParams();
    if (selectedTickets > 0) queryParams.set("quantity", selectedTickets);
    if (selectedTicketsEv) queryParams.set("community", selectedTicketsEv);
    navigate(`?${queryParams.toString()}`);
  }, [selectedTickets, selectedTicketsEv, navigate]);

  // To Scale picture
  const [selectedImage, setSelectedImage] = useState(null); // حالة الصورة المختارة

  const openImage = () => {
    setSelectedImage(eventData.location_image); // تعيين الصورة عند النقر
  };

  const closeImage = () => {
    setSelectedImage(null); // إغلاق الصورة
  };

  // // وظيفة للتبديل بين التذاكر بجانب بعضها
  const handleToggleChange = () => {
    setIsSiblings((prev) => !prev);
    console.log("تغيير حالة is_siblings:", !isSiblings);
  };

  // Zoom in & out For Pic
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const imageStyle = {
    maxWidth: "90%",
    maxHeight: "90%",
  };

  // function TotalPrice(ticket)
  // {
  //   const totalQuantity = ticketQuantities[ticket.id] || 1 ;
  //   return Math.round(totalQuantity * ticket.price) ;

  // }
  function TotalPrice(ticket) {
    const totalQuantity = ticketQuantities[ticket.id] || 1;
    return totalQuantity * ticket.price;
  }

  if (loading) {
    return (
      <div>
        <div
          className="loading"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <i
            className="fas fa-spinner fa-spin"
            style={{ fontSize: "3rem", color: "#3498db" }}
          ></i>
        </div>
      </div>
    );
  }

  if (error) return <div>حدث خطأ: {error}</div>;
  return (
    <div className="container">
      <div className="eventDetailsSec">
        <div className="CommonNoise">
          <div className="commonDesc">
            <h5>{eventData.name}</h5>
            <h6>
              <span> {t("Home")} \ </span> {eventData.name}
            </h6>
          </div>
        </div>

        <div className="row RowEvent">
          <div className="col-md-6 col-lg-6">
            <div className="eventDetailsContent">
              <div className="d1">
                {/* <h6> عن الفعالية </h6> */}
                {/* <LanguageSwitch /> */}
                <h6> {t("eventDetails")} </h6>
                <h5>{eventData.name}</h5>
                <p>{eventData.description}</p>
              </div>
              <div className="brdrEvent my-4"></div>
              <div className="d2">
                <h6> {t("DateAndTime")} </h6>
                <div className="d-flex">
                  <p className="d-flex align-items-center">
                    <img src={calendar} alt="" className="px-1" />{" "}
                    {eventData.date}
                  </p>
                  <p className="d-flex align-items-center me-4">
                    <img src={clock} alt="" className="px-1" /> {eventData.time}
                  </p>
                </div>
              </div>
              <div className="brdrEvent my-4"></div>

              <div className="d4Img text-center">
                <img
                  src={eventData.location_image}
                  alt=""
                  className="w-75"
                  height={250}
                  onClick={openImage} // إضافة حدث النقر
                  style={{ cursor: "pointer" }} // تغيير شكل المؤشر
                />

                {selectedImage && (
                  <div style={overlayStyle} onClick={closeImage}>
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={imageStyle}
                    />
                  </div>
                )}
              </div>
              <div className="brdrEvent my-4"></div>
              <div className="d5">
                <h6> {t("TermsAndConditions")} </h6>
                <p> {t("TermsAndConditionsApply")} </p>
                <p>
                  <Link to={"/privacy"} className="nav-link">
                    {t("seeMore")}{" "}
                    <img src={arrowDown} alt="" className="px-1" />
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <div className="eventDetailsContent  eventImg ">
              <img src={eventData.image} alt="" className="w-100" />
            </div>
          </div>
        </div>

        <div className={`${style.bookNow} mt-5`}>
          <h6>
            {t("timeforBook")} <img src={img9} alt="" />
          </h6>
          <h5 className="mb-5"> {t("bookYourTik")} </h5>

          <div>
            {/* عرض التفاصيل إذا تم تحميل الحدث */}
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <div className={`${style.Row1} row mx-auto g-3`}>
                {/* بعد تعديل maxCountTicket و community */}
                <div className="col-md-4 border-start pointer">
                  <div className={`${style.bookTicket}`}>
                    <div
                      className={`${style.ticketNum} ${style.SelectArrowDiv} `}
                    >
                      <select
                        id="ticket-count"
                        className="px-4 pointer"
                        value={selectedTickets}
                        onChange={(e) => {
                          const quantity = parseInt(e.target.value);
                          setSelectedTickets(quantity);
                          // Update ticket quantities for all tickets in the tickets array
                          tickets.forEach((ticket) => {
                            if (ticket.id) {
                              handleTicketQuantityChange(ticket.id, quantity);
                            }
                          });
                        }}
                      >
                        <option value="0"> {t("chooseQuantity")} </option>
                        {Array.from({ length: maxCountTicket }, (_, i) => (
                          <option key={i} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>

                      <div
                        className={`${style.selectArrow} ${style.selectArrowIcon} `}
                      >
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 border-start">
                  <div className={`${style.bookTicket}`}>
                    <div
                      className={`${style.ticketNum} ${style.selectArrowType}`}
                    >
                      <select
                        id="ticket-type"
                        className="px-4 pointer"
                        onChange={handleCommunityChange}
                        value={selectedTicketsEv}
                      >
                        <option value="" disabled>
                          {t("chooseTypeofTik")}
                        </option>

                        {community.map((comm) => (
                          <option key={comm.id} value={comm.name}>
                            {comm.name}
                          </option>
                        ))}
                      </select>

                      <div
                        className={`${style.selectArrow} ${style.selectArrowIconType} `}
                      >
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Sibling */}

                <div className="col-md-4 d-flex align-items-center px-3">
                  <div
                    className={`${style.toggleSide} d-flex align-items-center`}
                  >
                    <input
                      type="checkbox"
                      id="check"
                      checked={isSiblings || false}
                      onChange={handleToggleChange}
                    />
                    <label
                      htmlFor="check"
                      className={`${style.toggleSideBtn}`}
                    ></label>
                    <span className="px-2"> {t("tikSidebySide")} </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row g-2 my-3">
          {filteredTickets.map((ticket) => (
            <div className="content p-2  " key={ticket.id}>
              <div className=" tiketSide  col-12 mb-2  col-md-9 px-3   d-flex align-items-center justify-content-between">
                <div className="itemImg">
                  {ticket.owner_is_vip ? (
                    <span className="text-info">
                      {" "}
                      <img src={verify} alt="" className="ps-1" />{" "}
                      {t("verifiedSeller")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {ticket.owner_is_vip ? (
                <>
                  <div className="customeHr"></div>
                </>
              ) : (
                ""
              )}

              <div className="EditForWebOnly    ">
                <div className=" tiketSide  col-7 col-md-9 px-3    ">
                  <div className="itemImg">
                    {ticket.is_siblings && ticket.total > 1 ? (
                      <>
                        {" "}
                        <img src={chair} alt="" />
                        <img src={chair} alt="" />
                        <span className="px-2"> {t("tikSidebySide")} </span>
                      </>
                    ) : (
                      <img src={chair} alt="" />
                    )}
                  </div>
                </div>

                <div className=" tiketSide col-4 itemImg col-md-3 ">
                  <span className="ps-4">
                    {" "}
                    {t("available")} : {ticket.total}{" "}
                  </span>
                </div>
              </div>
              <div className="customeHr mt-3"></div>
              <div className="tickItemDetails">
                <div className="col-4 col-md-3">
                  <div className="itemDesc">
                    <label htmlFor="tickettype"> {t("typeofTik")} </label>
                    <h6>{ticket.community}</h6>
                  </div>
                </div>

                <div className="col-4 col-md-3">
                  <div className="itemDesc">
                    <h5> {t("totalPrice")} </h5>
                    <h6> {TotalPrice(ticket)} SAR</h6>
                  </div>
                </div>

                <div className="col-4 col-md-3">
                  <div className="itemDesc">
                    <label htmlFor="ticketCount" className="w-100">
                      {" "}
                      {t("quantity")}{" "}
                    </label>
                    <div className="d-flex align-items-center specialSelectIcon ">
                      <select
                        className=" h-auto pointer specialSelect "
                        value={ticketQuantities[ticket.id] || 1}
                        onChange={(e) =>
                          handleTicketQuantityChange(
                            ticket.id,
                            parseInt(e.target.value)
                          )
                        }
                      >
                        <option value={0} disabled>
                          {t("chooseNumOfTik")}
                        </option>
                        {[...Array(ticket.total)].map((_, index) => (
                          <option key={index + 1} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>

                      <i className="fa-solid fa-chevron-down  QuantityEventDetails "></i>
                    </div>
                  </div>
                </div>

                {userToken ? (
                  <>
                    {" "}
                    <div className="col-12 col-md-3 text-center">
                      {filteredTickets.length > 0 ? (
                        <>
                          <button
                            className="nav-link w-100"
                            onClick={() => handleBuyClick(ticket.id)}
                          >
                            <div className="buyTicket">
                              <button className=" mt-3"> {t("buy")} </button>
                            </div>
                          </button>
                          {errorMessage[ticket.id] && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {errorMessage[ticket.id]}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="TicketOff">
                          <button> {t("OutOfStock")} </button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="col-12 col-md-3 text-center">
                    {filteredTickets.length > 0 ? (
                      <>
                        <button
                          className="nav-link w-100"
                          onClick={() => handleLoginClick(ticket.id)}
                        >
                          <div className="buyTicket">
                            <button className=" mt-3">{t("buy")} </button>
                          </div>
                        </button>
                        {errorMessage[ticket.id] && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {errorMessage[ticket.id]}
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="TicketOff">
                        <button> {t("OutOfStock")} </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
