// after Check edit
import React, { useEffect, useState } from "react";
import axios from "axios";
import style from "./PauseTicket.module.css";
import Api_Url from "../../ApiConfig/ApiConfig";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export default function PauseTicket({ closeEdit, ticketId }) {
  const [ticketData, setTicketData] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [checkedTickets, setCheckedTickets] = useState({});
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const Authorization = localStorage.getItem("userToken");
  const headers = {
    Accept: "application/json",
    "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
    Authorization: `Bearer ${Authorization}`,
    "X-Language": i18n.language,
  };

  // جلب حالة checkbox من localStorage عند تحميل الكومبوننت
  useEffect(() => {
    const savedCheckedTickets =
      JSON.parse(localStorage.getItem("checkedTickets")) || {};
    setCheckedTickets(savedCheckedTickets);
  }, []);

  // دالة لجلب البيانات
  const fetchData = async () => {
    try {
      const response = await axios.get(`${Api_Url}/my_ticket/${ticketId}`, {
        headers,
      });
      setTicketData(response.data.data.single_ticket || []);
      console.log(response.data.data.single_ticket);
    } catch (error) {
      console.error("Error fetching ticket data:", error);
    }
  };

  // التأكد من إرسال الطلب لمرة واحدة فقط عند فتح المودال
  useEffect(() => {
    if (!hasFetched) {
      fetchData();
      setHasFetched(true);
    }
  }, [ticketId, hasFetched]);

  // دالة لمعالجة تغيير حالة التذكرة
  const handleCheckboxChange = async (ticketId) => {
    // تحديث الحالة المحلية للـ checkedTickets
    setCheckedTickets((prev) => {
      const newCheckedTickets = {
        ...prev,
        [ticketId]: !prev[ticketId],
      };
      // تخزين الحالة الجديدة في localStorage
      localStorage.setItem("checkedTickets", JSON.stringify(newCheckedTickets));
      return newCheckedTickets;
    });

    // إرسال الريكوست للـ API لتحديث حالة التذكرة
    try {
      const response = await axios.post(
        `${Api_Url}/update/singelticket`,
        { single_ticket_id: ticketId },
        { headers }
      );

      // عرض رسالة SweetAlert بناءً على الاستجابة
      Swal.fire({
        text: response.data.data.status,
        icon: "success",
        confirmButtonText: `${t("ok")}`,
      });
    } catch (error) {
      console.error("Error updating ticket status:", error);
      Swal.fire({
        title: "خطأ!",
        text: "حدث خطأ أثناء تحديث التذكرة.",
        icon: "error",
        confirmButtonText: `${t("ok")}`,
      });
    }
  };

  return (
    <div className="container Modal-section">
      <div className="modalBackground">
        <div className={`${style.pauseTicketSec} modalContainer p-4`}>
          <div
            className={`${style.closebtnforPause} d-flex justify-content-end`}
          >
            <button className="text-center" onClick={() => closeEdit(false)}>
              <i className="fa-solid fa-xmark fs-5"></i>
            </button>
          </div>

          <div className="body  ScrollBody">
            <div className={`myTicketCancel inputContainer px-2 `}>
              {ticketData.length > 0 ? (
                ticketData.map((ticket, index) => (
                  <div key={ticket.id} className="row  ">
                    <div className={`${style.caneled} pauseTikReverse `}>
                      <div className="col-md-4  ">
                        <div className={`${style.ticketInfo}  `}>
                          <h5>{ticket.event_name || "فعالية جديدة"}</h5>
                          <h6>{ticket.date || "2024:10:30"}</h6>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className={`${style.ticketInfoCheck}`}>
                          <div className={`${style.CheckTickStyle}`}>
                            <input
                              type="checkbox"
                              checked={checkedTickets[ticket.id] || false}
                              onChange={() => handleCheckboxChange(ticket.id)}
                              className="pointer"
                              id={`confirmCancel-${index}`}
                            />
                            <label
                              htmlFor={`confirmCancel-${index}`}
                              className="pointer"
                            >
                              {t("cancelSelling")}
                              {/* {ticket.status} */}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className={`${style.ticketInfoShow}`}>
                          <a
                            href={ticket.path || "#"}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("viewAndDawnload")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div> {t("noTikToShow")} </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
