import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer1 from '../Footer1/Footer1'
import Navbar2 from '../Navbar2/Navbar2'

export default function Layout1() {
  return <>
  <Outlet/>
  {/* <Footer1/> */}
  
  
  </>
}
