import React, { useEffect, useRef, useState } from "react";
import style from "./SellTicketShow.module.css";
import calendar from "../../assets/Calendar.png";
import clock from "../../assets/Clock Circle.png";
import img21a from "../../assets/image21a.png";
import img20 from "../../assets/image 20.png";
import ticket from "../../assets/Ticket Sale.png";
import { MdDelete } from "react-icons/md";
import gallery from "../../assets/Gallery Send.png";
import goldTicket from "../../assets/GoldTicket.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";

export default function SellTicketShow() {
  // استخدام useState لإنشاء حالة لتحميل الزر، والتذاكر المختارة، والملفات المحددة
  const [selectedTicketsEv, setSelectedTicketsEv] = useState("");
  const selectedFilesRef = useRef([]); // مرجع للاحتفاظ بالملفات المختارة
  const [, forceUpdate] = useState(0); // دالة لإجبار إعادة التحديث
  const fileInputRef = useRef(null); // مرجع لمدخل الملف
  const navigate = useNavigate(); // دالة للتنقل بين الصفحات
  const [ticketData, setTicketData] = useState(null); // حالة لحفظ بيانات التذكرة
  const [loading, setLoading] = useState(true); // حالة لعملية التحميل
  const [error, setError] = useState(null); // حالة لحفظ الأخطاء
  const { id } = useParams(); // الحصول على ID من المعلمات
  const [hasIban, setHasIban] = useState(false);
  const [iban, setIban] = useState("");

  const [selectedTag, setSelectedTag] = useState("");
  const [tags, setTags] = useState([]); // GET TAGS
  const [loaderButton, setLoaderButton] = useState(false); // THIS FOR LOADER BUTTON ON LCIKED SEND DATA
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // THIS FOR DISABLED BUTTON WHEN ISSTILL SEND DATA
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  // تعريف نموذج التحقق باستخدام Yup
  const validationSchema = Yup.object().shape({
    quantity: Yup.number()
      .min(1, `${t("quantityErr")}`)
      .required(`${t("numofTikErr")}`)
      .typeError(`${t("typeofNumErr")}`),
    price: Yup.number()
      .min(1, `${t("priceErr")}`)
      .required(`${t("priceofTikErr")}`)
      .typeError(`${t("priceNumReqErr")}`),
    images: Yup.array()
      .of(Yup.mixed().required(`${("picReqErr")}`))
      .required(`${("picReqErr")}`),
    iban: !hasIban ? Yup.string().required(`${t("ibanErr")}`) : Yup.string(),

    tag: Yup.string().required(`${t("communityErr")}`),
  });

  // تعريف نموذج formik
  const formik = useFormik({
    initialValues: {
      quantity: "",
      price: "",
      images: [],
      iban: "",
      is_siblings: false, // تأكد من أنها بوليانية
      auto_approved: false,
      tag: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // تحقق من وجود ملفات قبل الإرسال
      if (selectedFilesRef.current.length === 0) {
        Swal.fire({
          icon: "warning",
          title: "تحذير",
          text: `${t("swalErr")}`,
        });
        return;
      }

      const Authorization = localStorage.getItem("userToken"); // جلب توكن المستخدم من التخزين المحلي
      const space = " ";

      const dataToSend = new FormData(); // إنشاء نموذج بيانات جديد
      dataToSend.append("quantity", values.quantity);
      dataToSend.append("price", values.price);
      if (!hasIban) {
        console.log("Using new IBAN from form:", values.iban);
        dataToSend.append("iban", values.iban);
      }
      dataToSend.append("event_id", id); // إضافة ID الحدث
      dataToSend.append("tag_id", values.tag); // إضافة ID الوسم

      dataToSend.append("is_siblings", values.is_siblings ? "1" : "0");
      dataToSend.append("auto_approved", values.auto_approved ? "1" : "0");

      // اعرض الداتا في الكونسل
      console.log("Sending data:", {
        quantity: values.quantity,
        price: values.price,
        iban: values.iban,
        is_siblings: values.is_siblings,
        auto_approved: values.auto_approved,
        tag_id: values.tag,
      });
      // إضافة الملفات المحددة إلى النموذج
      selectedFilesRef.current.forEach((file) => {
        dataToSend.append("images[]", file.file);
      });

      try {
        setLoaderButton(true);
        setIsButtonDisabled(true);
        // إرسال البيانات إلى الخادم
        const response = await axios.post(`${Api_Url}/ticket`, dataToSend, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer${space}${Authorization}`,
            "Content-Type": "multipart/form-data",
            "X-Language": i18n.language

          },
        });

        // تحقق من نجاح العملية
        if (response.data && response.data.data.success) {
          handleClick(); // عرض رسالة النجاح
          formik.resetForm(); // إعادة تعيين النموذج
          selectedFilesRef.current = []; // إعادة تعيين الملفات المحددة
          formik.setFieldValue("images", []); // إعادة تعيين الصور في النموذج

          setSelectedTag("");
          setLoaderButton(false);
          setIsButtonDisabled(false);
          setHasIban(true);
          console.log(response.data);
        }
      } catch (error) {
        // التعامل مع الأخطاء
        // console.error("حدث خطأ أثناء إرسال البيانات:", error);
        setSelectedTag("");
        setLoaderButton(false);
        setIsButtonDisabled(false);
        console.log(error);

        // Swal.fire(
        //   "خطأ",
        //   "حدث خطأ أثناء رفع التذكرة. تأكد من أن عدد التذاكر يساوي عدد الصور المرفوعة.",
        //   "error"
        // );
      }
    },
  });

  // جلب تفاصيل التذكرة عند تحميل المكون
  useEffect(() => {
    const fetchTicketDetails = async () => {
      const Authorization = localStorage.getItem("userToken");
      const space = " ";
      try {
        // إرسال طلب جلب بيانات التذكرة
        const response = await axios.get(`${Api_Url}/ticket/${id}`, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer${space}${Authorization}`,
            "X-Language": i18n.language

          },
        });
        setTicketData(response.data.data.ticket); // حفظ بيانات التذكرة
        setTags(response.data.data.ticket.tags || []);
        setHasIban(response.data.data.has_iban); // حفظ قيمة has_iban
        setIban(response.data.data.iban);
      } catch (err) {
        setError(err.message); // حفظ الخطأ في حالة حدوثه
      } finally {
        setLoading(false); // إنهاء حالة التحميل
        setSelectedTag("");
        setLoaderButton(false);
        setIsButtonDisabled(false);
      }
    };

    fetchTicketDetails();
  }, [id]);

  // عرض مكون التحميل
  if (loading)
    return (
      <div className={style.loadingContainer}>
        <FontAwesomeIcon icon={faSpinner} spin size="3x" />
      </div>
    );

  // عرض رسالة الخطأ
  if (error) return <div>Error: {error}</div>;

  // دالة عرض رسالة النجاح بعد الرفع
  const handleClick = () => {
    Swal.fire({
      title: `${t("reviewTik")}`,
      text: `${t("reviewTikConfirm")}`,
      imageUrl: `${goldTicket}`,
      imageWidth: 100,
      imageHeight: 100,
      imageAlt: "Custom Success Icon",
      confirmButtonText: `${t("Profile")}`,
      customClass: {
        title: "swalTitleProfile",
        text: "swalTextProfile",
        confirmButton: "swalButtonProfile",
      },
      width: "392px",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/profile"); // التنقل إلى صفحة الملف الشخصي عند تأكيد
      }
    });
  };

  // دالة معالجة تغيير الملفات
  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files).map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
      file: file,
    }));

    // إضافة الملفات الجديدة إلى القائمة
    selectedFilesRef.current = [...selectedFilesRef.current, ...filesArray];
    forceUpdate((n) => n + 1); // تحديث الواجهة
    event.target.value = null; // إعادة تعيين مدخل الملفات
  };

  // دالة لحذف صورة من القائمة
  const removeImage = (indexToRemove) => {
    selectedFilesRef.current = selectedFilesRef.current.filter(
      (_, index) => index !== indexToRemove
    );
    forceUpdate((n) => n + 1); // تحديث الواجهة
  };

  // اختيار الفئه للتذكره
  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
    formik.setFieldValue("tag", event.target.value);
  };

  return (
    <>
      <div className="container ">
        <div className="CommonNoise">
          <div className="commonDesc">
            <h5>{ticketData.name}</h5>
            <h6>
              <span> {t("Home")} \ </span> {ticketData.name}{" "}
            </h6>
          </div>
        </div>

        <div className="ticketShown">
          <div className="row RowEvent mb-3 ">
            <div className="col-md-6 col-lg-6">
              <div className="eventDetailsContent">
                <div className="d1">
                  <h6> {t("eventDetails")} </h6>
                  <h5>{ticketData.name}</h5>
                  <p>{ticketData.description}</p>
                </div>

                <div className="brdrEvent my-4"></div>
                <div className="d2">
                  <h6 className=""> {t("DateAndTime")} </h6>
                  <div className="d-flex ">
                    <p className="d-flex align-items-center ">
                      <img src={calendar} alt="" className="px-1" />{" "}
                      {ticketData.date}
                    </p>
                    <p className="d-flex align-items-center me-4 ">
                      <img src={clock} alt="" className="px-1" />{" "}
                      {ticketData.time}
                    </p>
                  </div>
                </div>
                <div className="brdrEvent my-4"></div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className={`${style.ticketSellImg} eventDetailsContent   `}>
                <img
                  src={ticketData.image}
                  alt={ticketData.name}
                  className="w-100"
                />
              </div>
            </div>
          </div>

          {/*  بيع تذكرتك */}
          <div className={`${style.sellYourTicket} mx-auto my-5`}>
            <h5>  {t("sellyourTicket")} </h5>

            <form onSubmit={formik.handleSubmit}>
              <div className="row g-2">
                {/* عدد التذاكر */}
                <div className="col-md-3">
                  <div className={`bookTicket`}>
                    <div className={`${style.ticketNum}`}>
                      <div>
                        <label htmlFor="quantity" className="py-2">
                          {t("numOfTik")}
                        </label>
                        <input
                          type="number"
                          min={1}
                          name="quantity"
                          className={`${style.tikNumInput}`}
                          dir="rtl"
                          onChange={formik.handleChange}
                          value={formik.values.quantity}
                        />
                        {formik.touched.quantity && formik.errors.quantity && (
                          <div className="text-danger">
                            {formik.errors.quantity}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* اختيار فئة التذكرة */}
                <div className="col-md-3 pointer">
                  <div className={`bookTicket`}>
                    <div className={`${style.ticketNum} ${style.SelectArrowSellTik}`}>
                      <div>
                        <label htmlFor="ticket-count" className="py-2">
                         {t("chooseTypeofTik")}
                        </label>
                        <select
                          id="tag"
                          value={selectedTag}
                          onChange={handleTagChange}
                          className={`${style.commuSelect}`}
                        >
                          <option value=""> {t("chooseTypeofTik")} </option>
                          {Array.isArray(tags) && tags.length > 0 ? (
                            tags.map((tag) => (
                              <option key={tag.id} value={tag.id}>
                                {tag.name}
                              </option>
                            ))
                          ) : (
                            <option disabled>  {t("noOptionAvailable")} </option>
                          )}
                        </select>


                        <div className={`${style.selectArrow} ${style.selectArrowIconSellTik} `}>
                      <i className="fa-solid fa-chevron-down"></i>      
                      
                       </div>
                        {formik.touched.tag && formik.errors.tag && (
                          <div className="text-danger">{formik.errors.tag}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* السعر للتذكرة الواحدة */}
                <div className="col-md-3">
                  <div className={`bookTicket`}>
                    <div className={`${style.ticketNum}`}>
                      <div>
                        <label htmlFor="price" className="py-2">
                          {t("priceForOneTik")}
                        </label>
                        <input
                          type="number"
                          min={1}
                          name="price"
                          placeholder="SAR"
                          dir="rtl"
                          className={`${style.ticketPrice}`}
                          onChange={formik.handleChange}
                          value={formik.values.price}
                        />
                        {formik.touched.price && formik.errors.price && (
                          <div className="text-danger">
                            {formik.errors.price}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Upload Ticket Section */}
                <div className="col-md-12">
                  <div
                    className={`${style.ticketAdd}`}
                    onClick={() => fileInputRef.current.click()}
                  >
                    {selectedFilesRef.current.length > 0 ? (
                      <div className={`${style.imagePreviewContainer}`}>
                        {selectedFilesRef.current.map((file, index) => (
                          <div key={index} className={`${style.imagePreview}`}>
                            <img
                              src={file.url}
                              width={80}
                              height={80}
                              alt={file.name}
                            />
                            <div className={`${style.uploadedRow}`}>
                              <span className={`${style.uploadContent}`}>
                                <MdDelete
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeImage(index);
                                  }}
                                  className="pointer fs-5"
                                />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="py-3"> {t("addpicForTik")} </p>
                    )}
                  </div>

                  <div
                    className={`${style.ticketPhoto} pointer`}
                    onClick={() => fileInputRef.current.click()}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="input-field"
                      hidden
                      multiple
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                    <div className="d-flex align-items-center justify-content-between px-2">
                      <p className="">
                        {t("numPhotoCheck")}
                      </p>
                      <img src={gallery} alt="Gallery" className="" />
                    </div>
                  </div>
                </div>
                {/* End Upload Ticket Section */}

                {/* IBAN Input */}
                {/* لو كان ب ترو يختفي لو مكنش هيظهر الديف */}

                <div className="col-md-12">
                  {!hasIban && (
                    <div className="signForm">
                      <label htmlFor="iban">IBAN</label>
                      <input
                        type="text"
                        dir="rtl"
                        placeholder="SA51100000111"
                        name="iban"
                        id="iban"
                        className="p-3 mt-2 w-100"
                        onChange={formik.handleChange}
                        value={formik.values.iban}
                      />
                      {formik.touched.iban && formik.errors.iban && (
                        <div className="text-danger">{formik.errors.iban}</div>
                      )}
                    </div>
                  )}
                </div>

                {/* Toggle buttons for options */}

                <div className="col-md-6 d-flex align-items-center ps-3">
                  <div
                    className={`${style.toggleSide} d-flex align-items-center`}
                  >
                    <input
                      type="checkbox"
                      id="check"
                      checked={formik.values.is_siblings}
                      onChange={() =>
                        formik.setFieldValue(
                          "is_siblings",
                          !formik.values.is_siblings
                        )
                      }
                    />
                    <label
                      htmlFor="check"
                      className={`${style.toggleSideBtn}`}
                    ></label>
                    <span className="px-2"> {t("tikSidebySide")} </span>
                  </div>
                </div>


                {/* الموافقه تلقائي */}

                <div className="col-md-6 d-flex align-items-center ps-3">
                  <div
                    className={`${style.toggleSide} d-flex align-items-center`}
                  >
                    <input
                      type="checkbox"
                      id="checkTwo"
                      checked={formik.values.auto_approved}
                      onChange={() =>
                        formik.setFieldValue(
                          "auto_approved",
                          !formik.values.auto_approved
                        )
                      }
                    />
                    <label
                      htmlFor="checkTwo"
                      className={`${style.toggleSideBtn}`}
                    ></label>
                    <span className="px-2">  {t("autoTikApprove")} </span>
                  </div>
                </div>

               

                {/* Submit Button */}
                <div className="col-md-12">
                  <div className={`${style.ticketUploadBtn} mt-5 text-center`}>
                    <button
                      type="submit"
                      className={`${isButtonDisabled ? "disabled-active" : ""}`}
                      disabled={isButtonDisabled}
                    >
                      {!loaderButton ? (
                        <>
                          {" "}
                         {t("uploadTik")}  <img src={ticket} alt="" />
                        </>
                      ) : (
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
