import React, { useState, useEffect, useTransition } from "react";
import axios from "axios";
import style from "./EditmyTicket.module.css";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";


export default function EditmyTicket({
  closeEdit,
  ticketId,
  ticketPrice: initialPrice,
  ticketAutoApprov,
  onUpdateTicket,
  onUpdateTicketState,
}) {
  const [ticketPrice, setTicketPrice] = useState(initialPrice || "");
  const [autoApproved, setAutoApproved] = useState(ticketAutoApprov || false); // حالة التوجل
  const [errorMessage, setErrorMessage] = useState("");
  let {t} = useTranslation()

  // تحديث السعر إذا تغير initialPrice
  useEffect(() => {
    setTicketPrice(initialPrice);
  }, [initialPrice]);

  const handleSave = () => {
    if (!ticketPrice) {
      setErrorMessage("يرجى إدخال السعر.");
      return;
    }

    setErrorMessage("");

    const Authorization = localStorage.getItem("userToken");
    if (!Authorization) {
      setErrorMessage("مطلوب تسجيل الدخول. يرجى إعادة تسجيل الدخول.");
      return;
    }

    const headers = {
      Accept: "application/json",
      "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
      Authorization: `Bearer ${Authorization}`,
    };

    console.log(
      `Saving changes for ticket ID: ${ticketId}, New Price: ${ticketPrice}, Auto Approved: ${autoApproved}`
    );

    axios
      .put(
        `${Api_Url}/my_ticket/${ticketId}`,
        {
          price: ticketPrice,
          auto_approved: autoApproved, // إرسال قيمة التوجل
        },
        { headers }
      )
      .then((response) => {
        console.log("Ticket updated successfully:", response.data);
        onUpdateTicket(ticketId, ticketPrice, autoApproved); // تحديث السعر وحالة التوجل
        onUpdateTicketState(ticketId, autoApproved); // تحديث السعر وحالة التوجل

        closeEdit(false); // إغلاق المودال بعد التحديث
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage(
            error.response.data.message || "حدث خطأ أثناء تحديث التذكرة."
          );
        } else {
          setErrorMessage("خطأ في الاتصال بالخادم.");
        }
        console.error("Error updating ticket:", error);
      });
  };

  return (
    <div className="container Modal-section">
      <div className="modalBackground">
        <div className={`${style.EditTicketSec} modalContainer p-4`}>
          <div className="d-flex justify-content-end">
            <button className="text-center pe-2" onClick={() => closeEdit(false)}>
              <i className="fa-solid fa-xmark fs-5"></i>
            </button>
          </div>
          <div className="title">
            <div className={`${style.editTicketTitle}`}>
              <h6> {t("editTicket")} </h6>
              <h5> {t("editTicketData")} </h5>
            </div>
          </div>
          <div className="body">
            <div className={`${style.editForm} pt-4`}>
              <div className="row">
                <div className="col-md-10">
                  <label htmlFor="ticketNum"> {t("price")} </label>
                  <input
                    type="tel"
                    id="ticketNum"
                    value={ticketPrice}
                    onChange={(e) => setTicketPrice(e.target.value)}
                    className=""
                    dir="rtl"
                  />
                  <span> SAR </span>
                  {errorMessage && (
                    <div className="text-danger">{errorMessage}</div>
                  )}
                </div>

                <div className="col-md-12">
                  <div className={`${style.autoApp}`}>
                    <div className={`${style.toggleSwitch}`}>
                      <input
                        type="checkbox"
                        id="toggle"
                        checked={autoApproved}
                        onChange={() => setAutoApproved(!autoApproved)}
                      />
                      <label htmlFor="toggle" className={`${style.slider}`}></label>
                    </div>
                    <p className=""> {t("autoTikApprove")} </p>
                  </div>
                </div>

                <div className="col-md-12 my-5">
                  <div className={`${style.saveEdit}`}>
                    <button onClick={handleSave}> {t("saveChanges")} </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
