import React from 'react'
import style from './AddNewCard.module.css'
import img29 from '../../assets/image 29.png'
import shield from '../../assets/Shield Check.png'

export default function AddNewCard({ closeModal }) {
  return <>
    <div className="container Modal-section">
      <div className="modalBackground ">
        <div className={`${style.AddNewCardSec} modalContainer p-5  `}>
          <div className=' d-flex justify-content-end '>
            <button className='closeBtn d-flex align-items-center justify-content-center ' onClick={() => { closeModal(false) }}  ><i className="fa-solid fa-xmark "></i> </button>

          </div>
          <div className="title ">
            <h5> اضافة بطاقة جديدة</h5>
          </div>
          <div className="body">
            <div className="signForm text-center ">
              <input type="text" placeholder='الاسم علي الكارت البنكي' className='p-3 ' />
            </div>

            <div className={`${style.cardNum} signForm  text-center d-flex align-items-center justify-content-center  `}>
              <input type="tel" dir='rtl' placeholder='  رقم الكارت البنكي ' className='p-3 my-2 ' />
              <img src={img29} alt="" width={37} height={21} className='' />
            </div>

            <div className={`${style.endDate}   my-3 `}>
              <div className={`${style.end}`}>
                <h6> تاريخ الانتهاء </h6>
                <div className=' d-flex '>
                  <div className={`${style.date}  d-flex align-items-center justify-content-center `}>
                    <input  type='tel' dir='rtl' className='px-4' placeholder='01'        / > 
                  </div>
                  <div className={`${style.date}  mx-2 d-flex align-items-center justify-content-center `}>
                    <input type='tel' dir='rtl' className='px-4' placeholder='2028'       / >  
                  </div>
                </div>
              </div>

              <div className={`${style.cvvNum}`}>
                <h6> رقم ال CVV </h6>
                
                  <div className={`${style.date}   d-flex align-items-center justify-content-center `}>
                    <input  type='tel' dir='rtl' className='px-5' placeholder='435' />  
                  </div>
                  
               
              </div>
            </div>

          </div>
          <div className="footer">

            <div className={`${style.modalAddCardBtn}`} >
              <button> اضافه  </button>

            </div>
            <div className={`${style.buySafe} text-center pt-4 mt-5 `}>
          <img src={shield} alt="" />
          <span> وسائل دفع امنة ١٠٠٪  </span>
        </div>
          </div>
        </div>
      </div>
    </div>



  </>
}
