import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // استيراد useNavigate
import axios from "axios";
import logo from "../../assets/logoa.png";
import { UserContext } from "../../Context/userContext";
import NavMob from "../NavMob/NavMob";
import login from "../../assets/log3x.png";
import userImg from "../../assets/User3x.png";
import sellTic from "../../assets/sellTick3x.png";
import profilePic from "../../assets/ProfileNav2x.png";
import vectorTick from "../../assets/VectorTick.png";
import VectorSell from "../../assets/VectorSell.png";
import VectorMyTic from "../../assets/VectorMyTikets.png";
import VectorSale from "../../assets/VectorSales.png";
import walletSide from "../../assets/WalletNav.png";
import VectorLogOut from "../../assets/VectorLogOut.svg";
import { SearchContext } from "../../Context/searchContext";
import Aos from "aos";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useUser } from "../../Context/UserApiContext";
import { useTranslation } from "react-i18next";
import icon1 from "../../assets/Ticket Sale.svg";
import MobileLanguageSwitch from "../MobileLanguageSwitch/MobileLanguageSwitch";

export default function Navbar2() {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();

  const navigate = useNavigate(); // استخدام useNavigate
  let { userToken, setUserToken } = useContext(UserContext);
  const { setSearchTerm, setSearchResults, setResultDataNotFound, searchTerm } =
    useContext(SearchContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  let nav = useNavigate();

  // Search handle
  // SEARCH INPUT
  const handleSearch = async (e) => {
    e.preventDefault();
    const termInputSearch = e.target.search.value.trim(); // THIS FOR INPUT CHANGES TO GET DATA SEARCH
    if (!termInputSearch) return;
    setSearchTerm(termInputSearch); // ADD THE CHANGES OF INPUT IN USESTATE SEARCH TERM
    setLoading(true); // SHOW LOADING WHEN GET DATA
    try {
      const response = await axios.get(
        `${Api_Url}/search?q=${termInputSearch}`,
        {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            "X-Language": i18n.language
          },
        }
      );
      const data = response.data;
      if (Array.isArray(data.data.result)) {
        setSearchResults(data.data.result); // ADD RESULTS DATA FROM API IN STATE TO SHOW IN PAGE SEARCHRESULT USE "searchResults"
        navigate("/SearchResult"); // GO TO PAGE SEARCH RESULT AFTER GET DATA
      } else if (typeof data.data.result === "string") {
        setResultDataNotFound(data.data.result);
        setSearchResults([]);
        localStorage.removeItem("searchResults");
      }

      navigate("/SearchResult"); // GO TO PAGE SEARCH RESULT AFTER GET DATA
    } catch (err) {
      setError("فشل في جلب النتائج");
      setSearchResults([]); //  SET TO EMPTY ARRAY IF HAS ERROR
    } finally {
      setLoading(false); //ADD LOADING IS FALSE
    }
  };

  const handleLinkClick = (path) => {
    setIsOpen(false); // إغلاق الـ sidebar
    document.body.style.overflow = "auto"; // استعادة السكرول
    navigate(path); // توجيه المستخدم إلى الصفحة المختارة
  };

  function LogOut() {
    localStorage.removeItem("userToken");
    setUserToken(null);
    nav("/");
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const navElement = document.querySelector(".mobileNav");
      if (navElement && !navElement.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      {/* second NavBar */}
      <div>
        {/* Navbar for Web */}
        <div className="Navbar   navbarStaticTwo">
          <div className={`nav-items-base`}>
            <Link to={"/"} onClick={() => handleLinkClick("/")}>
              {t("Home")}
            </Link>
            <Link to={"/aboutUs"} onClick={() => handleLinkClick("/aboutUs")}>
              {t("AboutUs")}
            </Link>
            <Link to={"/faqSec"}> {t("FaQs")} </Link>
            <Link to={"/privacy"}> {t("Privacy")} </Link>
            <Link to={"/contactUs"}> {t("ContactUs")} </Link>
          </div>

          {/* Search Input */}
          <div className="ms-auto HiddenNav loginHeader">
            <form onSubmit={handleSearch}>
              <input
                type="search"
                id="search"
                value={searchTerm}
                placeholder={t("Search")}
                onChange={(term) => {
                  setSearchTerm(term.target.value);
                  console.log(term.target.value);
                }}
                className="form-control"
              />
            </form>
            {loading && <div>  {t("loading")}   </div>}
            {error && <div>{error}</div>}
          </div>


          
        </div>

        {/* Navbar for Mobile */}
        {userToken ? (
          <div className="Navbar mobileNav ">
            <div className={`nav-items-base ${isOpen && "open"}`}>
              <div className="row g-2">
                <div className="col-12">
                  <div className="detailsItemNav d-flex align-items-center">
                    {/* Navbar img in beside profileName */}

                    <div className="ProfNav   ">
                      {user?.avatar === "" ? (
                        <>
                          <div className="specialProvNav">
                            <span>
                              {user?.name ? user?.name.slice(0, 1) : ""}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="specialProvNav">
                            <img src={user?.avatar} className="" alt="" />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="mx-2">
                      <h5> {user?.name}</h5>
                      <Link
                        to={"/profile"}
                        className="nav-link"
                        onClick={() => handleLinkClick("/profile")}
                      >
                        <div className="d-flex align-items-center">
                          <h6>  {t("Profile")} </h6>
                          <i className="fa-solid fa-arrow-left-long px-1"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                {/* Additional Nav Links */}
                <div className="col-12">
                  <Link
                    to={"/Myactivity"}
                    className="nav-link"
                    onClick={() => handleLinkClick("/Myactivity")}
                  >
                    <div className="itemOfNav">
                      <div className="d-flex align-items-center">
                        <img src={vectorTick} alt="" width={17} height={13} />
                        <span> {t("myOrders")} </span>
                      </div>
                      <i className="fa-solid fa-chevron-left px-3"></i>
                    </div>
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to={"/sellTicet"}
                    className="nav-link"
                    onClick={() => handleLinkClick("/sellTicet")}
                  >
                    <div className="itemOfNav">
                      <div className="d-flex align-items-center">
                        <img src={VectorSell} alt="" width={17} height={13} />
                        <span>  {t("sellyourTicket")}  </span>
                      </div>
                      <i className="fa-solid fa-chevron-left px-3"></i>
                    </div>
                  </Link>
                </div>
                <div className="col-12 ">
                  <Link
                    to={"/dashboard"}
                    className="nav-link"
                    onClick={() => handleLinkClick("/dashboard")}
                  >
                    <div className="itemOfNav bg-white ">
                      <div className="d-flex align-items-center">
                        <span className="">  {t("dashboardTitle")}   </span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to={"/mytickets"}
                    className="nav-link"
                    onClick={() => handleLinkClick("/mytickets")}
                  >
                    <div className="itemOfNav  customeItemNav ">
                      <div className="d-flex align-items-center">
                        <img src={VectorMyTic} alt="" width={17} height={13} />
                        <span>  {t("myTickets")}  </span>
                      </div>
                      <i className="fa-solid fa-chevron-left px-3"></i>
                    </div>
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to={"/prevOrders"}
                    className="nav-link"
                    onClick={() => handleLinkClick("/prevOrders")}
                  >
                    <div className="itemOfNav  customeItemNav">
                      <div className="d-flex align-items-center">
                        <img src={VectorSale} alt="" width={17} height={13} />
                        <span> {t("mySales")}  </span>
                      </div>
                      <i className="fa-solid fa-chevron-left px-3"></i>
                    </div>
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to={"/wallet"}
                    className="nav-link"
                    onClick={() => handleLinkClick("/wallet")}
                  >
                    <div className="itemOfNav  customeItemNav">
                      <div className="d-flex align-items-center">
                        <img src={walletSide} alt="" width={17} height={13} />
                        <span>{t("wallet")}</span>
                      </div>
                      <i className="fa-solid fa-chevron-left px-3"></i>
                    </div>
                  </Link>
                </div>
                <div className="col-12">
                  <div
                    className="itemOfNav customeLogOutNav "
                    onClick={() => LogOut()}
                  >
                    <div className="d-flex align-items-center">
                      <img src={VectorLogOut} alt="" width={17} height={13} />
                      <span className="text-white" >   {t("logout")}  </span>
                    </div>
                    <i className="fa-solid fa-chevron-left px-3 text-white "></i>
                  </div>
                </div>
              </div>
            </div>

            {/* Ticket Sell */}
          

            <div className="navItem  navbar2SellTik ">
              <Link to={"/sellTicet"} className="nav-link">
                <span>
                  {t("sellyourTicket")}{" "}
                  <img src={icon1} alt="" width={14} height={14} />{" "}
                </span>
              </Link>
            </div>

            {/* Logo */}


            

            <Link
              to="/"
              className="mx-auto"
              onClick={() => handleLinkClick("/")}
            >
              <img src={logo} alt="logo" height={32} />
            </Link>
            {/* language switch */}
            <div className="mx-2">

              <MobileLanguageSwitch/>
            </div>

              


            <div
              className={`nav-toggle ${isOpen && "open"}  `}
              onClick={() => {
                setIsOpen(!isOpen);
                document.body.style.overflow = isOpen ? "auto" : "hidden";
              }}
            >
              <div className="bar">
                <i className="fa-solid fa-chevron-down text-info "></i>
              </div>
            </div>
            {/* Navbar img in header */}
            <div className="ProfNav ">
              {user?.avatar === "" ? (
                <>
                  <div className="specialProvNavtwo">
                    <span>{user?.name ? user?.name.slice(0, 1) : ""}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="specialProvNavtwo">
                    <img src={user?.avatar} className="" alt="" />
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="Navbar mobileNav ">
            
            <div className="navItem  navbar2SellTik ">
              <Link to="/login" className="logImg nav-link px-1 ">
                <span>{t("login")}</span>{" "}
              </Link>
            </div>

            <Link to="/" className="px-1 mx-auto logoImg">
              <img src={logo} alt="logo" height={32} />
            </Link>


{/* for language mobile switch */}

<MobileLanguageSwitch/>


            <Link to="/login" className="px-1 me-auto UserImg">
              <img src={userImg} alt="user" height={38} width={38} />
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
