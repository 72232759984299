// import React from "react";

// export default function UploadedTicket({ closeUpdated }) {
//   return (
//     <>
//       <div className="container Modal-section">
//         <div className="modalBackground">
//           <div className={`modalContainer p-4`}>
//             {/* زر الإغلاق */}
//             <div className="d-flex justify-content-end">
//               <button
//                 className="closeBtn text-center pe-2"
//                 onClick={() => closeUpdated(false)} // استدعاء الوظيفة فقط
//               >
//                 <i className="fa-solid fa-xmark"></i>
//               </button>
//             </div>

//             <div className="title text-center">
//               {/* المحتوى */}
//               <h3> رفع التذكرة </h3>
//             </div>
//             <div className="body ">
//               <div className="uploadTikInput my-5 d-flex align-items-center">
//                 <label htmlFor="up"> رفع التذكره </label>
//                 <input type="file" id="up" className="px-2" />
//               </div>
//             </div>
//             <div className="uploadtikBtn">
//               {" "}
//               <button> تعديل </button>{" "}
//             </div>

//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

//////////////////////////////////////////////////////////////
// import Api_Url from "../../ApiConfig/ApiConfig";
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useTranslation } from "react-i18next";
// import Swal from "sweetalert2"; // استيراد SweetAlert2
// import celebrate from "../../assets/celebrate2.png";

// export default function UploadedTicket({ closeUpdated, ticketId }) {
//   const [tickets, setTickets] = useState([]);
//   const [fileInputs, setFileInputs] = useState([]);
//   const { i18n } = useTranslation();
//   const Authorization = localStorage.getItem("userToken");
//   const headers = {
//     Accept: "application/json",
//     "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
//     Authorization: `Bearer ${Authorization}`,
//   };

//   // الرسائل للاستجابة
//   const [responseMessage, setResponseMessage] = useState("");
//   const [responseError, setResponseError] = useState("");
//   const [Loading, setLoading] = useState(true);

//   // عند فتح المودال، نعمل request لعرض التذاكر
//   useEffect(() => {
//     if (ticketId) {
//       axios
//         .get(`${Api_Url}/my_ticket/${ticketId}`, {
//           headers,
//           "X-Language": i18n.language,
//         })
//         .then((response) => {
//           console.log("التذاكر المسترجعة:", response.data); // تأكيد البيانات المسترجعة من الـ API
//           setTickets(response.data.data.single_ticket || []); // حفظ التذاكر
//           setFileInputs(
//             new Array(response.data.data.single_ticket.length).fill("")
//           ); // تحديد عدد الـ input fields حسب عدد التذاكر
//         })
//         .catch((error) => {
//           console.error("Error fetching tickets:", error);
//         });
//     }
//   }, [ticketId]);

//   // وظيفة لرفع الملفات
//   const handleFileChange = (e, index) => {
//     const newFileInputs = [...fileInputs];
//     newFileInputs[index] = e.target.files[0];
//     setFileInputs(newFileInputs);
//   };

//   // وظيفة لتحديث التذكرة ورفع الملفات
//   const handleUpdateTicket = () => {
//     if (fileInputs.every((file) => !file)) {
//       setResponseError("برجاء إدخال صوره التذكره.");
//       setResponseMessage(""); // إعادة تعيين رسالة النجاح
//       return;
//     }

//     const formData = new FormData();
//     formData.append("single_ticket_id", ticketId); // استخدام ticketId المتغير
//     console.log(formData)

//     // إضافة الملفات إلى الـ FormData
//     fileInputs.forEach((file, index) => {
//       if (file) {
//         formData.append("image", file); // إضافة الملف بإسم موحد 'image'
//       }
//     });

//     // إرسال الـ POST request
//     axios
//       .post(`${Api_Url}/my_ticket`, formData, {
//         headers: {
//           ...headers,
//           "Content-Type": "multipart/form-data",
//         },
//       })
//       .then((response) => {
//         console.log("تم التعديل بنجاح:", response.data);
//         if (response.data.success) {
//           // عرض رسالة النجاح باستخدام SweetAlert
//           Swal.fire({
//             imageUrl: `${celebrate}`,
//             title: "تم التعديل بنجاح",
//             text: response.data?.data?.success ,
//             width: "382px",
//             customClass: {
//               text: "swalTextFatoraa",
//               confirmButton: "swalButtonFatoraa",
//               title: "swalTitleFatoraa",
//             },
//           });
//           setResponseMessage(
//             response.data?.data?.success || "تم التعديل بنجاح!"
//           );
//           setResponseError(""); // إعادة تعيين رسالة الخطأ
//         } else {
//           // عرض رسالة الخطأ باستخدام SweetAlert
//           Swal.fire({
//             icon: "error",
//             title: "خطأ",
//             text: response.data.message || "حدث خطأ في التعديل.",
//           });
//           setResponseError(response.data.message || "حدث خطأ في التعديل.");
//           setResponseMessage(""); // إعادة تعيين رسالة النجاح
//         }
//         closeUpdated(false); // إغلاق المودال بعد النجاح
//       })
//       .catch((error) => {
//         console.error("خطأ في تنفيذ التعديل:", error);
//         // عرض رسالة الخطأ باستخدام SweetAlert
//         Swal.fire({
//           icon: "error",
//           title: "خطأ",
//           text: "حدث خطأ في التعديل. من فضلك حاول لاحقاً.",
//         });
//         setResponseError("حدث خطأ في التعديل. من فضلك حاول لاحقاً.");
//         setResponseMessage(""); // إعادة تعيين رسالة النجاح
//       });
//   };

//   return (
//     <>
//       <div className="container Modal-section">
//         <div className="modalBackground">
//           <div className={`modalContainer p-4`}>
//             {/* زر الإغلاق */}
//             <div className="d-flex justify-content-end">
//               <button
//                 className="closeBtn text-center pe-2"
//                 onClick={() => closeUpdated(false)} // استدعاء الوظيفة فقط
//               >
//                 <i className="fa-solid fa-xmark"></i>
//               </button>
//             </div>

//             <div className="title text-center">
//               {/* المحتوى */}
//               <h3> رفع التذكرة </h3>
//             </div>
//             <div className="body ScrollBody">
//               {/* التحقق من وجود التذاكر */}
//               {tickets.length === 0 ? (
//                 <p>لا توجد تذاكر لعرضها.</p> // رسالة عندما لا توجد تذاكر
//               ) : (
//                 // عرض عدد الـ input fields لرفع الملفات بناءً على عدد التذاكر
//                 <div className="inputContainer">
//                   {tickets.map((ticket, index) => (
//                     <div
//                       key={index}
//                       className="uploadTikInput my-5 d-flex align-items-center"
//                     >
//                       <label htmlFor={`up-${index}`}>
//                         {" "}
//                         رفع التذكرة {index + 1}{" "}
//                       </label>
//                       <input
//                         type="file"
//                         id={`up-${index}`}
//                         className="px-2"
//                         onChange={(e) => handleFileChange(e, index)}
//                         accept="image/*"
//                       />
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>

//             {/* عرض الرسائل */}
//             <div className="responseMessage">
//               {responseMessage && (
//                 <p className="text-success">{responseMessage}</p>
//               )}
//               {responseError && <p className="text-danger">{responseError}</p>}
//             </div>

//             <div className="uploadtikBtn">
//               <button onClick={handleUpdateTicket}> تعديل </button>
//             </div>

//             {/* {Loading ? (
//               <div className="uploadtikBtn">
//                 <button onClick={handleUpdateTicket}> تعديل </button>
//               </div>
//             ) : (
//               <div className="uploadtikBtn">
//                 <button>
//                   {" "}
//                   <i className="fa-solid fa-spinner fa-spin text-white">
//                     {" "}
//                   </i>{" "}
//                 </button>
//               </div>
//             )} */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }





import Api_Url from "../../ApiConfig/ApiConfig";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2"; // استيراد SweetAlert2
import celebrate from "../../assets/celebrate2.png";

export default function UploadedTicket({ closeUpdated, ticketId }) {
  const [tickets, setTickets] = useState([]);
  const [fileInputs, setFileInputs] = useState([]);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const Authorization = localStorage.getItem("userToken");
  const headers = {
    Accept: "application/json",
    "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
    Authorization: `Bearer ${Authorization}`,
  };

  const [responseMessage, setResponseMessage] = useState("");
  const [responseError, setResponseError] = useState("");

  useEffect(() => {
    if (ticketId) {
      axios
        .get(`${Api_Url}/my_ticket/${ticketId}`, {
          headers,
          "X-Language": i18n.language,
        })
        .then((response) => {
          console.log("التذاكر المسترجعة:", response.data);
          setTickets(response.data.data.single_ticket || []);
          setFileInputs(new Array(response.data.data.single_ticket.length).fill(""));
        })
        .catch((error) => {
          console.error("Error fetching tickets:", error);
        });
    }
  }, [ticketId]);

  const handleFileChange = (e, index) => {
    const newFileInputs = [...fileInputs];
    newFileInputs[index] = e.target.files[0];
    setFileInputs(newFileInputs);
  };

  const handleUpdateTickets = () => {
    if (fileInputs.every((file) => !file)) {
      setResponseError("برجاء إدخال صوره واحدة على الأقل.");
      
      setResponseMessage("");
      return;
    }

    let successCount = 0;
    let errorCount = 0;
    let skippedCount = 0;

    tickets.forEach((ticket, index) => {
      const file = fileInputs[index];

      if (!file) {
        console.log(`تخطي التذكرة ${ticket.id} لأنها لم تُعدل.`);
        skippedCount++;
        return;
      }

      const formData = new FormData();
      formData.append("single_ticket_id", ticket.id);
      formData.append("image", file);

      console.log(`رفع التذكرة ${ticket.id}:`, formData);

      axios
        .post(`${Api_Url}/my_ticket`, formData, {
          headers: {
            ...headers,
            "Content-Type": "multipart/form-data",
            "X-Language": i18n.language,
          },
        })
        .then((response) => {
          console.log(`تم رفع التذكرة ${ticket.id} بنجاح:`, response.data);
          if (response.data.success) {
            successCount++;
          } else {
            errorCount++;
            setResponseError(response.data.message || `خطأ في رفع التذكرة ${ticket.id}`);
          }

          if (successCount + errorCount + skippedCount === tickets.length) {
            Swal.fire({
              title: response.data?.data?.success ,
              // text: `تم رفع ${successCount} تذكرة بنجاح، فشل رفع ${errorCount} تذكرة، وتم تخطي ${skippedCount} تذكرة غير معدلة.`,
              width: "392px",
              confirmButtonText: `${t("ok")}`,
              customClass: {
                text: "swalTextFatoraa",
                confirmButton: "swalButtonFatoraa",
                title: "swalTitleFatoraa",
              },
          //  imageUrl: `${celebrate}`
           imageUrl: successCount > 0 ? `${celebrate}` : "warning",
            });
            if (successCount > 0 && errorCount === 0) {
              closeUpdated(false);
            }
          }
        })
        .catch((error) => {
          console.error(`خطأ في رفع التذكرة ${ticket.id}:`, error);
          errorCount++;
          if (successCount + errorCount + skippedCount === tickets.length) {
            Swal.fire({
              width: "392px",
              confirmButtonText:  `${t("ok")}`,
              customClass: {
                confirmButton: "swalButtonFatoraa",
                title: "swalTitleFatoraa",
              },
              text: error.response.data?.message ,
              icon: successCount > 0 ? "success" : "warning",
            });
            if (successCount > 0 && errorCount === 0) {
              closeUpdated(false);
            }
          }
        });
    });
  };

  return (
    <div className="container Modal-section">
      <div className="modalBackground">
        <div className={`modalContainer p-4`}>
          <div className="d-flex justify-content-end">
            <button className="closeBtn text-center pe-2" onClick={() => closeUpdated(false)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <div className="title text-center">
            <h3> {t("uploadTik")}  </h3>
          </div>

          <div className="body ScrollBody">
            {tickets.length === 0 ? (
              <p>  {t("noTikAvaliable")}  </p>
            ) : (
              <div className="inputContainer">
                {tickets.map((ticket, index) => (
                  <div key={index} className="uploadTikInput my-5 d-flex align-items-center">
                    <label htmlFor={`up-${index}`}> {`${t("uploadTik")}`} {index + 1}</label>
                    <input
                      type="file"
                      id={`up-${index}`}
                      className="px-2"
                      onChange={(e) => handleFileChange(e, index)}
                      accept="image/*"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="responseMessage">
            {responseMessage && <p className="text-success">{responseMessage}</p>}
            {responseError && <p className="text-danger">{responseError}</p>}
          </div>

          <div className="uploadtikBtn">
            <button onClick={handleUpdateTickets}> {t("edit")} </button>
          </div>
        </div>
      </div>
    </div>
  );
}
