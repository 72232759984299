import React , { useState, useEffect } from "react";
import style from './SeeMore.module.css'
import ahlam from '../../assets/ahlam.png'
import tamer from '../../assets/tamer.png'
import hosny from '../../assets/hosny.png'
import hama2i from '../../assets/hama2i.png'
import calendar from '../../assets/Calendar.png'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';

import Api_Url from "../../ApiConfig/ApiConfig";
import { truncateText } from "../../Utils/textUtils";

export default function SeeMore() {
  const [category, setCategory] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { categoryId } = useParams(); // استخدم useParams للحصول على id الفئة


  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${Api_Url}/category/${categoryId}` , 
          {
            headers: {
              Accept: "application/json",
              "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            },
          }
        );
        console.log("البيانات تم جلبها بنجاح:", response.data);

        const categoryData = response.data.data.categories;
        setCategory(categoryData.title);  // حفظ عنوان الفئة
        console.log("عنوان الفئة:", categoryData.title);

        setEvents(categoryData.events);  // حفظ الأحداث المرتبطة بالفئة
        console.log("الأحداث المرتبطة بالفئة:", categoryData.events);
      } catch (error) {
        setError('خطأ في جلب البيانات');
        console.error("حدث خطأ أثناء جلب البيانات:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategory();
  }, []);

  if (loading) {
    return (
      <>
        {" "}
        <div
          className="loading"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <i
            className="fas fa-spinner fa-spin"
            style={{ fontSize: "3rem", color: "#3498db" }}
          ></i>
        </div>
      </>
    );
  }
  if (error) {
    console.error("خطأ:", error);
    return <p>حدث خطأ: {error}</p>;
  }

  return (
    <>
      <div className="container">
        <div className="CommonNoise  ">
          <div className="commonDesc">
            <h5> تصفح كل الفعاليات </h5>
            <h6>
              <span> الرئيسية \ </span> تصفح كل الفعاليات
            </h6>
          </div>
        </div>



        <div className={`${style.sellContent}`}>
      <div className='py-3'>
        <h5 className="text-center">{category}</h5> {/* عرض عنوان الفئة */}
      </div>
      <div className="row g-2">
        {events.map((event) => (
          <div className="col-md-3 col-6" key={event.id}  >
            <Link to={`/eventDetails/${event.id}/${event.slug}`} className="nav-link">
              <div className={`${style.sellItem} `}   >
                <img src={event.image} alt={event.name} className={`${style.seeMoreTikImg} w-100 `}   />
                <div className=''>
                  <h5 className="cutText"> {event.name}  </h5> 
                  {/* <h5>  {truncateText(event.name)}  </h5>  */}
                  <h6  > <img src={calendar} alt="" />  {event.date}  </h6> {/* عرض التاريخ */}
                </div>
                




              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
      </div>
    </>
  );
}
