

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import img34 from "../../assets/image 34.png";
import style from "./PrevOrders.module.css";
import file from "../../assets/File Text.png";
import verifiedBlue from "../../assets/Verified Checkblue.png";
import axios from "axios";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useUser } from "../../Context/UserApiContext";
import { useTranslation } from "react-i18next";

export default function PrevOrders() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const { countersData } = useUser();
  const [isLoading, setIsLoading] = useState(true); // حالة التحميل
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const handleClick = (link) => {
    setActiveLink(link);
  };


  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const Authorization = localStorage.getItem("userToken"); // استرجاع التوكن من localStorage
        const space = " "; // المتغير للمسافة
        const response = await axios.get(`${Api_Url}/previous_order`, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            Authorization: `Bearer${space}${Authorization}`, 
            "X-Language": i18n.language

          },
        });
        setOrders(response.data.data.PreviousOrder);
      } catch (error) {
        setError(
          error.response
            ? error.response.data.message
            : "حدث خطأ أثناء تحميل البيانات."
        );
      } finally {
        setIsLoading(false); // عند الانتهاء من تحميل البيانات، نغير حالة التحميل إلى false
      }
    };

    fetchOrders();
  }, []);

  if (isLoading) {
    return (
      <div
        className="loading"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <i
          className="fas fa-spinner fa-spin"
          style={{ fontSize: "3rem", color: "#3498db" }}
        ></i>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="CommonNoise">
          <div className="commonDesc">
            <h5>{t("dashboardTitle")} </h5>
            <h6>
              <span> {t("Home")} \ </span> {t("prevOrders")}
            </h6>
          </div>
        </div>
        <div className="row my-3 g-2 ">
          

<div className="col-6 col-md-4">
              <div className="dashItems">
                <div className="dashItemImg">
                  <i className="fa-solid fa-bag-shopping"></i>
                </div>
                <h5>  {t("mySales")} </h5>
                {/* <h4> {countersData?.new_order} </h4> */}
              </div>
            </div>

            <div className="col-6 col-md-4">
              <Link to={"/mytickets"} className="nav-link">
                <div className="dashItems">
                  <div className="dashItemImg">
                    <i className="fa-solid fa-bag-shopping"></i>
                  </div>
                  <h5> {t("myTickets")} </h5>
                  <h4> {countersData?.my_ticket} </h4>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-4">
              <Link className="nav-link" to={"/wallet"}>
                <div className="dashItems">
                  <div className="dashItemImg">
                    <i className="fa-solid fa-bag-shopping"></i>
                  </div>
                  <h5> {t("wallet")} </h5>
                  <h4 className="d-flex justify-content-center ">
                    <h6 className="px-2 "> SAR</h6> {countersData?.wallet_balance}
                  </h4>
                </div>
              </Link>
            </div>
          </div>




  {/* التنقل بين الطلبات الجديده السابقه  */}
  <div className="containActivity ActivitySalse d-flex pt-5 my-5 justify-content-between">
          <Link
            to="/dashboard"
            className="nav-link profileOpt"
            onClick={() => handleClick("/dashboard")}
          >
            <h6 className={activeLink === "/dashboard" ? "active" : ""}>
            {t("newOrders")}
            </h6>
          </Link>
          <Link
            to="/prevOrders"
            className="nav-link activityOpt"
            onClick={() => handleClick("/prevOrders")}
          >
            <h6 className={activeLink === "/prevOrders" ? "active" : ""}>
            {t("prevOrders")}  ({countersData?.Previous_order})
            </h6>
          </Link>
        </div>



        {/* <div className="Orders text-center pb-4">
          <h6> طلباتي </h6>
          <h5> الطلبات السابقة </h5>
        </div> */}
        {error && (
          <div className="alert alert-danger my-4">
            <p style={{ color: "red", textAlign: "center" }}>{error}</p>
          </div>
        )}

        {orders.map((order, index) => (
          <div className={`prevOrder`} key={index}>
            <div className="row my-2">
              <div className="prevOrderItem">
                <div className="col-12 col-md-1 prevOrderTikImg">
                  <img
                    src={order.event_image}
                    alt={order.event_name}
                    className=""
                  />
                </div>
                <div className="col-12 col-md-2">
                  <div className="prevOrderDetails ">
                    <h6>{t("eventName")}  </h6>
                    <h5>{order.event_name}</h5>
                  </div>
                </div>
                <div className="col-4 col-md-2">
                  <div className="prevOrderDetails">
                    <h6>{t("date")}</h6>
                    <h5>{order.date}</h5>
                  </div>
                </div>
                <div className="col-4 col-md-1">
                  <div className="prevOrderDetails">
                    <h6> {t("numOfTik")}</h6>
                    <h5>{order.quentity}</h5>
                  </div>
                </div>
                <div className="col-6 col-md-1">
                  <div className="prevOrderDetails">
                    <div className="sarEffect">
                      <h5 className="text-info">{order.price} SAR</h5>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-1">
                  <div className="prevOrderDetails">
                    <h6>{t("community")}</h6>
                    <h5>{order.comunity}</h5>
                  </div>
                </div>
                <div className="col-6 col-md-1">
                  <div className="prevOrderDetails">
                    <div className="varifiedEffect">
                      <h6>{t("status")}</h6>
                      <p>
                        <img src={verifiedBlue} alt="" /> {order.status}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="prevOrderDetails">
                    <div className="showAndUploadBtn text-center">
                      <button>
                        <img src={file} alt="" className="px-1" /> {t("viewAndDawnload")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
