import React, { useEffect, useState } from "react";
import FaQ from "../FaQ/FaQ";
import axios from "axios";
import squareIcon from "../../assets/Square Alt  Arrow Down.png";
import arrowLeft from "../../assets/Round Arrow Left2.png";
import { Link, useLocation } from "react-router-dom";
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";

export default function FaqSec() {
  const [faqs, setFaqs] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const location = useLocation(); // الحصول على موقع الصفحة الحالي
  const [activeLink, setActiveLink] = useState(location.pathname); // تعيين الرابط النشط بناءً على الموقع الحالي
  const handleClick = (link) => {
    setActiveLink(link); // تعيين الرابط النشط
  };

  
  useEffect(() => {
    const fetchFAQs = async () => {
        try {
            const response = await axios.get(`${Api_Url}/faq` , 
             {
                headers: {
                  Accept: "application/json",
                  "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
                  "X-Language": i18n.language
                  
                },
              }
            );
            setFaqs(response.data.data.seller); // ربط الـ FAQs من قسم buyer
            console.log(response.data.data.seller)
        } catch (error) {
            console.error('Error fetching FAQs:', error);
        }
    };

    fetchFAQs();
}, []);




  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <div className="container">
        <div className="CommonNoise  ">
          <div className="commonDesc">
            <h5> {t("FaQs")} </h5>
            <h6>
              {" "}
              <span> {t("Home")} \ </span> {t("FaQs")}
            </h6>
          </div>
        </div>
        {/* <FaQ/> */}

        {/* التنقل بين اسئله البائع واسئله المشتري  */}

        <div className="containActivity d-flex my-4 justify-content-between">
          <Link
            to="/faqSec"
            className="nav-link profileOpt"
            onClick={() => handleClick("/faqSec")}
          >
            <h6 className={activeLink === "/faqSec" ? "active" : ""}>
            {t("BuyerQes")} 
            </h6>
          </Link>
          <Link
            to="/faqBuyer"
            className="nav-link activityOpt"
            onClick={() => handleClick("/faqBuyer")}
          >
            <h6 className={activeLink === "/faqBuyer" ? "active" : ""}>
            {t("SellerQes")}
            </h6>
          </Link>
        </div>







        <div className={`faQsSection my-4`}>
            {faqs.map((item, index) => (
                <div className="my-3" key={index}>
                    <div
                        className={`faQsItem d-flex justify-content-between align-items-center px-4 mx-auto`}
                        onClick={() => toggleFAQ(index)}
                    >
                        <p className="fw-bold pt-3">{item.question}</p>
                        <img src={squareIcon} alt="Toggle" />
                    </div>
                    {openIndex === index && (
                        <div className={`faq-content open`}>
                            <p className="py-3 text-end">{item.answer}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>


      
      </div>
    </>
  );
}
