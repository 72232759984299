import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import squareIcon from "../../assets/Square Alt  Arrow Down.png";
import arrow2x from '../../assets/arrowDown2x.png'
import Api_Url from "../../ApiConfig/ApiConfig";
import { useTranslation } from "react-i18next";

export default function FaQ() {
  const [faqs, setFaqs] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const location = useLocation(); // الحصول على موقع الصفحة الحالي
  const [activeLink, setActiveLink] = useState(location.pathname); // تعيين الرابط النشط بناءً على الموقع الحالي
  const handleClick = (link) => {
    setActiveLink(link); // تعيين الرابط النشط
  };

  // جلب البيانات من الـ API باستخدام Axios
  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await axios.get(`${Api_Url}/faq`, {
          headers: {
            Accept: "application/json",
            "X-SECRET-KEY": "TIKEVENT-5mm0jUsfOwCrAANQ6X_uoJkexlL",
            "X-Language": i18n.language


          },
        });
        setFaqs(response.data.data.buyer); // ربط الـ FAQs من قسم buyer
        console.log(response.data.data.buyer);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFAQs();
  }, []);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (

   
   //  <div className={`faQsSection my-4`}>
   //    {faqs.map((item, index) => (
   //      <div className="my-3" key={index}>
   //        <div
   //          className={`faQsItem d-flex justify-content-between align-items-center px-4 mx-auto`}
   //          onClick={() => toggleFAQ(index)}
   //        >
   //          <p className="fw-bold pt-3">{item.question}</p>
   //          <img src={squareIcon} alt="Toggle" />
   //        </div>
   //        {openIndex === index && (
   //          <div className={`faq-content open`}>
   //            <p className="py-3 text-end">{item.answer}</p>
   //          </div>
   //        )}
   //      </div>
   //    ))}
   //    <div className="faqBtn my-4 text-center">
   //           <Link to={'/faqSec'}> {/* رابط لصفحة الأسئلة */}
   //              <button>
   //                 عرض المزيد <img src={arrow2x} alt=""  width={20} height={20} />
   //              </button>
   //           </Link>
   //        </div>






   //  </div>
   <div className={`faQsSection my-4`}>
  {faqs.slice(0, 5).map((item, index) => (
    <div className="my-3" key={index}>
      <div
        className={`faQsItem d-flex justify-content-between align-items-center px-4 mx-auto`}
        onClick={() => toggleFAQ(index)}
      >
        <p className="fw-bold pt-3">{item.question}</p>
        <img src={squareIcon} alt="Toggle" />
      </div>
      {openIndex === index && (
        <div className={`faq-content open`}>
          <p className="py-3 text-end">{item.answer}</p>
        </div>
      )}
    </div>
  ))}
  
  {faqs.length > 5 && (
    <div className="faqBtn my-4 text-center">
      <Link to={'/faqSec'}>
        <button>
         {t("seeMore")} <img src={arrow2x} alt="" width={20} height={20} />
        </button>
      </Link>
    </div>
  )}
</div>

  );
}
